// import React from "react";
import styled from 'styled-components';
import { media } from 'src/styled/helpers/';

const MobileHidden = styled.div`
  ${media.mobile`
    display: none;
    visibility: hidden;
  `}
`;

export default MobileHidden;
export { MobileHidden };
