import {ILinkageData} from "../interface/ILinkageData";
import { IResultTopState } from "src/interface/ITopResultState";
import { StockNavigation } from "src/interface/IBookmarkState";

export const actionTypes = {
  COMMON_SET_LOADING: 'COMMON_SET_LOADING',
  COMMON_FETCH_LINKAGE_DATA: 'COMMON_FETCH_LINKAGE_DATA',
  COMMON_FETCH_LINKAGE_DATA_SUCCESS: 'COMMON_FETCH_LINKAGE_DATA_SUCCESS',
  COMMON_GET_TOP_RESULT: 'COMMON_GET_TOP_RESULT',
  COMMON_GET_TOP_RESULT_SUCCESS: 'COMMON_GET_TOP_RESULT_SUCCESS',
  COMMON_GET_TOP_RESULT_PENDING: 'COMMON_GET_TOP_RESULT_PENDING',
};

export const setLoading = (isLoading: boolean) => ({
  type: actionTypes.COMMON_SET_LOADING,
  isLoading
});

export const fetchLinkageData = (navigation: StockNavigation, stockCode= '0', firstLoadLinkage = false) => ({
  type: actionTypes.COMMON_FETCH_LINKAGE_DATA,
  navigation,
  stockCode,
  firstLoadLinkage
});

export const fetchLinkageDataSuccess = (data: ILinkageData) => ({
  type: actionTypes.COMMON_FETCH_LINKAGE_DATA_SUCCESS,
  data
});

export const getTopResult = () => ({
  type: actionTypes.COMMON_GET_TOP_RESULT
});

export const getTopResultPending = () => ({
  type: actionTypes.COMMON_GET_TOP_RESULT_PENDING
});

export const getTopResultSuccess = (data: IResultTopState) => ({
  type: actionTypes.COMMON_GET_TOP_RESULT_SUCCESS,
  data
});