export const calcToshinChartInfo = (min: number, max: number) => {
  const step = (max - min) / 4;
  let roundUnit = Math.pow(10, Math.floor(Math.log10(step)));
  let roundedStep = round(step, roundUnit);
  // Increase measurement
  if ((Math.abs(step - roundedStep) / roundUnit) > 0.4) {
    roundUnit -= roundUnit / 2;
    roundedStep = round(step, roundUnit);
  }
  let roundedMin = round(min, roundUnit);
  let roundedMax = round(max, roundUnit);

  let newMin = roundedMin - roundedStep;
  let newMax = roundedMax + roundedStep;
  if (Math.abs(roundedMax - max) < Math.abs(roundedMin - min)) {
    roundedMin = newMax - roundedStep * 5;
    // Increase measurement when Min is too different than RoundMin (second line from bottom)
    if (Math.abs(min - roundedMin) / roundedStep > 0.5) {
      newMax = min - roundedMin < 0 ?
        newMax - roundedStep / 2
        : newMax + roundedStep / 2
    }
    return {
      tickRange: roundedStep,
      min: newMax - roundedStep * 6,
      max: newMax
    }
  } else {
    // Increase measurement when Max is too different than RoundMax (second line from top)
    roundedMax = newMin + roundedStep * 5;
    if (Math.abs(max - roundedMax) / roundedStep > 0.5) {
      newMin = max - roundedMax < 0 ?
        newMin - roundedStep / 2
        : newMin + roundedStep / 2
    }
    return {
      tickRange: roundedStep,
      min: newMin,
      max: newMin + roundedStep * 6
    }
  }
};

const round = (value: number, roundUnit: number) => {
  const surplus = value % roundUnit;
  return surplus / roundUnit < 0.5 ? value - surplus : (value - surplus) + roundUnit;
};


export const getTickValueYAxis = (dataYMin: number, dataYMax: number) => {
  const ticks: number[] = [];
  let dataYMinProcessed = dataYMin;
  let dataYMaxProcessed = dataYMax;
  const baseUnit = 4;
  const minRaw =  dataYMin
  const maxRaw =  dataYMax
  const unbufferedRange = Math.round(maxRaw - minRaw)
  const bufferLabel = unbufferedRange/baseUnit;

  const newDataYMax = maxRaw + bufferLabel
  const range = unbufferedRange + bufferLabel
  let unroundedTickSize = range/(baseUnit);
  let x = Math.ceil(Math.log10(unroundedTickSize));

  const deltaSteps: number[] = [0.1,0.2,0.25,0.3,0.4,0.5,0.6,0.7,0.75,0.8,0.9,1];
  let roundedTickRange = unroundedTickSize
  if(minRaw < 0) {
    //bottom extra area
    unroundedTickSize += (unroundedTickSize / baseUnit)
  }
    do {
      //find the valid roundedTickRange
      const pow10x = Math.pow(10, x);
      let temp = unroundedTickSize / pow10x
      const tempFloor = Math.floor(temp)
      let deltaTemp = temp - tempFloor
      if(deltaTemp > 0){
          for (let index = 0; index < deltaSteps.length; index++) {
            if(deltaTemp < deltaSteps[index]) {
              deltaTemp = deltaSteps[index]
              break;
            }
          }
      }
      temp = tempFloor + deltaTemp

      roundedTickRange = temp * pow10x;
      const firstLabel = (Math.ceil(minRaw/roundedTickRange) - (minRaw < 0? 1 : 0)) * roundedTickRange
      const padding =  minRaw - firstLabel
      const lastLabel = firstLabel+ baseUnit * roundedTickRange
      if(lastLabel <= newDataYMax * 1.2 + padding && lastLabel >= newDataYMax) {
        //valid roundedTickRange for render beautiful chart
        break
      }
      x--
    } while (x > 0)

  const gap = Math.round(roundedTickRange)

  let firstLabel = (Math.floor(minRaw/gap) - (minRaw < 0 ? 1 : 0)) * gap
  ticks.push(firstLabel);
  let nextLabel = firstLabel
  for (let index = 1; index <= baseUnit; index++) {
    nextLabel += gap
    ticks.push(nextLabel);
  }

  dataYMinProcessed = firstLabel - gap;
  dataYMaxProcessed = nextLabel + gap;
  ticks.unshift(dataYMinProcessed)
  ticks.push(dataYMaxProcessed)

  const tickRange = gap;

  return {
    ticks,
    dataYMinProcessed,
    dataYMaxProcessed,
    tickRange
  };
}
