// import React from "react";
import styled from 'styled-components';
import { $color01, gradient8, media } from 'src/styled/helpers/';

const PanelGradient = styled.div`
  ${gradient8()};
  border-radius: 8px;
  color: ${$color01};
  font-size: 13px;
  padding: 20px 15px;
  text-align: left;

  * {
    margin: 0;
  }

  .panel-header {
    padding-bottom: 5px;
    ${media.mobile`
      padding-bottom: 15px;
    `}
    border-bottom: 1px solid #fff;
  }

  .panel-subtext {
    font-size: 13px;
    line-height: 1;
    margin-bottom: 5px;
  }

  .panel-headingtext {
    font-size: 19px;
    font-weight: bold;
  }
  ${media.mobile`
    .panel-body {
      padding-top: 20px;
    }
  `}

  .panel-col {
    display: flex;
    flex-flow: row nowrap;
    ${media.mobile`
      &:not(:last-child) {
        margin-bottom: 20px;
      }
    `}
  }

  .col-label {
    flex: 0 0 auto;
    font-weight: bold;
    padding-right: 15px;
  }

  .col-text {
    flex: 1 1 0;
    width: auto;
    font-weight: bold;
    ${media.mobile`
      margin-top: 4px;
      p {
        &:not(:last-child) {
          margin-bottom: 10px;
        }
      }
  `}
  }

  ${media.tabletUp`
    padding: 25px 25px;

    .panel-body {
      padding-top: 10px;
      display: flex;
      flex-flow: row nowrap;
    }

    .panel-col {
      flex: 0 0 auto;
      width: 50%;
    }

    .col-label {
    }

    .col-text {
      padding-right: 20px;
      padding-top: 4px;
      p {
        display: inline-block;
      }
    }

    .panel-subtext {
      font-size: 15px;
    }

    .panel-headingtext {
      font-size: 24px;
    }
  `}

  ${media.iphone5`
    font-size: 12.5px;
  `}

  ${media.ie`
    .col-text {
      p {
        &:not(:last-child) {
          margin-right: 13px;
        }
      }
    }
  `}
`;

export default PanelGradient;
export { PanelGradient };
