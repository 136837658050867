import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import {
  Button as StyledButton,
  RowWrapper,
  RowMini,
  Row1ColLeft,
  Row1ColRight,
  ColMini,
  MobileVisible,
  MobileHidden,
  getColorType,
} from 'src/styled';
import iconLookup from 'src/assets/images/lookup.png';
import iconLookupMinus from 'src/assets/images/lookup-minus.png';
import favoriteOn from 'src/assets/images/img-favorite-on.png';
import favoriteOff from 'src/assets/images/img-favorite-off.png';
import { StockNavigation } from 'src/interface/IBookmarkState';
import {
  formatMoney,
  getDispValue,
  isMobileViewport,
  getWindowSize,
  getDeatilStockKabuLink,
  getDeatilStockToShinLink,
  toASCII,
  formatYYMMDDJapan
} from 'src/common/utils/functionUtil';
import { connect } from 'react-redux';
import { unbookmarkStock } from 'src/bookmark/bookmarkAction';
import { Dispatch } from 'redux';

import { IResStockState } from 'src/interface/IToshinState';
import { getToshinChartByStock } from 'src/toshin/toshinAction';
import HistoricalChart from 'src/product/index/_productSurveyContainer/HistoricalChart';
import { IAppState } from 'src/interface/IAppState';
import { IDataChart } from 'src/interface/IDataChart';
import VisibilitySensor from 'react-visibility-sensor'
import SkeletonResultFooter from 'src/product/skeleton/SkeletonResultFooter';
import LupinForm from 'src/common/lupin_form/LupinForm';
import CollapsibleContent from './CollapsibleContent';
import { isGuest } from 'src/common/utils/functionUtil';

const IconSample = styled.i<{ favorite: boolean }>`
  background-image: url(${props =>
    props.favorite ? favoriteOn : favoriteOff});
  background-size: 18px auto;
  background-repeat: no-repeat;
  display: inline-block;
  width: 18px;
  height: 18px;
`;

const IconLookup = styled.i<{ isOpen: boolean }>`
  background-image: url(${props => props.isOpen ? iconLookupMinus : iconLookup});
  background-size: 41px 22px;
  background-repeat: no-repeat;
  display: inline-block;
  width: 41px;
  height: 22px;
`;

interface Props {
  favorite?: any;
  typeRow?: string;
  bookmark?: IResStockState;
  unbookmarkStock?(id: string, navigation: StockNavigation): void;
  navigation?: StockNavigation;
  stock?: IResStockState;
  handleonClickBookmarkIcon?(): void;
  getToshinChartByStock?(stock_id: string): void;
  dataChart?: Array<IDataChart> | [];
}

const initStock: IResStockState = {
  id: '',
  fcode: '',
  stock_code: '',
  trust_name: '',
  trust_universe: '',
  is_bookmark: false,
  url: '',
  nav: 0,
  nav_change: 0,
  nav_rate_change: 0,
  net_asset: 0,
  nav_daily_return: 0,
  yearly_dividend: 0,
  tcode: '',
  t_listed_flag: '0',
  n_listed_flag: '0',
  f_listed_flag: '0',
  s_flag: '0',
  stock_code_l: '0'
};


const RowTypeOne: React.FC<Props> = ({ favorite, stock = initStock, handleonClickBookmarkIcon }) => {
  const row4State = stock.dw_yt_contents ? '' : 'has-no-data';
  const { nav_change, nav_rate_change, nav_daily_return, nav, trust_name, stock_code, dw_yt_contents, yearly_dividend } = stock;
  const navRateChangeNull = nav_rate_change === null
  const navIsZero = nav === 0

  const openStock = () => {
    window.open(getDeatilStockKabuLink(stock),"mywindow","width=1024,height=845,scrollbars=yes,resizable=1");
  }

  const checkColClassName = (col: string) => {
    return isGuest() ? 'col-guest' : col
  }

  return (
    <RowWrapper className="paper-progress-1">
      <RowMini className="minirow-1">
        <Row1ColLeft>{stock.trust_universe}</Row1ColLeft>
        { !isGuest()  && <Row1ColRight onClick={handleonClickBookmarkIcon}>
                        <span className="text-favorite">お気に入り</span>
                        <IconSample favorite={favorite} className="icon-star" />
                      </Row1ColRight>
        }
      </RowMini>
      <RowMini className="minirow-2">
        <div onClick={openStock} className="no-style">{toASCII(trust_name)}</div>
      </RowMini>
      <RowMini className={`minirow-3 ${row4State}`}>
        <ColMini className={isGuest() ? 'col-guest' : 'col-1'}>
          <p className="mobile-left line-1">株価</p>
          <p className="mobile-right line-number">{!navIsZero ? `${formatMoney(nav)} 円`: '-円'}</p>
        </ColMini>
        <ColMini colorType={getColorType((navRateChangeNull || navIsZero) ? 0 : nav_change)} className={checkColClassName('col-2')}>
          <p className="mobile-left line-1">前日比</p>
          <p className={isGuest() ? 'mobile-right line-number guest' : 'mobile-right line-number'}>
              { (navRateChangeNull || navIsZero) ?  '-' : getDispValue(nav_change, formatMoney(nav_change))}
               円
               ({ (navRateChangeNull || navIsZero) ? '-' : getDispValue(nav_rate_change === 0 ? 0 : nav_rate_change.toFixed(2))}%)
          </p>
        </ColMini>
        <ColMini colorType={getColorType(navIsZero ? 0 : nav_daily_return)} className={checkColClassName('col-2')}>
          <p className="mobile-left line-1">配当利回り</p>
          <p className="mobile-right line-number">{navIsZero ? '-%' : `${getDispValue(nav_daily_return)} %`}</p>
        </ColMini>
        <ColMini className={checkColClassName('col-2')}>
          <p className="mobile-left line-1">PER(株価収益率)</p>
          <p className="mobile-right line-number">{navIsZero ? '-倍' : `${formatMoney(yearly_dividend)} 倍`}</p>
        </ColMini>
        { !isGuest() &&
          <ColMini className="col-3">
            <MobileHidden>
              <LupinForm
                type="kabu"
                stockCode={stock_code}
              >
              <StyledButton gradient>つみたてる</StyledButton>
              </LupinForm>
            </MobileHidden>
          </ColMini>
        }
      </RowMini>
      <RowMini className={`minirow-4 ${row4State}`}>
        {dw_yt_contents &&
          <>
            <p className="small">株主優待</p>
            <CollapsibleContent content={dw_yt_contents}/>
          </>
        }
        { !isGuest() &&
          <MobileVisible className="mobile-button-temp">
            <LupinForm
              type="kabu"
              stockCode={stock_code}
            >
              <StyledButton reverse gradient>つみたてる</StyledButton>
            </LupinForm>
          </MobileVisible>
        }
      </RowMini>
    </RowWrapper>
  )
};

const RowTypeTwo: React.FC<Props> = ({ favorite, stock = initStock, handleonClickBookmarkIcon, getToshinChartByStock, ...props }) => {
  const [isShowingChart, setChartDisplay] = useState(false);
  const { dataChart } = props;
  const showingChartCSSState = isShowingChart ? `is-showing-chart` : ``;
  const currentIsMobile = isMobileViewport();
  const { width } = getWindowSize();
  const heightSkeleton = width <= 320 ? 220 : width >=768 ? 300 : 250;
  useEffect(() => {
    if (typeof getToshinChartByStock === 'function')
      getToshinChartByStock(stock.tcode)
  }, [getToshinChartByStock, stock.tcode]);
  const { nav_change, nav_rate_change, stock_code_l, nav, trust_name, tcode } = stock;

  const navRateChangeNull = nav_rate_change === null
  const navIsZero = nav === 0

  const onChange = (isVisible) => {
    if (currentIsMobile) {
      setChartDisplay(isVisible)
    }
  };

  const openStock = () => {
    window.open(getDeatilStockToShinLink(stock_code_l),"mywindow","width=1024,height=845,scrollbars=yes,resizable=1");
  }
  const checkColClassName = (col: string) => {
    return isGuest() ? 'col-guest' : col
  }  

  return (
    <VisibilitySensor
      onChange={onChange}
      intervalCheck={true}
      scrollCheck={true}
      partialVisibility={true}
      offset={{ top: 150 }}
    >
      <RowWrapper className="paper-progress">
        <p className="standard-date">{`基準日: ${stock.standard_date ? formatYYMMDDJapan(stock.standard_date) : '-'}`}</p>
        <RowMini className="minirow-1">
          <Row1ColLeft>{stock.trust_universe}</Row1ColLeft>
          { !isGuest()  && <Row1ColRight onClick={handleonClickBookmarkIcon}>
                        <span className="text-favorite">お気に入り</span>
                        <IconSample favorite={favorite} className="icon-star" />
                      </Row1ColRight>
          }
        </RowMini>
        <RowMini className="minirow-2">
          <div onClick={openStock} className="no-style">{toASCII(trust_name)}</div>
        </RowMini>
        <RowMini className={`minirow-3 ${showingChartCSSState}`}>
          <ColMini className={checkColClassName('col-1')}>
            <p className="mobile-left line-1">基準価格</p>
            <p className="mobile-right line-number">{!navIsZero ? `${formatMoney(nav)} 円`: '-円'}</p>
          </ColMini>
          <ColMini colorType={getColorType((navRateChangeNull || navIsZero) ? 0 : nav_change)} className={checkColClassName('col-2')}>
            <p className="mobile-left line-1">前日比</p>
            <p className={isGuest() ? 'mobile-right line-number guest' : 'mobile-right line-number'}>
              { (navRateChangeNull || navIsZero) ?  '-' : getDispValue(nav_change, formatMoney(nav_change))}
               円
               ({ (navRateChangeNull || navIsZero) ? '-' : getDispValue(nav_rate_change === 0 ? 0 : nav_rate_change.toFixed(2))}%)
            </p>
          </ColMini>
          <ColMini className="col-3">
            <MobileHidden>
              <p className="mobile-left line-1">チャート</p>
              <p className="mobile-right line-number">
                <button className="show-chart" onClick={e => { e.preventDefault(); setChartDisplay(!isShowingChart) }}>
                  <IconLookup isOpen={isShowingChart} />
                </button>
              </p>
            </MobileHidden>
          </ColMini>
          {!isGuest() &&
             <ColMini className="col-3">
              <MobileHidden>
                <LupinForm
                  type="toshin"
                  stockCode={stock_code_l}
                >
                  <StyledButton gradient>つみたてる</StyledButton>
                </LupinForm>
              </MobileHidden>
            </ColMini>
          }
        </RowMini>

        <RowMini className={`minirow-4 ${showingChartCSSState}`}>
          <div className={`minichart-wrapper ${isShowingChart ? 'is-visible' : ''}`}>
            {
              (dataChart && isShowingChart) ?
                <HistoricalChart dataChart={dataChart} tcode={tcode} />
                : currentIsMobile ?
                  <SkeletonResultFooter blockHeight={heightSkeleton}
                    rectX={"0"} rectWidth={"400"} rectHeight={heightSkeleton + ""} />
                  : null
            }
          </div>
          {!isGuest() &&
             <MobileVisible className="mobile-button-temp">
              <LupinForm
                  type="toshin"
                  stockCode={stock_code_l}
                >
                <StyledButton reverse gradient>つみたてる</StyledButton>
              </LupinForm>
            </MobileVisible>
          }
        </RowMini>
      </RowWrapper>
    </VisibilitySensor>
  );
};

const RowTypeThree: React.FC<Props> = ({ favorite, stock = initStock, handleonClickBookmarkIcon, getToshinChartByStock, ...props }) => {
  const [isShowingChart, setChartDisplay] = useState(false);
  const { dataChart } = props;
  const showingChartCSSState = isShowingChart ? `is-showing-chart` : ``;
  const currentIsMobile = isMobileViewport();
  const { width } = getWindowSize();
  const heightSkeleton = width <= 320 ? 220 : width >=768 ? 300 : 250;
  useEffect(() => {
    if (typeof getToshinChartByStock === 'function')
      getToshinChartByStock(stock.tcode)
  }, [getToshinChartByStock, stock.tcode]);
  const { nav_change, nav_rate_change, stock_code_l, nav, trust_name, tcode } = stock;

  const navRateChangeNull = nav_rate_change === null
  const navIsZero = nav === 0

  const onChange = (isVisible) => {
    if (currentIsMobile) {
      setChartDisplay(isVisible)
    }
  };

  const openStock = () => {
    window.open(getDeatilStockToShinLink(stock_code_l),"mywindow","width=1024,height=845,scrollbars=yes,resizable=1");
  }
  const checkColClassName = (col: string) => {
    return isGuest() ? 'col-guest' : col
  }  
  const elementBookmark = document.getElementById('bookmarks-tab')
  return (
    <VisibilitySensor
      onChange={onChange}
      intervalCheck={true}
      scrollCheck={true}
      partialVisibility={true}
      offset={{ top: 0 }}
      containment={elementBookmark}
    >
      <RowWrapper className="paper-progress">
        <p className="standard-date">{`基準日: ${stock.standard_date ? formatYYMMDDJapan(stock.standard_date) : '-'}`}</p>
        <RowMini className="minirow-1">
          <Row1ColLeft>{stock.trust_universe}</Row1ColLeft>
          { !isGuest()  && <Row1ColRight onClick={handleonClickBookmarkIcon}>
                        <span className="text-favorite">お気に入り</span>
                        <IconSample favorite={favorite} className="icon-star" />
                      </Row1ColRight>
          }
        </RowMini>
        <RowMini className="minirow-2">
          <div onClick={openStock} className="no-style">{toASCII(trust_name)}</div>
        </RowMini>
        <RowMini className={`minirow-3 ${showingChartCSSState}`}>
          <ColMini className={checkColClassName('col-1')}>
            <p className="mobile-left line-1">基準価格</p>
            <p className="mobile-right line-number">{!navIsZero ? `${formatMoney(nav)} 円`: '-円'}</p>
          </ColMini>
          <ColMini colorType={getColorType((navRateChangeNull || navIsZero) ? 0 : nav_change)} className={checkColClassName('col-2')}>
            <p className="mobile-left line-1">前日比</p>
            <p className={isGuest() ? 'mobile-right line-number guest' : 'mobile-right line-number'}>
              { (navRateChangeNull || navIsZero) ?  '-' : getDispValue(nav_change, formatMoney(nav_change))}
               円
               ({ (navRateChangeNull || navIsZero) ? '-' : getDispValue(nav_rate_change === 0 ? 0 : nav_rate_change.toFixed(2))}%)
            </p>
          </ColMini>
          <ColMini className="col-3">
            <MobileHidden>
              <p className="mobile-left line-1">チャート</p>
              <p className="mobile-right line-number">
                <button className="show-chart" onClick={e => { e.preventDefault(); setChartDisplay(!isShowingChart) }}>
                  <IconLookup isOpen={isShowingChart} />
                </button>
              </p>
            </MobileHidden>
          </ColMini>
          {!isGuest() &&
             <ColMini className="col-3">
              <MobileHidden>
                <LupinForm
                  type="toshin"
                  stockCode={stock_code_l}
                >
                  <StyledButton gradient>つみたてる</StyledButton>
                </LupinForm>
              </MobileHidden>
            </ColMini>
          }
        </RowMini>

        <RowMini className={`minirow-4 ${showingChartCSSState}`}>
          <div className={`minichart-wrapper ${isShowingChart ? 'is-visible' : ''}`}>
          {
              (dataChart && isShowingChart) ?
                <div>
                  <HistoricalChart dataChart={dataChart} tcode={tcode} />

                </div>
                : currentIsMobile ?
                  <div style={{width: "100%", height: heightSkeleton, background: "#f2f2f2"}}></div>
                  : null
            }
          </div>
          {!isGuest() &&
             <MobileVisible className="mobile-button-temp">
              <LupinForm
                  type="toshin"
                  stockCode={stock_code_l}
                >
                <StyledButton reverse gradient>つみたてる</StyledButton>
              </LupinForm>
            </MobileVisible>
          }
        </RowMini>
      </RowWrapper>
    </VisibilitySensor>
  );
};

const TableRow: React.FC<Props> = (props) => {
  const {
    typeRow, favorite, stock,
    handleonClickBookmarkIcon, dataChart, getToshinChartByStock
  } = props;
  if (typeRow === '2') return (
    <RowTypeTwo
      dataChart={stock && dataChart ? dataChart[stock.tcode] : []} favorite={favorite}
      stock={stock} handleonClickBookmarkIcon={handleonClickBookmarkIcon}
      getToshinChartByStock={getToshinChartByStock}
    />
  );

  if (typeRow === '3') return (
    <RowTypeThree
      dataChart={stock && dataChart ? dataChart[stock.tcode] : []} favorite={favorite}
      stock={stock} handleonClickBookmarkIcon={handleonClickBookmarkIcon}
      getToshinChartByStock={getToshinChartByStock}
    />
  );

  return (
    <RowTypeOne
      favorite={favorite} stock={stock}
      handleonClickBookmarkIcon={handleonClickBookmarkIcon}
    />
  );
};

const mapStateToProps = (state: IAppState) => ({
  dataChart: state.toshinState.dataChart
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  unbookmarkStock: (id: string, navigation: StockNavigation) => dispatch(unbookmarkStock(id, navigation)),
  getToshinChartByStock: (stock_id: string) => dispatch((getToshinChartByStock(stock_id))),
});


export default connect(mapStateToProps, mapDispatchToProps)(TableRow);

