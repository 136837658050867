import { AxiosResponse } from 'axios';
import { appAxios } from "src/app/appAxios";

export const AnswerService = {
  submitProductAnswer: async (params: any) => {
    try {
      const { data }: AxiosResponse = await appAxios.post('/robo/product/answers', params);
      return data;
    } catch (error) {
      throw error;
    }
  },
  submitToshinAnswer: async (params: any) => {
    try {
      const { data }: AxiosResponse = await appAxios.post('/robo/toshin/answers', params);
      return data;
    } catch (error) {
      throw error;
    }
  },
  submitKabuAnswer: async (params: any) => {
    try {
      const { data }: AxiosResponse = await appAxios.post('/robo/kabu/answers', params);
      return data;
    } catch (error) {
      throw error;
    }
  },
};