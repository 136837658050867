import { all, takeLatest, call, put } from "redux-saga/effects";
import { actionTypes, fetchLinkageDataSuccess,
   getTopResultSuccess, getTopResultPending } from "./commonAction";
import { AuthService } from "src/api/AuthService";
import { TopService } from "src/api/TopService";
import { ILupinInfo } from "src/interface/ILinkageData";
import { createLupinForm, jsonToQueryString } from "./utils/functionUtil";
import { show } from "redux-modal";

// Workers
function* fetchLinkageDataWorker(action: any) {
  const {navigation, stockCode } = action
  const {data} = yield call(AuthService.linkageData);
  if (data.is_blocked) {
    yield put(show('AUTH_MODAL'))
  } else {
    yield put(fetchLinkageDataSuccess(data));
    const formData: ILupinInfo =  {...data, naviType: navigation, stockCode}
    const lupinData = jsonToQueryString(createLupinForm(formData))
    const lupin2Url = formData.naviType === 'product' ? process.env.REACT_APP_LUPIN2_FW_URL : process.env.REACT_APP_LUPIN2_URL;
    if (formData.naviType === 'product') {
      window.open(`${lupin2Url}${lupinData}`, "Windows: FwsOnline");
    } else {    
      if (!window.opener) {
        window.open(`${lupin2Url}${lupinData}`)
      } else {
        window.opener.location.href = `${lupin2Url}${lupinData}`
      }
    }
  }
}

function* getResultTopWorker() {
  yield put(getTopResultPending())
  const {data} = yield call(TopService.getTopResult);
  yield put(getTopResultSuccess(data))
}

// Watchers
function* fetchLinkageDataWatcher() {
  yield takeLatest(actionTypes.COMMON_FETCH_LINKAGE_DATA, fetchLinkageDataWorker)
}

function* getResultTopWatcher() {
  yield takeLatest(actionTypes.COMMON_GET_TOP_RESULT, getResultTopWorker)
}

export function* commonSaga() {
  yield all([
    fetchLinkageDataWatcher(),
    getResultTopWatcher()
  ]);
}