import styled from "styled-components";

// import React from "react";

const ModalAuthStyled = styled.div`
  &.modal-blocked {
    .modal-content {
      z-index: 10;
      background-color: #fff;
      height: auto;
      padding: 80px 0;
      margin: 0 20px;
      width: auto;
      .modal-close {
        position: absolute;
        top: 10px;
        right: 10px;
        &:before, &:after {
          background-color: #333333;
        }
      }
      p {
        text-align: center;
        margin: 0 40px
      }
  }
  
  }
`;

export default ModalAuthStyled;
export { ModalAuthStyled };
