import { AxiosResponse } from 'axios';
import appAxios, {authAxios} from "src/app/appAxios";

export const AuthService = {
  checkAuth: async (params: any, config: any = {}) => {
    try {
      const { data }: AxiosResponse = await authAxios.post('/authorize', params, config);
      return data;
    } catch (error) {
      throw error;
    }
  },
  linkageData: async () => {
    try {
      const { data }: AxiosResponse = await appAxios.get('/auth/linkage_data');
      return data;
    } catch (error) {
      throw error;
    }
  }
};