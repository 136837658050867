import { AxiosResponse } from 'axios';
import { appAxios } from "src/app/appAxios";

export const ToshinService = {
  getResultToshinList: async () => {
    try {
      const { data }: AxiosResponse = await appAxios.get(`/robo/toshin/results?limit=3`);
      return data;
    } catch (error) {
      throw error;
    }
  },
  getResultToshin: async (id: string) => {
    try {
      const { data }: AxiosResponse = await appAxios.get(`/robo/toshin/results/${id}`);
      return data;
    } catch (error) {
      throw error;
    }
  },
  getToshinDataChart: async (stock_id: string) => {
    try {
      const { data }: AxiosResponse = await appAxios.get(`/stock/${stock_id}/chart`);
      return data;
    } catch (error) {
      throw error;
    }
  }
};

