import { all, takeLatest, put, call } from "redux-saga/effects";
import { AxiosResponse } from 'axios';
import { actionTypes, getResultHistorySuccess,
   getResultProductListSuccess, getResultHistoryPending,
   getResultProductListPending } from "./productAction";
import { ProductApiService } from "src/api/ProductApiService";

function* getResultHistorySaga(action: any) {
  const { product_id } = action;
  try {
    yield put(getResultHistoryPending())
    const { data: { products } }: AxiosResponse = yield call(ProductApiService.getProductDetail, product_id)
    yield put(getResultHistorySuccess(products))
  } catch (error) {
    console.log(error)
  }
}

function* getResultProductListSaga() {
  try {
    yield put(getResultProductListPending())
    const { data : { results } }: AxiosResponse = yield call(ProductApiService.getResultProductList)
    yield put(getResultProductListSuccess(results))
  } catch (error) {
    console.log(error)
  }
}

function* watchGetResultHistory() {
  yield takeLatest(actionTypes.GET_RESULT_HISTORY, getResultHistorySaga);
}

function* watchGetProductReusultList() {
  yield takeLatest(actionTypes.GET_RESULT_PRODUCT_LIST, getResultProductListSaga);
}

export function* productSaga() {
  yield all([
    watchGetResultHistory(),
    watchGetProductReusultList()
  ]);
}
