// import React from "react";
import styled from 'styled-components';
import { $color08, COLOR_TYPE, $color28 } from 'src/styled/helpers/';

interface Props {
  colorType?: string;
}

const ButtonSmall = styled.div<Props>`
  font-weight: bold;
  border-radius: 4px;
  background: #fff;
  padding: 3px 7px;
  color: ${props => {
    if (!props.colorType) return $color08;
      return  props.colorType === COLOR_TYPE.FIRST ? $color08 : $color28
    }}
`;

export default ButtonSmall;
export { ButtonSmall };
