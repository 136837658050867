import styled from 'styled-components';

const DemoNavigator = styled.div`
  background-color: lightblue;
  font-size: 14px;
  left: 0;
  position: fixed;
  text-align: left;
  top: 50%;
  width: 130px;
  z-index: 99999;
  opacity: 0.2;
  transform: translateY(-50%);
  // visibility: hidden;

  &:hover {
    opacity: 1;
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  li {
    display: inline-block;
  }

  a {
    font-size: 11px;
    padding: 3px;
    display: block;
  }
`;

export default DemoNavigator;
export { DemoNavigator };
