// import React from "react";
import styled from 'styled-components';
import {BlockStyle1, flexRow } from 'src/styled/index';
import { getThemeColor, $color07, posr, media } from 'src/styled/helpers/';

const BlockStyle3 = styled(BlockStyle1)`
  text-align: left;
  font-size: 14px;
  .block-content {
    .content-item {
      display: flex;
      margin-bottom: 3px;
      .icon-left {
        margin-right: 5px;
        margin-top: 2px;
        .icon-check {
          width: 20px;
          height: 20px;
        }
      }
      .sub-text {
        width: 100%;
      }
    }
  }

  ${media.mobile`
    font-size: 12px;

    .block-content {
      .content-item {
        display: flex;
        .icon-left {
          margin-right: 5px;
          margin-top: 3px;
          .icon-check {
            width: 15px;
            height: 15px;
          }
        }
        .sub-text {
          width: 100%;
        }
      }
    }
  `}

  .image-placeholder {
    max-height: 80px;
    min-height: 45px;

    img {
      display: block;
      margin-left: auto;
      margin-right: auto;
      max-width: ${props =>
        props.maxImageWidth ? `${props.maxImageWidth}px` : 'none'
      };
    }
  }

  .block-heading {
    ${posr()};
    text-align: center;
    padding-top: 15px;
    margin-bottom: 15px;

    h5 {
      color: ${props => getThemeColor(props.theme, $color07)};
      font-size: 18px;
      margin: 15px 0 0;
    }

    ${media.mobile`
      ${() => flexRow()};
      align-items: center;
      margin-bottom: 10px;
      padding-top: 0;

      .image-placeholder {
        flex: 0 0 auto;
        width: 26px;
        margin-right: 12px;
        min-height: 0;

        img {
          max-width: 100%;
        }
      }

      h5 {
        flex: 1 1 0px;
        font-size: 16px;
        line-height: 1;
        margin: 0;
        text-align: left;
        width: auto;
      }
    `}
  }
`;

export default BlockStyle3;
export { BlockStyle3 };
