import styled from 'styled-components';
import {media} from "../helpers";
// import {

// } from 'src/styled/helpers/';

const SurveyResultPage = styled.div`
  .page-section__body {
    ${media.mobile`
      .columns {
        &:last-child {
          margin-bottom: 0;
        }
      }
    `}
  }
  .result-actions {
    ${media.mobile`
      & > .columns {
        display: flex;
        flex-flow: column nowrap;

        .center-align {
          justify-content: center;
          order: 2;
        }

        .col-offset-2 {
          order: 1;
        }
      }
    `}
  }
`;

export default SurveyResultPage;
export { SurveyResultPage };
