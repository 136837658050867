// import React from "react";
import styled from 'styled-components';
import {
  $color05,
  $color31,
  $color32,
  $color33,
  $color34,
  $color35,
} from 'src/styled/helpers/';

interface Props {
  // order?: '1' | '2' | '3' | '4' | '5'
  order?: string;
}

const ProgressBlock = styled.div<Props>`
  text-align: left;

  &:not(:last-child) {
    margin-bottom: 12px;
  }
`;

const ProgressBar = styled.div<Props>`
  position: relative;
  z-index: 1;
  background-color: ${$color05};
  height: 3px;
  border-radius: 14px;
  margin-bottom: 6px;

  span {
    background-color: ${props => {
      if (!props.order) return $color31;

      switch (props.order) {
        case '1':
        default:
          return $color31;
        case '2':
          return $color32;
        case '3':
          return $color33;
        case '4':
          return $color34;
        case '5':
          return $color35;
      }
    }}
    position: absolute;
    z-index: 10;
    left: 0;
    top: 0;
    height: 100%;
  }
`;

const ProgressContent = styled.div`
  text-align: left;
  font-size: 13px;
  font-weight: bold;
`;

const ProgressNumbers = styled.div`
  display: flex;
  flex-flow: row nowrap;
  font-size: 15px;
  margin-bottom: 8px;

  .number-left {
    margin-right: auto;
  }

  .number-right {
    margin-left: auto;
  }
`;

export default ProgressBlock;
export {
  ProgressBlock,
  ProgressBar as ProgressBlockBar,
  ProgressContent as ProgressBlockContent,
  ProgressNumbers as ProgressBlockNumbers
};
