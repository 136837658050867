import styled from 'styled-components';
import {media, flexRow, $color05, gradient1, GAP} from 'src/styled/helpers/';

const CategoryChoicePage = styled.div`
  .text-1 {
    font-size: 13px;
    line-height: 1;
    padding: 25px 0;
    text-align: center;
  }

  .image-placeholder-wrapper {
    height: 40px;
  }

  .image-placeholder {
    max-height: 100%;
  }

  .max-block-2 {
    padding-bottom: 0;
    padding-top: 0;

    ${media.tabletUp`
      padding-bottom: 60px;
      margin-bottom: 30px;
      margin-top: 30px;
    `}
  }

  .max-block-3 {
    padding-top: 25px;

    ${media.tabletUp`
      padding-top: 10px;
    `}
  }

  .category-choice-block-3 {
    ${media.mobile`
      &.paper-kabu-wrapper,
      &.paper-tosin-wrapper {
        background: ${$color05};
        padding: 0 40px;

        ${media.iphone5`
          padding: 0 ${GAP.IPHONE5};
        `}

        .block-content {
          margin-bottom: 0;
        }
      }

      &.paper-kabu-wrapper {
        .block-heading h5 br {
          display: none;
          visibility: hidden;
        }
      }
    `}

    ${media.tabletUp`
      background-color: ${$color05};

      .block-heading {
        height: 110px;
      }
    `}

    ${media.iphone5`
      &.paper-tosin-wrapper {
        padding: 0 25px;
        
        .block-heading {
          padding-right: 72px;
          padding-top: 7px;

          h5 {
            font-size: 14.5px;
          }
        }

        .image-placeholder {
          max-width: 72px;
        }
      }
    `}
  }

  .category-choice-block-mobile-1 {
    ${gradient1()};
  }

  .category-choice-block-1 {
    ${media.tabletUp`
      margin-bottom: 25px;

      &.category-toshin-paper {
        .is-4 {
          min-height: 300px;
        }
      }

      &.category-kabu-paper {
        .is-4 {
          min-height: 360px;
        }
      }

      .block-toshin-column {
        .image-placeholder {
          height: 84px;
        }
      }

      .block-kabu-column {
        .image-placeholder {
          height: 89px;
        }
      }
    `}
  }

  .category-choice-block-1,
  .category-choice-block-3 {
    ${media.tabletUp`
      .col-1 {
        padding-left: 86px;
        padding-right: 0;
      }

      .col-2 {
        padding-left: 43px;
        padding-right: 43px;
      }

      .col-3 {
        padding-left: 0;
        padding-right: 86px;
      }
    `}
  }

  .carousel-items-wrapper {
    .mini {
      &-row {
        ${flexRow()};
        justify-content: center;
        margin-left: -18px;
        margin-right: -18px;
      }

      &-row--block {
        align-items: center;
        justify-content: center;
        margin-left: -18px;
        margin-right: -18px;
      }

      &-col {
        flex: 0 0 auto;
        padding-left: 40px;
        padding-right: 40px;
        width: 295px;

        ${media.tabletUp`
          padding-left: 18px;
          padding-right: 18px;
          width: 280px;

          .history-result-carousel-item,
          .kabu-carousel-item {
            height: 100%;
          }
        `}

        ${media.iphone5`
          padding-left: ${GAP.IPHONE5};
          padding-right: ${GAP.IPHONE5};
        `}
      }
    }
  }
`;

export default CategoryChoicePage;
export { CategoryChoicePage };
