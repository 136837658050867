// import React from "react";
import styled from 'styled-components';
import {$color01, $color05, $color08, $color09, $color10, $color14, media, size, SKIN} from "../helpers";
import arrowBack from "src/assets/images/left-arrow.png";
import { isIE } from "src/common/utils/functionUtil";
interface Props {
  arrowRotate?: number
}
const HelpPage = styled.div`
  padding: 20px;
  
  .datatable-tabmenu {
    li {
      cursor: pointer;
      width: calc(100% / 3);
      
      ${media.tabletUp`
        ${() => {
          if (isIE())
            return `
              width: auto;
            `;
          else {
            return `
              width: 240px;
            `;
          }
        }}
      `}
      
      &.is-active {
        cursor: default;
        pointer-events: none;
      }
      ${() => {
        if (isIE())
          return `
            a {
              padding: 20px 60px;
              display: inline-block;
            }
          `;
        }
      }}
    }
  }
`;

const getColor = (skin: string) => (
  skin === SKIN.PRIMARY ?
    $color08
    : skin === SKIN.SECONDARY ? $color09 : $color10
);

const HelpTab = styled.div<{skin: string}>`
  .break {
    padding: 0 40px;
    background: ${$color01}
    ${media.mobile`
      padding: 0 25px;
    `};
    &:after {
      display: block;
      content: ' ';
      border-bottom: 2px solid ${$color05};
    }
  }
  .header {
    background: ${$color01}
    padding: 36px 40px 30px;
    font-weight: bold;
    ${media.mobile`
      padding: 26px 20px 25px;
    `};
    
    &-title {
      font-size: 24px;
      color: ${({skin}) => getColor(skin)};
      text-align: center;
      ${media.mobile`
        font-size: 20px;
      `}
    };
    
    &-subtitle {
      font-size: 14px;
      text-align: center;
      ${media.mobile`
        font-size: 12px;
      `}
    };
  };
  .body {
    background: ${$color01};
    
    &-block {
      padding: 36px 40px 30px;
      ${media.mobile`
        padding: 26px 20px 25px;
      `};
    };
    
    &-title {
      font-size: 18px;
      font-weight: bold;
      color: ${({skin}) => getColor(skin)};
      text-align: center;
      ${media.mobile`
        font-size: 16px;
      `}
    };
    
    &-subtitle {
      font-size: 14px;
      text-align: center;
      ${media.mobile`
        font-size: 12px;
      `}
      &--2 {
        margin-top: 53px;
        ${media.mobile`
          margin-top: 28px;
        `}
      }
    };
    
    &-image {
      display: block;
      margin: 30px auto 0;
    }
    
    &-note {
      margin-top: 55px;
      ${media.mobile`
        margin-top: 35px;
      `}
      &-title {
        color: ${$color14}
        font-size: 16px;
        font-weight: bold;
        margin-bottom: 15px;
        ${media.mobile`
          margin-bottom-top: 7px;
        `}
        img {
          vertical-align: sub;
          height: 24px;
          margin-right: 5px;
        }
      }
      &-content {
        font-size: 14px;
      }
    }
  }
  .footer {
    background: transparent;
    padding: 25px 0 0;
    ${media.mobile`
      padding: 20px 0 0;
    `};
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    &--left {
      justify-content: flex-start;
    }
    &--right {
      justify-content: flex-end;
    }
    &--between {
      justify-content: space-between;
    }
  }
`;

const HelpPageNavigation = styled.div<Props>`
  &.navigation-right {
    text-align: right;
    width: 520px;
    margin: 10px auto;
    
    ${media.mobile`
      width: auto;
    `};
  }
  span {
    background-color: transparent;
    color: ${$color08};
    cursor: pointer;
    font-size: 15px;
    font-weight: bold;
    justify-content: center;
    margin: 0;
    ${media.mobile`
      font-size: 13px;
    `};
    &:before, &:after {
      content: ' ';
      ${size('12px', '14px')};
      display: none;
      background-image: url(${arrowBack});
      background-repeat: no-repeat;
      background-size: 9px;
    };  
    &:after {
      margin-left: 8px;
      margin-bottom: -2px;
      display: inline-block;
      transform: rotate(${props => props.arrowRotate ? props.arrowRotate : 90}deg); 
    }
  }
`;

export { HelpPage, HelpTab, HelpPageNavigation };
