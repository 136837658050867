import React, {CSSProperties} from 'react';

const AppVersion: React.FC = () => {
  if (process.env.REACT_APP_ENV === 'production' ) return null;

  const style: CSSProperties = {
    position: 'fixed',
    bottom: 5,
    right: 5,
    color: '#000',
    zIndex: 9999,
    padding: 5,
    background: 'rgba(0,0,0,0.2)',
    pointerEvents: 'none',
  };
  const tag = process.env.REACT_APP_VERSION;
  const version = tag && tag.match(/release-(?:dev|pro|staging)-([^=]+)/);
  return (
    <div style={style}>
      {version ? version[1] : 'development'}
    </div>
  )
};

export default AppVersion;