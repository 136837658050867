import ContentLoader from "react-content-loader";
import React from "react";

interface Props {
  blockHeight: number;
  rectX: string;
  rectWidth: string;
  rectHeight: string;
}
class SkeletonResultFooter extends React.PureComponent<Props>{
  render(){
    const { blockHeight } = this.props;
    const { rectX, rectWidth, rectHeight } = this.props;
    return(
        <ContentLoader
        style={{height:blockHeight,width:"100%"}}
        height={blockHeight}
        primaryColor="#f3f3f3"
        secondaryColor="#ecebeb">
          <rect x={rectX} y="0" rx="4" ry="4" width={rectWidth} height={rectHeight} />
        </ContentLoader>
    )
  }
}
export default SkeletonResultFooter;

