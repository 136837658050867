// import React from "react";
import styled from 'styled-components';
// import { media } from 'src/styled/helpers/';

interface Props {
  mobileEdgePadding?: number
}

const MaxContainer = styled.div<Props>`
  ${props =>
    props.mobileEdgePadding &&
    `
    @media screen and (max-width: 768px) {
      padding-left: ${props.mobileEdgePadding}px;
      padding-right: ${props.mobileEdgePadding}px;
    }
  `}
`;

export default MaxContainer;
export { MaxContainer };
