import { all, takeLatest, put, call } from "redux-saga/effects";
import { AxiosResponse } from 'axios';
import { actionTypes, getResultKabuListSuccess, getKabuResultSuccess, getResultKabuListPending, getKabuResultPending } from "./kabuAction";
import { KabuService } from "src/api/KabuService";

function* getResultKabuListSaga() {
  try {
    yield put(getResultKabuListPending())
    const { data : { results } }: AxiosResponse = yield call(KabuService.getResultKabuList);
    yield put(getResultKabuListSuccess(results))
  } catch (error) {
    console.log(error)
  }
}

function* getKabuResultSaga(action) {
  try {
    const { id } = action;
    yield put(getKabuResultPending())
    const { data }: AxiosResponse = yield call(KabuService.getKabuResult, id);
    yield put(getKabuResultSuccess(data))
  } catch (error) {
  }
}

function* watchGetKabuResultList() {
  yield takeLatest(actionTypes.GET_RESULT_KABU_LIST, getResultKabuListSaga);
}

function* watchGetKabuResult() {
  yield takeLatest(actionTypes.GET_KABU_RESULT, getKabuResultSaga);
}

export function* kabuSaga() {
  yield all([
    watchGetKabuResultList(),
    watchGetKabuResult()
  ]);
}
