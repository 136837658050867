import { AxiosResponse } from 'axios';
import { appAxios } from "src/app/appAxios";

export const  ProductApiService = {
  getProductDetail: async (product_id: string) => {
    try {
      const { data }: AxiosResponse = await appAxios.get(`/robo/product/results/${product_id}`);
      return data;
    } catch (error) {
      throw error;
    }
  },
  getResultProductList: async () => {
    try {
      const { data }: AxiosResponse = await appAxios.get(`/robo/product/results`);
      return data;
    } catch (error) {
      throw error;
    }
  }
};