// import React from "react";
import styled from 'styled-components';
import {
  $color01,
  $color13,
  $color12,
  posr,
  posa,
  size
} from 'src/styled/helpers/';

interface Props {
  isFullHeight?: boolean,
  mobile?: boolean,
}

const MessageGradient = styled.div<Props>`
  ${posr()};
  padding-left: 60px;
  width: 100%;

  .box-gradient {
    background: ${$color12};
    border-radius: 32px;
    display: flex;
    flex-flow: column nowrap;
    min-height: 200px;
    padding: 2px;
    text-align: left;
  }

  .box-stretch {
    ${size('100%')};
    background-color: ${$color01};
    border-radius: 30px;
    flex: 1 1 0;
    padding: 25px;
  }

  .corner {
    ${posa()}
    ${size('96px', '44px')}
    left: 0;
    bottom: -2px;
  }

  .text-heading {
    font-size: 13px;
    line-height: 1;
    font-weight: bold;
    color: ${$color13};
    margin: 0 0 3px 0;
  }

  .text-gradient {
    font-size: 32px;
  }

  ${props => {
  if (!props.mobile) return;

  return `
      padding-left: 13px;

      .box-gradient {
        min-height: auto;
      }

      .box-stretch {
        padding: 12px 25px;
      }

      .corner {
        ${size('46px', '36px')};
        bottom: -1px;
      }

      .text-heading {
        font-size: 11px;
      }

      .text-gradient {
        font-size: 18px;
        line-height: 1.45;
      }
    `;
}}

  ${props => {
  if (!props.isFullHeight) return;

  // Set the direct parent of this component with `display: flex` and `flex-flow: column nowrap;`
  return `
      height: 100%;

      .box-gradient {
        height: 100%;
      }
    `;
  }}
`;

export default MessageGradient;
export { MessageGradient };
