// import React from "react";
import styled from 'styled-components';
import { media, $color14 } from 'src/styled/helpers/';

const PageSectionTop = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  padding-bottom: 40px;
  padding-top: 30px;
  
  ${media.tabletUp`
    padding-bottom:  52px;
    padding-top: 52px;
  `}

  &.reduction {
  }
  .section-top {
    &__image {
      width: 40px;
      display: block;
      margin-bottom: 15px;
    }

    &__heading {
      color: ${$color14};
      font-size: 18px;
      font-weight: bold;
      line-height: 1;
      margin-bottom: 10px;
      ${media.mobile`
      font-size: 20px;
      `}
    }

    &__subtext {
      font-size: 13px;
      text-align: center;
    }
  }
`;

export default PageSectionTop;
export { PageSectionTop };
