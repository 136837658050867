// import React from "react";
import styled from 'styled-components';
import {
  $color01, $color08, gradient1, gradient2,
  gradient3, gradient4, gradient6, gradient7, media, noOutline,
  posa, posr, size
} from 'src/styled/helpers/';
import {Link} from "react-router-dom";


interface Props {
  gradient?: boolean | number,
  gradientOffset?: boolean | number,
  reverse?: boolean,
  active?: boolean,
  mobile?: boolean,
  surveyMiddleMobile?: boolean,
  fund_wrap?: boolean 
}

interface LinkProps {
  gradient?: boolean | number,
  gradientoffset?: boolean | number, // React warning => change to lowercase
  reverse?: boolean,
  active?: boolean,
  surveymiddlemobile?: boolean | number, // React warning => change to lowercase
}

const Button = styled.button<Props>`
  ${noOutline};
  background: ${$color08};
  color: ${$color01};
  padding: 14px 5px;
  cursor: pointer;
  border: none;
  border-radius: 6px;

  ${media.mobile`
    font-size: 14px;
  `}

  ${props => {
    if (!props.gradient) return;
    return `
      ${props.active ? gradient3() :  props.reverse ? gradient7() : gradient1()};

      @media screen and (max-width: 768px) {
        ${props.active ? gradient6() : props.reverse ? gradient1() : gradient2()};
      }

      color: ${$color01};
    `;
  }};

  ${props =>
    props.gradientOffset &&
    `
      ${posr()}
      ${size('100%', '60px')}
      background-color: ${$color08};
      border-radius: 6px 0 0 6px;
      color: ${$color01};
      font-size: 16px;
      font-weight: bold;
      padding-left: 30px;
      text-align: left;
      line-height: 28px;

      .offset-gradient {
        ${gradient4()};
        ${size('100%')};
        ${posa(-1)};
        border-radius: 6px 0 0 6px;
        top: 0;
        left: 0;
      }
    `}

  ${props => props.surveyMiddleMobile && `
    @media screen and (max-width: 768px) {
      height: 44px;
      line-height: 1;
    }
  `}
  ${media.mobile`
    font-size: 16px;
    height: 50px;
    line-height: 25px;
  `}
  ${props => props.fund_wrap && `
      @media screen and (max-width: 768px) {
      height: auto;
      ${size('110%', 'auto')}
      font-size: 14px;
      padding-left: 15px;
    }
  `}
`;

const StyledLink = styled(Link)<LinkProps>`
  ${noOutline};
  background: ${$color08};
  color: ${$color01};
  padding: 14px 5px;
  cursor: pointer;
  border: none;
  border-radius: 6px;
  display: block;
  text-align: center;
  font-family: 'Noto Sans JP', sans-serif;
  line-height: normal;

  &:hover {
    color: ${$color01};
  };

  ${media.mobile`
    font-size: 14px;
  `}

  ${props => {
  if (!props.gradient) return;

  return `
      ${props.reverse ? gradient2() : gradient1()};
      color: ${$color01};
    `;
}};

  // Using for button with offset width
  ${props =>
  props.gradientoffset &&
  `
    ${posr()}
    ${size('100%', '60px')}
    background-color: ${$color08};
    border-radius: 6px 0 0 6px;
    color: ${$color01};
    font-size: 16px;
    font-weight: bold;
    padding-left: 25px;
    text-align: left;
    line-height: 32px;

    .offset-gradient {
      ${gradient4()};
      ${size('100%')};
      ${posa(-1)};
      border-radius: 6px 0 0 6px;
      top: 0;
      left: 0;
    }
  `}
  ${media.iphone5`
    padding-left: 15px;
  `}

  ${props => props.surveymiddlemobile && `
    @media screen and (max-width: 768px) {
      height: 44px;
      line-height: 1;
    }
  `}

  ${media.mobile`
    font-size: 16px;
  `};
`;

const StyledLinkGuest = styled.a<LinkProps>`
  ${noOutline};
  background: ${$color08};
  color: ${$color01};
  padding: 14px 5px;
  cursor: pointer;
  border: none;
  border-radius: 6px;
  display: block;
  text-align: center;
  font-family: 'Noto Sans JP', sans-serif;
  line-height: normal;

  &:hover {
    color: ${$color01};
  };

  ${media.mobile`
    font-size: 14px;
  `}

  ${props => {
  if (!props.gradient) return;

  return `
      ${props.reverse ? gradient2() : gradient1()};
      color: ${$color01};
    `;
}};

  // Using for button with offset width
  ${props =>
  props.gradientoffset &&
  `
    ${posr()}
    ${size('115%', 'auto')}
    background-color: ${$color08};
    border-radius: 6px 0 0 6px;
    color: ${$color01};
    font-size: 16px;
    font-weight: bold;
    padding-left: 15px;
    text-align: left;
    line-height: 32px;

    .offset-gradient {
      ${gradient4()};
      ${size('100%')};
      ${posa(-1)};
      border-radius: 6px 0 0 6px;
      top: 0;
      left: 0;
    }
  `}

  ${props => props.surveymiddlemobile && `
    @media screen and (max-width: 768px) {
      height: 44px;
      line-height: 1;
    }
  `}

  ${media.mobile`
    font-size: 14px;
  `};
`;

export { Button, StyledLink, StyledLinkGuest };
export default Button;
