import { AxiosResponse } from 'axios';
import appAxios from "src/app/appAxios";
import {StockNavigation} from "../interface/IBookmarkState";

export const BookmarksService = {
  bookmark: async (id: string, navigation: StockNavigation, config: any = {}) => {
    try {
      const url = `/stock/${id}/bookmark`;
      const { data }: AxiosResponse = await appAxios.post(url,{
        navigation
      }, config);
      return data;
    } catch (error) {
      throw error;
    }
  },
  unbookmark: async (id: string, navigation: StockNavigation, config: any = {}) => {
    try {
      const url = `/stock/${id}/unbookmark`;
      const { data }: AxiosResponse = await appAxios.delete(url, {
        ...config,
        data: { navigation }
      });
      return data;
    } catch (error) {
      throw error;
    }
  },
  getBookmarkList: async (params: string, page?: number) => {
    try {
      const url = `/stock/bookmarks`;
      const { data }: AxiosResponse = await appAxios.get(url, {params: { page: page  ? page : undefined,  navigation: params} });
      return data;
    } catch (error) {
      throw error;
    }
  },
};