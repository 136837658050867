import React from 'react';
import {
  ModalAuthStyled,
} from 'src/styled';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { connectModal, InjectedProps, ModalConfig, hide } from 'redux-modal';

interface ModalAuthProps extends InjectedProps  {
  closeModal(): void;
  backToTop(): void;
}

const AuthModal: React.FC<ModalAuthProps>  = props => {
  const handleCloseModal = () => {
    props.closeModal()
  }
  return (
    props.show ?
      <ModalAuthStyled className={`modal ${props.show ? 'is-active' : ''} modal-blocked`}>
        <div className="modal-background"/>
        <div className="modal-content">
          <button
            onClick={handleCloseModal}
            className="modal-close is-large"
            aria-label="close"
          />
          <p>サービス時間外のため、つみたてサービスをご利用いただけません。</p>
        </div>
      </ModalAuthStyled>
    : null
  );
}
   

const modalConfig: ModalConfig = {
  name: 'AUTH_MODAL'
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  closeModal: () => dispatch(hide('AUTH_MODAL')),
});
  

export default connectModal(
  modalConfig
)(connect(null, mapDispatchToProps)(AuthModal));