// import React from "react";
import styled from 'styled-components';
import {
  noOutline,
  flexRow,
  media,
  $color08,
  size,
  $color07,
  $color28,
  posr,
  posa, $color29, $color30
} from 'src/styled/helpers/';
import IconFavorite from 'src/assets/images/img-favorite-both.png';

const Header = styled.div`
  .primary-header {
    ${flexRow()};

    ${media.mobile`
      padding: 15px 20px;
      align-items: center;
    `}

    ${media.tabletUp`
      align-items: flex-end;
      padding-top: 40px;

      .header-logo {
        margin-right: auto;
      }

      .header-menu {
        margin-left: auto;
      }
    `}
  }

  .header-hamburger-mobile {
    ${media.mobile`
      margin-left: auto;

      .icon-hamburger {
        ${size('20px', '16px')};
      }

      button {
        ${noOutline};
        background-color: transparent;
        border: none;
      }
    `}
  }

  .primary-menu {
    ${media.tabletUp`
      display: flex;
      flex-flow: row nowrap;

      li {
        &:not(:first-child) {
          margin-left: 45px;
        }

        &:nth-child(1) a {
          color: ${$color08};
        }

        &:nth-child(2) a {
          color: ${$color30};
        }

        &:nth-child(3) a {
          color: ${$color29};
        }

        &:nth-child(4) a {
          color: ${$color28};
        }
        
        &:nth-child(5) a {
          color: ${$color07};
        }
      }

      a {
        ${posr()};
        color: ${$color08};
        font-size: 14px;
        font-weight: bold;

        &.has-favorite-icon {
          &:after {
            ${posa()}
            ${size('18px', '17px')}
            content: "";
            background-image: url(${IconFavorite});
            background-size: 18px 17px;
            top: -20px;
            right: 3px;
          }
        }
      }
    `}
  }

  .header-logo {
    a {
      display: block;
      color: transparent;
    }

    img {
      border: none;
      display: block;
      width: 128px;

      ${media.mobile`
        width: 92px;
      `}
    }
  }
`;

export default Header;
export { Header };
