// import React from "react";
import styled from 'styled-components';
import { media, $color11 } from 'src/styled/helpers/';

interface Props {
  isMobile?: boolean;
}

const VisualizeBlock = styled.div`
  display: block;
  text-align: left;
  font-size: 13px;

  .select-container {
    max-width: 290px;
    margin-bottom: 20px;
  }
`;

const VisualizeBlockTitle = styled.div<Props>`
  font-size: 16px;
  font-weight: bold;
  margin-bottom: ${props  => (props.isMobile ? '10px' : '30px')};
  text-align: center;
`;

const VisualizeBlockChart = styled.div`
  background-color: rgba(0, 0, 0, 0.1);
  display: block;
  font-size: 13px;
  height: 250px;
  margin-bottom: 20px;
`;

const VisualizeBlockInfo = styled.div`
  display: flex;
  flex-flow: row nowrap;

  &:not(:last-child) {
    margin-bottom: 14px;
  }

  p {
    margin: 0;
  }
`;

const VisualizeBlockInfoLeft = styled.p`
  font-size: 13px;
  flex: 0 0 auto;
  width: 50%;

  ${media.tabletUp`
    font-size: 15px;
  `}

  ${media.iphone5`
    font-size: 12px;
  `}
`;

const VisualizeBlockInfoRight = styled.p<{positive?: boolean,}>`
  font-size: 13px;
  font-weight: bold;
  flex: 0 0 auto;
  text-align: right;
  width: 50%;
  color: ${props => (props.positive ? '#E80C39' : $color11)};

  ${media.tabletUp`
    font-size: 15px;
  `}

  ${media.iphone5`
    font-size: 12px;
  `}
`;

export default VisualizeBlock;
export {
  VisualizeBlock,
  VisualizeBlockChart,
  VisualizeBlockInfo,
  VisualizeBlockInfoLeft,
  VisualizeBlockInfoRight,
  VisualizeBlockTitle
};
