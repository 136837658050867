// import React from "react";
import styled from 'styled-components';
import { PaperLarge } from '../Paper';
import {
  media,
  flexRow,
  $color07,
  $color08,
  $color09,
  $color10,
  SKIN,
  posr,
  posa,
  size,
} from 'src/styled/helpers/';

const BlockSurveyResult = styled(PaperLarge)`
  .panel-center {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    max-width: 480px;
    width: 100%;
  }

  .result-line {
    &-top,
    &-2 {
      line-height: 1;
    }

    &-top {
      font-size: 13px;
      margin-bottom: 44px;
    }

    &-2 {
      font-size: 18px;
      font-weight: bold;
      margin-bottom: 30px;

      ${media.tabletUp`
        margin-bottom: 40px;
      `}
    }

    &-imagebox {
      ${posr()};
      margin: 0 auto 12px;
      transform: translateX(4px);
      width: auto;

      .is-dash {
        ${posa()};
        bottom: 0;

        &.dash-left {
          ${size('27px','33px')};
          left: 0;
        }

        &.dash-right {
          ${size('39px','36px')};
          right: 0;
        }
      }
      .is-main {
        height: 100px;

        ${media.tabletUp`
          height: 100px;
        `}
        ${media.iphone5`
          height: 90px;
        `}
      }
    }

    &-image {
      margin-bottom: 20px;

      ${media.tabletUp`
        margin-bottom: 30px;
      `}

      p {
        color: ${$color07};
        font-size: 19px;
        font-weight: bold;
        line-height: 1;
        margin: 0;

        ${media.tabletUp`
          font-size: 24px;
        `}
      }
    }

    &-description {
      text-align: left;
      display: block;
      margin-bottom: 25px;

      ${media.tabletUp`
        margin-bottom: 45px;
      `}
    }

    &-minicharts {
      ${flexRow()};
      justify-content: space-around;
      height: 112px;
      ${media.iphone5`
        height: 84px;
      `}
    }
  }

  .minichart {
    &-box {
    }

    &-image {
      width: 72px;
      margin-bottom: 5px;
    }

    &-text {
      color: ${$color08};
      font-size: 12px;
      font-weight: bold;
      line-height: 1;

      &.${SKIN.PRIMARY} {
        color: ${$color08};
      }

      &.${SKIN.SECONDARY} {
        color: ${$color09};
      }

      &.${SKIN.THIRD} {
        color: ${$color10};
      }

      ${media.tabletUp`
        font-size: 13px;
      `}

      ${media.iphone5`
        font-size: 10px;
      `}
    }
  }

  .result-actions {
    margin-top: 60px;

    .link-to-start {
      margin-left: 65px;
    }

    .center-align {
      ${flexRow()};
      align-items: center;
    }

    .col-offset-2 {
      padding-left: 50px;
    }

    ${media.mobile`
      margin-top: 20px;

      .link-to-start {
        margin-left: 0;
      }

      .col-offset-2 {
        padding-left: .75rem;
      }

      .center-align {
        padding-bottom: 0;
      }
    `}
  }

  ${media.tabletUp`
  `}
`;

export default BlockSurveyResult;
export { BlockSurveyResult };
