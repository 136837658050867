// import React from "react";
import styled from 'styled-components';
import {
  media,
  $color14
} from 'src/styled/helpers/';

const Breadcrumb = styled.div`
  padding-top: 40px;

  &.breadcrumb {
    a {
      color: ${$color14};
      font-weight: bold;
    }
    .is-active {
      a {
        color: ${$color14};
      }
    }

    li + li::before {
      content: '';
    }
  }

  ${media.mobile`
    padding-top: 15px;
    padding-bottom: 0;

    &.breadcrumb {
      a {
        font-size: 13px;
      }

      li {
        &.is-active {
          margin-left: auto;
          a {
            color: ${$color14};
          }
        }
      }
    }
  `}
`;

export default Breadcrumb;
export { Breadcrumb };
