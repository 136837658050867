import React from 'react';
import {
  XAxis, YAxis, AreaSeries, MarkSeriesPoint, VerticalGridLines,
  HorizontalGridLines, LineSeries, Crosshair, DiscreteColorLegend, FlexibleHeightXYPlot
} from 'react-vis';
import moment from 'moment';
import {formatMoney, formatChartAxisYLabel, getWindowSize} from 'src/common/utils/functionUtil';
import { IDataChart } from 'src/interface/IDataChart';
import { getTickValueYAxis } from "../../../toshin/toshinUtils";
import ChartLabel from './ChartLabel';

interface Props {
  tcode: string;
  dataChart: Array<IDataChart>;
}
interface State {
  crosshairValues: Array<any>;
  hoveredNode: null | MarkSeriesPoint;
  widthChart: number;
  tickRange: number;
  maxAxisY: number;
  minAxisY: number;
  tickRightRange: number;
  maxRightAxisY: number;
  minRightAxisY: number;
  tickValuesY: number[];
  tickValuesRightY: number[];
  tickValuesX: number[];
  maxAxisX: number;
  minAxisX: number;
  isMobile: boolean;
}

const CustomYRightLabel: React.FC<{total: number, type: 'right'| 'left'}> = ({total, type}) => (
  type === 'right' ?
  <text dy="0.32em" transform="translate(5, 0)" fill="#6b6b76" fontSize="8">
    <tspan>
      {formatChartAxisYLabel(total, 8)}
    </tspan>
  </text>
  :
  <text dy="0.32em" dx="-3.32em" transform="translate(-5, 0)" fill="#6b6b76" fontSize="8">
    <tspan>
      {formatMoney(formatChartAxisYLabel(total, 4))}
    </tspan>
  </text>
);

class  HistoricalChart extends React.Component<Props, State> {

  constructor(props) {
    super(props);
    this.state = {
      crosshairValues: [],
      hoveredNode: null,
      widthChart: 900,
      tickRange: 0,
      maxAxisY: 0,
      minAxisY: 0,
      tickRightRange: 0,
      maxRightAxisY: 0,
      minRightAxisY: 0,
      tickValuesY: [0, 0],
      tickValuesRightY: [0, 0],
      tickValuesX: [0, 0],
      minAxisX: 0,
      maxAxisX: 0,
      isMobile: false
    };
  }

   /**
   * Event handler for onMouseLeave.
   * @private
   */
  _onMouseLeave = () => {
    this.setState({crosshairValues: []});
  };

  _onNearestX = (DATA, value, {index}) => {
    this.setState({
      crosshairValues: DATA.map(d => {
        return d[index].y !== null && d[index]})
    })
  };

  getTickValuesX = (data: any, dataMax: any, dataMin: any) => {
    let dateStart = moment(dataMin);
    let dateEnd = moment(dataMax);
    let timeValues: any[] = [];
    let dataDate: any[] = [];

    while (dateEnd > dateStart || dateStart.format('M') === dateEnd.format('M')) {
      timeValues.push(dateStart.format('YYYY/MM'));
      dateStart.add(1,'month');
    }
    timeValues.forEach((month: string) => {
      let dataFilter = data.filter(data => {
        return moment(data.data_date).format('YYYY/MM') === month
      });
      let moments = dataFilter.map(d => moment(d.data_date));
      return dataDate.push(moment.min(moments).valueOf());
    });
    return dataDate;
  };

  getMinMaxXYDomain = (field: 'nav' | 'net_asset' | 'dividend_included_nav') => {
    const { dataChart } = this.props;
    return {
      dataYMin: Math.ceil(Math.min(...dataChart.map(ele => ele[field]))),
      dataYMax: Math.ceil(Math.max(...dataChart.map(ele => ele[field]))),
    };
  };

  getMinMaxXDomain = () => {
    const { dataChart } = this.props;
    let moments = dataChart.map((d: IDataChart) => moment(d.data_date));
    return {
      minAxisX: moment.min(moments).valueOf(),
      maxAxisX: moment.max(moments).valueOf()
    };
  };

  componentDidMount() {

    const { dataChart } = this.props;
    const { dataYMax: dataNavYMax, dataYMin: dataNavYMin } = this.getMinMaxXYDomain('nav');
    const { dataYMax: dataNetYMax, dataYMin: dataNetYMin } = this.getMinMaxXYDomain('net_asset');
    const { ticks: tickValuesY, dataYMinProcessed, dataYMaxProcessed, tickRange } = getTickValueYAxis(dataNavYMin, dataNavYMax);
    const { ticks: tickValuesRightY, dataYMinProcessed: dataYMinProcessedRight, dataYMaxProcessed: dataYMaxProcessedRight, tickRange:  tickRangeRight} = getTickValueYAxis(dataNetYMin, dataNetYMax);

    const maxAxisY = dataYMaxProcessed
    const minAxisY = dataYMinProcessed

    const tickRightRange = tickRangeRight;
    const maxRightAxisY = dataYMaxProcessedRight;
    const minRightAxisY = dataYMinProcessedRight;

    const { minAxisX, maxAxisX } = this.getMinMaxXDomain();
    const tickValuesX: number[] = this.getTickValuesX(dataChart, maxAxisX, minAxisX);

    this.setState({
      tickRange, maxAxisY, minAxisY,
      tickRightRange, maxRightAxisY, minRightAxisY,
      tickValuesY, tickValuesRightY, minAxisX, maxAxisX, tickValuesX,
      crosshairValues: [
        {x: moment(dataChart.slice(-1)[0].data_date).valueOf(), y: dataChart.slice(-1)[0].nav },
        {x: moment(dataChart.slice(-1)[0].data_date).valueOf(), y: dataChart.slice(-1)[0].net_asset },
        {x: moment(dataChart.slice(-1)[0].data_date).valueOf(), y: dataChart.slice(-1)[0].dividend_included_nav },
      ]
    });


    window.addEventListener("resize", this.resize);
    this.resize();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resize);
  }

  resize = () => {
    const tabContent = document.getElementById('historical-chart') || document.getElementsByClassName('tab-content')[1];
    const clientWidth = tabContent && tabContent;
    const {width} = getWindowSize();
    if (!clientWidth) return;

    let currentWidth = clientWidth.clientWidth;
    if (currentWidth !== this.state.widthChart) {
      this.setState({widthChart: currentWidth - (width <= 320 ? 20 : 40)});
    }
    let curentIsMobile = (width < 768);
    if (curentIsMobile !== this.state.isMobile) {
      this.setState({isMobile: curentIsMobile});
    }
  };

  render() {
    const { dataChart } = this.props;
    const {
      crosshairValues,
      maxAxisY, minAxisY,
      maxRightAxisY, minRightAxisY,
      tickValuesY, tickValuesRightY,
      maxAxisX, minAxisX, isMobile, tickValuesX
    } = this.state;

    const processedData = dataChart.map((data: IDataChart) => ({
      x: moment(data.data_date).valueOf(),
      y: data.nav
    }));

    const processedDataAsset = dataChart.map((data: IDataChart) => ({
      x: moment(data.data_date).valueOf(),
      y: data.net_asset
    }));

    const dividendDataNav = dataChart.map((data: IDataChart) => ({
      x: moment(data.data_date).valueOf(),
      y: data.dividend_included_nav
    }));

    const mindomainX = minAxisX;
    const maxdomainX = moment(maxAxisX).add(7, 'day').valueOf();
    if (isNaN(maxAxisY)) {
      return null;
    }

    const {width} = getWindowSize();
    const marginLR = isMobile ? 40 : 100;
    const chartMargin = {top: 20, left: marginLR, right: marginLR, bottom: 70};
    const chartHeight = isMobile ? width <= 320 ? 220 : 250 : 300;
    const tickValuesYSlice = tickValuesY.slice(0, -1)
    const tickValuesRightYSlice = tickValuesRightY.slice(0, -1)
    return (
      <div style={{ position: 'relative' }}>
        <div>
          <FlexibleHeightXYPlot
            onMouseLeave={this._onMouseLeave}
            xDomain={[mindomainX, maxdomainX]}
            yDomain={[minRightAxisY, maxRightAxisY]}
            xType='time' width={this.state.widthChart}
            height={chartHeight} margin={chartMargin}
            className="historical-chart"
          >
            <YAxis
              tickSize={5}
              tickTotal={7}
              style={{strokeWidth: 0.5}}
              tickPadding={0}
              attr="y"
              attrAxis="x"
              orientation="right"
              tickValues={tickValuesRightYSlice}
              tickSizeInner={0}
              tickFormat={(total: number) =>  isMobile ? CustomYRightLabel({total, type: 'right'}) : formatChartAxisYLabel(total, 8) }
            />
            <AreaSeries
                data={processedDataAsset}
                fill={"#5b6799"}
                color={"transparent"}
            />
            <DiscreteColorLegend
              className={"lengends-toshin"}
              orientation="horizontal"
              items={[
                {title: "基準価額", color:"red"},
                {title: "分配金再投資基準価額", color: "#720707"},
                {title: "純資産総額", color: "#5b6799", strokeWidth: 11}
              ]}
              width={isMobile ? 250 : 350}
            />
          </FlexibleHeightXYPlot>
        </div>
        <div style={{ position: 'absolute', top: 0 }}>
          <FlexibleHeightXYPlot yDomain={[minAxisY, maxAxisY]}
            xDomain={[mindomainX, maxdomainX]}
            xType='time' width={this.state.widthChart}
            height={chartHeight} margin={chartMargin}
            className="historical-chart">
            <XAxis
              attr="x"
              attrAxis="y"
              orientation="bottom"
              tickSize={4}
              tickFormat={(v: string, index: number) => {
                return isMobile && index % 2 !== 0 ? '' : moment(v).format("YYYY/MM");
              }}
              tickTotal={6}
            />
            <XAxis
              attr="x"
              attrAxis="y"
              orientation="top"
              tickSize={0}
              hideTicks
            />
            <ChartLabel
              marginBottom={70}
              marginLeft={marginLR}
              marginRight={marginLR}
              marginTop={20}
              innerHeight={chartHeight}
              innerWidth={this.state.widthChart}
              text="基準価額"
              subtext="(円)"
              className="alt-y-label"
              includeMargin={false}
              xPercent={-0.005}
              yPercent={0.04}
              style={{
                color: "#333333",
                textAnchor: 'end'
              }}
              isMobile={isMobile}
            />
            <ChartLabel
              marginBottom={70}
              marginLeft={marginLR}
              marginRight={marginLR}
              marginTop={20}
              innerHeight={chartHeight}
              innerWidth={this.state.widthChart}
              text="純資産総額"
              subtext="(億円)"
              className="alt-y-label"
              includeMargin={false}
              xPercent={0.84}
              yPercent={0.04}
              style={{
                color: "#333333",
                textAnchor: 'end'
              }}
              isMobile={isMobile}
            />
            <YAxis
              tickSize={5}
              tickTotal={7}
              tickPadding={0}
              attr="y"
              attrAxis="x"
              orientation="left"
              tickValues={tickValuesYSlice}
              style={{strokeWidth: 0.5}}
              tickSizeInner={0}
              tickFormat={(total: number) =>  isMobile ? CustomYRightLabel({total, type: 'left'}) : formatMoney(total)}
            />
            <VerticalGridLines
              tickTotal={7}
              tickValues={isMobile ? tickValuesX : undefined}
              style={{
              stroke: "#000",
              strokeWidth: 0.3,
              }}/>
            <HorizontalGridLines tickValues={tickValuesY}  style={{
              stroke: "#000",
              strokeWidth: 0.3,
              }}
            />
            <LineSeries
              data={processedData}
              style={{
                strokeWidth: 1.5,
                stroke: 'red'
              }}
            />
            <LineSeries
              onNearestX={(value, {index}) => this._onNearestX([processedData, processedDataAsset, dividendDataNav], value, {index})}
              data={dividendDataNav}
              style={{
                strokeWidth: 1.5,
                stroke: '#720707'
              }}
            />
            <Crosshair values={crosshairValues} style={{background: "#CCCCCC"}}>
              { crosshairValues.length > 1 &&
                <div className="crosshair-history">
                  <p><strong style={{color: "#FFF"}}>{moment(crosshairValues[0].x).format('YYYY/MM/DD')}</strong></p>
                  <p><strong style={{color: "#FFF"}}>基準価額: {formatMoney(crosshairValues[0].y,)}円</strong></p>
                  <p><strong style={{color: "#FFF"}}>分配金再投資基準価額: {formatMoney(crosshairValues[2].y)}円</strong></p>
                  <p style={{marginTop: 0}}><strong style={{color: "#FFF"}}>純資産総額: {formatChartAxisYLabel(crosshairValues[1].y, 8)}億円</strong></p>
                </div>
              }
            </Crosshair>
          </FlexibleHeightXYPlot>
        </div>
      </div>
    )
  };
}

export default HistoricalChart;