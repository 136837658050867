import styled from 'styled-components';
import { media } from 'src/styled/helpers';

const DatatableResultPage = styled.div`
  .panel-gradient {
    margin-bottom: 5px;

    ${media.tabletUp`
      margin-bottom: 25px;
    `}
  }

  .column-tabledata {
    margin-bottom: 25px;
    ${media.mobile`
      margin-bottom: -35px;  
    `}
  }
  .paper-progress {
    ${media.tabletUp`
      padding: 0 20px 20px;
    `}
    ${media.mobile`
      &.portfolios {
        margin-top: 15px;
      }
    `}
    height: 100%;

    ${media.iphone5`
      padding: 20px 10px;
    `}
    .standard-date {
      ${media.tabletUp`
        padding: 10px 0;
      `}
      padding: 0 0 10px;
      text-align: right;
      font-size: 14px;
    }
  }

  .columns.columns-2col {
    .is-fullheight {
      ${media.tabletUp`
        display: flex;
        flex-flow: column nowrap;

        .tab-content {
          flex: 1 1 0px;
          height: auto;
        }
      `}

      ${media.ie`
        .tab-content {
          flex-basis: auto;
        }
      `}
    }
    ${media.mobile`
      margin-bottom: -15px;
    `}
  }

  .datatable-tabmenu {
    li {
      width: calc(100% / 3);
    }
  }
`;

export default DatatableResultPage;
export { DatatableResultPage };
