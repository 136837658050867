import axios, {AxiosRequestConfig} from 'axios';
import { isGuest } from 'src/common/utils/functionUtil';
const baseHeaders = (config: AxiosRequestConfig) => ({
  'Content-Type': 'application/json',
  ...config.headers
});
// Config Auth Axios
const authAxios = axios.create();
authAxios.defaults.baseURL = process.env.REACT_APP_AUTH_URL;
authAxios.interceptors.request.use(function (config: AxiosRequestConfig) {
  return {
    ...config,
    headers: baseHeaders(config),
  }
});
// Config App Axios
const appAxios = axios.create();
appAxios.defaults.baseURL = process.env.REACT_APP_BASE_URL;
appAxios.interceptors.request.use(function (config: AxiosRequestConfig) {
  const token = localStorage.getItem('token');
  return {
    ...config,
    headers: {
      ...baseHeaders(config),
      Authorization: isGuest() ? '' : token,
    },
  }
});

export { appAxios, authAxios };
export default appAxios;