import React from 'react';
import { Breadcrumb as StyledBreadcrumb } from 'src/styled';

interface Props {
}

const Breadcrumb: React.FC<Props> = ({ children }) => {
  return (
    <StyledBreadcrumb className="breadcrumb" aria-label="breadcrumbs">
      {children}
    </StyledBreadcrumb>
  );
};

export default Breadcrumb;
