// import React from "react";
import styled from 'styled-components';
import { $color08, size } from 'src/styled/helpers/';
import {Link} from "react-router-dom";
import arrowBack from "src/assets/images/left-arrow.png";

const ButtonLink = styled(Link)`
  font-size: 15px;
  color: ${$color08};
  font-weight: bold;
  &:hover {
    color: ${$color08};
  }
  &:before, &:after {
    content: ' ';
    ${size('12px', '14px')};
    display: none;
    background-image: url(${arrowBack});
    background-repeat: no-repeat;
    background-size: 9px;
  };
  &:before {
    margin-right: 5px;
    margin-bottom: -2px;
    display: inline-block;
    transform: rotate(0deg);
  }
`;

export default ButtonLink;
export { ButtonLink };
