// import React from "react";
import styled from 'styled-components';
import { media, $color17 } from 'src/styled/helpers/';

const Footer = styled.div`
  color: ${$color17};
  font-size: 12px;
  padding: 0;

  .footer-row {
    display: flex;
    flex-flow: row nowrap;

    &:not(:last-child) {
      margin-bottom: 6px;
    }
  }

  .footer-disclaimer-1 {
    padding: 10px 15px;
    border: 1px solid #ccc;
    margin-bottom: 20px;
    p {
      padding: 5px 0;
    }
  }


  .footer-disclaimer-2 {
    padding: 10px 15px;
    border: 1px solid #ccc;
    margin-bottom: 20px;
    .title {
      font-size: 14px;
      margin-bottom: 0;
      &.title-head {
        color: ${$color17};
        margin-bottom: 15px;
        font-weight: normal;
      }
    }
    .disclaimer-2-block {
      margin-bottom: 15px;
      &:last-child {
        margin-bottom: 0px;
      }
      margin-bottom: 20px;
      .text-link {
        margin-top: 15px;
        text-decoration: underline;
        color: #3273dc;
        cursor: pointer;
        &:hover {
          color: ${$color17};
        }
      }
      &--1 {
        .mg-15 {
          margin-bottom: 15px;
        }
      }
    }
  }

  .footer-col-left {
    flex: 0 0 auto;
    width: 80px;
  }

  .footer-col-right {
    flex: 1 1 0;
    width: auto;
  }

  ${media.tabletUp`
    padding: 30px 0;

    .footer-row.footer-row-3 {
      padding-top: 24px;
      font-size: 12px;
    }
  `}
  
  ${media.mobile`
    padding: 20px;
    line-height: 16px;
    .footer-row {
      display: block;
      
      &:not(:last-child) {
        margin-bottom: 15px;
      }
    }
    .footer-col-right {
      font-weight: bold;
    }
  `}
`;

export default Footer;
export { Footer };
