import { BOOKMARK_ACTION } from "src/bookmark/bookmarkAction";
import { IBookmarkState } from "src/interface/IBookmarkState";

export const initialState: IBookmarkState = {
  toshin: {
    bookmarks: [],
    hasLoadmore: false,
    params: 0,
    isPending: true,
    isLoadingMore: false

  },
  kabu: {
    bookmarks: [],
    hasLoadmore: false,
    params: 0,
    isPending: true,
    isLoadingMore: false
  }
}
export const bookmarkReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case BOOKMARK_ACTION.GET_LIST_BOOKMARK_PENDING: {
      const { navigation  } = action;
      return {
        ...state,
        [navigation]: {
          ...state[navigation],
          bookmarks: [],
          isPending: true,
          isLoadingMore: true
        }
      }
    }
    case BOOKMARK_ACTION.GET_LIST_BOOKMARK_SUCCESS: {
      const { bookmarks, hasLoadmore, navigation, next_page } = action;
      return {
        ...state,
        [navigation]: {
          ...state[navigation],
          bookmarks: bookmarks,
          isPending: false,
          hasLoadmore,
          params: next_page,
          isLoadingMore: false,
        }
      }
    }
    case BOOKMARK_ACTION.UPDATE_UNBOOKMARK_STOCK_LIST: {
      const { id, navigation } = action;
      const newBookmark = state[navigation].bookmarks.filter(bookmark => navigation === 'toshin' ? bookmark.fcode  !== id : bookmark.stock_code  !== id)
      return {
        ...state,
        [navigation]: {
          ...state[navigation],
          bookmarks: newBookmark
        }
      }
    }
    case BOOKMARK_ACTION.RESET_BOOKMARK_LIST: {
      const { navigation } = action;
      return {
        ...state,
        [navigation]: {
          ...state[navigation],
          bookmarks: [],
          params: 0
        }
      }
    }
    default:
      return state
  }
}