import styled from 'styled-components';
import {
  flexRow,
  media,
  $color07,
  $color09,
  $color22,
  $color26,
  SKIN,
  $color08
} from 'src/styled/helpers/';

const TabContainer = styled.div``;

const TabMenu = styled.div`
  display: block;

  &.tabs {
    ul {
      border-bottom-color: transparent;
    }

    a {
      border-bottom-color: ${$color22};
      border-bottom-width: 3px;
      color: ${$color26};
      font-size: 13px;
      font-weight: bold;
      line-height: 1;
      padding: 20px 70px;
    }

    li.is-active {
      a {
        color: ${$color07};
        border-bottom-color: ${$color07};
      }

      &.skin-${SKIN.PRIMARY} a {
        color: ${$color08};
        border-bottom-color: ${$color08};
      }

      &.skin-${SKIN.SECONDARY} a {
        color: ${$color09};
        border-bottom-color: ${$color09};
      }

      &.skin-${SKIN.THIRD} a {
        color: ${$color07};
        border-bottom-color: ${$color07};
      }
    }

    ${media.mobile`
      &.menu-help {
        overflow: initial;
        overflow-x: initial;
        white-space: initial;
        ul {
          align-items: flex-end;
          li {
            max-width: calc(100% / 3);
            text-align: center;
            a {
              white-space: normal;
              line-height: 18px;
            }
          }
        }
      }
      ul {
        ${flexRow()}
        align-items: flex-end;
      }

      a {
        font-size: 12px;
        padding: 10px 5px;
        white-space: nowrap;
      }
    `}
  }
`;

interface TabContentProps {
  visibleDesktop?: boolean
}

const TabContent = styled.div<TabContentProps>`
  display: none;
  visibility: hidden;

  &.is-active {
    display: block;
    visibility: visible;
  }

  ${props => {
    if (!props.visibleDesktop) return;

    return `@media screen and (min-width: 768px) {
      display: block;
      visibility: visible;
    }`;
  }}
`;

export default TabContainer;
export { TabContainer, TabMenu, TabContent };
