import styled from 'styled-components';
import {
  $color01,
  $color25
} from 'src/styled/helpers/';

interface Props {
  active: boolean;
}

export const DotStyle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
`
export const CircleDot = styled.div<Props>`
  border-radius: 50%;
  width: 10px;
  height: 10px;
  background: ${props => props.active ?  $color01 : $color25};
  margin: 0 3px;
`