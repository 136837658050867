// import React from "react";
import styled from 'styled-components';
import {$color08} from 'src/styled/helpers/';
import { isIE } from "src/common/utils/functionUtil";

const TextGradient = styled.div<{reverse?: boolean}>`
  ${() => {
    if (isIE())
      return `
        color: ${$color08};
        font-weight: bold;
      `;
    else {
      return `
        background: ${$color08}; // Remove Gradient(DLT-325)
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-weight: bold;
      `;
    }
  }}
`;

export default TextGradient;
export { TextGradient };
