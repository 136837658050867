import React from 'react';
import {
  FavoriteBrandsPage as StyledFavoriteBrandsPage,
  SKIN,
  ROW_TYPE,
  MaxContainer,
  RowMini,
  RowWrapper,
  PaperHeadingResult,
} from 'src/styled';
import Breadcrumb from 'src/components/Breadcrumb';
import TableRow from 'src/components/TableRow';
import TableContainer from 'src/components/TableContainer';
import {
  TabContainer,
  TabMenu,
  TabItem,
  TabContent
} from 'src/components/TabContainer';
import imgCloseModal from 'src/assets/images/img-close-gradient.png';
import { Dispatch } from 'redux';
import { getBookmark, unbookmarkStock, updateBookmarkStockList, resetBookmarkList } from './bookmarkAction';
import { connect } from 'react-redux';
import { INVESMENT_TYPES } from "src/common/utils/appConstant";
import { IAppState } from 'src/interface/IAppState';
import { IBookMarkList, StockNavigation } from 'src/interface/IBookmarkState';
import { connectModal, InjectedProps, ModalConfig, hide } from 'redux-modal';
import { IResStockState } from 'src/interface/IToshinState';
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import { formatYYMMDDJapan } from 'src/common/utils/functionUtil';
import { DisclaimerRender, ToshinDisclaimerRender } from 'src/components/Footer';

interface BookmarkProps extends InjectedProps  {
  getBookmark(params: string, isSecondLoad: boolean): void;
  toshinBookmarks: IBookMarkList;
  kabuBookmarks: IBookMarkList;
  closeModal(): void;
  unbookmarkStock(id: string, navigation: StockNavigation): void;
  updateBookmarkStockList(id: string, navigation: StockNavigation): void;
  resetBookmarkList(navigation: StockNavigation): void;
}

interface BookmarkState {
  tabValue: string;
}


class BookmarkContainer extends  React.Component<BookmarkProps, BookmarkState> {

  constructor(props: BookmarkProps) {
    super(props);
    this.state = {
      tabValue: '1'
    }
  }

  setTabValue = (tabValue: string) => {
    this.setState({
      tabValue: tabValue
    })
  };

  componentDidMount() {
    const { getBookmark } = this.props;
    const elHeight = document.getElementById('bookmarks-tab');
    if (elHeight) {
      elHeight.addEventListener('scroll', function(){
      })
    }
    const guest = localStorage && localStorage.getItem("token")
    if (guest !== 'guest') {
      getBookmark(INVESMENT_TYPES.kabu, true);
      this.scrollStop(this.onScrollHandler)
    }
    if(this.props.show) {
      document.body.style.overflow = "hidden";
      document.documentElement.style.overflow = "hidden"
    }
  }

  componentDidUpdate() {
    if(this.props.show) {
      disableBodyScroll(document.getElementById('bookmarks-tab'))
      document.documentElement.style.overflow = "hidden"
    }
  }

  componentWillUnmount() {
    document.documentElement.style.removeProperty("overflow");
    this.props.resetBookmarkList('toshin')
    this.props.resetBookmarkList('kabu')
    clearAllBodyScrollLocks();
  }

  scrollStop = (callback: Function) => {
    let isScrolling: any
    const elHeight = document.getElementById('bookmarks-tab');
    if (elHeight) {
      elHeight.onscroll = () => {
        window.clearTimeout(isScrolling)
        isScrolling = setTimeout(() => {
          callback()
        }, 66)
      }
    }
  }


  onScrollHandler = () => {
    const type = this.state.tabValue === '1' ? 'kabu' : 'toshin';
    const elHeight = document.getElementById('bookmarks-tab');
    const { isLoadingMore, hasLoadmore, isPending } = this.props[`${type}Bookmarks`];
    if (!hasLoadmore || isLoadingMore || isPending) return;
    if ( elHeight && (elHeight && elHeight.clientHeight + elHeight.scrollTop) >= (elHeight.scrollHeight - 20)) {
      this.props.getBookmark(type, false)
    }
  };

  handleonClickBookmarkIcon = (id: string, navigation: StockNavigation) => {
    this.props.updateBookmarkStockList(id, navigation);
    this.props.unbookmarkStock(id, navigation);
  }

  render() {
    const { tabValue } = this.state;
    const { toshinBookmarks, kabuBookmarks, show, closeModal } = this.props;
    return (
      show &&
        <StyledFavoriteBrandsPage className={`modal ${show ? 'is-active' : ''}`}>
          <div className="modal-background"/>
          <div id="bookmarks-tab"  className="modal-content">

            <MaxContainer mobileEdgePadding={20} className="max-container">
              <div className="container container-favorite-brand">
                <button className="close-modal" onClick={() => closeModal()}>
                  <img src={imgCloseModal} alt="Close Modal" />
                </button>
                <div className="columns">
                  <div className="column is-12">
                    <Breadcrumb>
                      <ul>
                        <li>
                          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                          <a>お気に入り銘柄</a>
                        </li>
                      </ul>
                    </Breadcrumb>
                  </div>
                </div>
                <div className="columns">
                  <div className="column is-12 column-tabledata">
                    <TabContainer>
                      <TabMenu>
                        <TabItem
                          className={`skin-${SKIN.PRIMARY} ${tabValue === '1' &&
                            'is-active'}`}
                          onClick={() => this.setTabValue('1')}
                        >
                          株式
                        </TabItem>
                        <TabItem
                          className={`skin-${SKIN.THIRD} ${tabValue === '2' &&
                            'is-active'}`}
                          onClick={() => this.setTabValue('2')}
                        >
                          投資信託
                        </TabItem>
                      </TabMenu>
                      { tabValue === '1' &&
                        <PaperHeadingResult>
                          <p className="title-heading"></p>
                          { kabuBookmarks.bookmarks.length > 0  && <p>{`株価: ${kabuBookmarks.bookmarks[0].standard_date ? formatYYMMDDJapan(kabuBookmarks.bookmarks[0].standard_date  ) : '-'} 時点`}</p>}
                        </PaperHeadingResult>
                      }
                      <TabContent className={`${tabValue === '1' && 'is-active'}`}>
                        <TableContainer
                          className={`skin-${SKIN.PRIMARY} ${ROW_TYPE.ONE}`}
                        >
                          {
                            kabuBookmarks.bookmarks.length > 0 ? kabuBookmarks.bookmarks.map((stock: IResStockState) => (
                              <TableRow
                                typeRow="1" favorite={stock.is_bookmark}
                                stock={stock} key={stock.id} navigation="kabu"
                                handleonClickBookmarkIcon={() => this.handleonClickBookmarkIcon(stock.stock_code, 'kabu')}
                              />
                            )) :
                            <RowWrapper>
                              <RowMini className="minirow-1">
                                <div className="bookmark-emty">お気に入り銘柄はありません。</div>
                              </RowMini>
                            </RowWrapper>
                          }
                        </TableContainer>
                      </TabContent>
                      <TabContent className={`${tabValue === '2' && 'is-active'}`}>
                        <TableContainer
                          className={`skin-${SKIN.THIRD} ${ROW_TYPE.TWO}`}
                        >
                          {
                            toshinBookmarks.bookmarks.length > 0 ? toshinBookmarks.bookmarks.map((stock: IResStockState) => (
                              <TableRow
                                typeRow="3" favorite={stock.is_bookmark}
                                stock={stock} key={stock.id} navigation="toshin"
                                handleonClickBookmarkIcon={() => this.handleonClickBookmarkIcon(stock.fcode, 'toshin')}
                              />
                            )) :
                            <RowWrapper>
                              <RowMini className="minirow-1">
                                <div className="bookmark-emty">お気に入り銘柄はありません。</div>
                              </RowMini>
                            </RowWrapper>
                          }
                        </TableContainer>
                      </TabContent>
                    </TabContainer>
                  </div>
                </div>
                <div className="disclaimer">
                  {tabValue === '1' ? <DisclaimerRender/> : <ToshinDisclaimerRender/>}
                </div>
              </div>
            </MaxContainer>
          </div>
        </StyledFavoriteBrandsPage>
    );
  }
}


const modalConfig: ModalConfig = {
  name: 'BOOMARK_MODAL'
};

const mapStateToProps = (state: IAppState) => ({
  toshinBookmarks: state.bookmarkState.toshin,
  kabuBookmarks: state.bookmarkState.kabu,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  closeModal: () => dispatch(hide('BOOMARK_MODAL')),
  getBookmark: (params: any, isSecondLoad: boolean) => dispatch(getBookmark(params, isSecondLoad)),
  unbookmarkStock: (id: string, navigation: StockNavigation) => dispatch(unbookmarkStock(id, navigation)),
  updateBookmarkStockList:(id: string, navigation: StockNavigation) => dispatch(updateBookmarkStockList(id, navigation)),
  resetBookmarkList: (navigation: StockNavigation) => dispatch(resetBookmarkList(navigation))
});

export default connectModal(
  modalConfig
)(connect(mapStateToProps, mapDispatchToProps)(BookmarkContainer));