import styled from 'styled-components';
import { getThemeColor, $color08 } from 'src/styled/helpers/';

const PaperHeading = styled.div`
  color: ${props => getThemeColor(props.theme, $color08)};
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 25px;
  margin-left: 20px;
  line-height: 1;
`;

const PaperHeadingResult = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 13px;
  padding: 10px 10px 10px 0;
  .title-heading {
    color: ${props => getThemeColor(props.theme, $color08)};
    font-size: 16px;
    font-weight: bold;
    margin-left: 20px;
    line-height: 1;
  }
`;
export { PaperHeading, PaperHeadingResult };

export default PaperHeading;
