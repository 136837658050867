// import React from "react";
import styled from 'styled-components';
import {
  media,
  $color11,
  $color05,
  posa,
  posr,
  getThemeColor,
  size,
} from 'src/styled/helpers/';

const BlockStyle2 = styled.div`
  text-align: left;
  font-size: 12px;

  &:not(:last-child) {
    margin-bottom: 45px;
  }

  &.is-row-1 {}

  &.is-row-2 {}

  &.is-row-3 {}

  h4 {
    font-size: 16px;
    font-weight: bold;
    line-height: 1;
    margin: 0;
    color: ${props => getThemeColor(props.theme, $color11)};
    padding-right: 36px;
  }

  .block-heading {
    ${posr()};
    border-bottom: 1px solid ${$color05};
    padding-bottom: 8px;
    padding-top: 8px;
    margin-bottom: 8px;


    .icon {
      ${posa()};
      ${size('36px')};
      right: 0;
      top: 50%;
      padding-bottom: 5px;
      transform: translateY(-50%);

      &.icon-1 {
        ${size('28px', '43px')};
        right: 1px;
      }

      &.icon-2 {
        ${size('26px','35px')};
      }

      &.icon-3 {
        ${size('32px', '37px')};
      }
    }
  }

  .block-content {
    p {
      line-height: 18px;
      margin-top: 0;

      &:not(:last-child) {
        margin-bottom: 10px;
      }
    }
  }

  .text-color {
    color: ${props => getThemeColor(props.theme, $color11)};
    font-weight: bold;
  }

  ${media.tabletUp`
    font-size: 14px;

    h4 {
      font-size: 24px;
    }

    .block-heading {
      padding-bottom: 15px;
      padding-top: 15px;
      margin-bottom: 15px;
    }
  `}
`;

export default BlockStyle2;
export { BlockStyle2 };
