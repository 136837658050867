import React from 'react';
import {
  MobileMenu as StyledMobileMenu,
  IconNav1, IconNav2,
  IconNav3, IconNav4, IconNav5
} from 'src/styled';
import MobileLogo from 'src/assets/images/img-logo-mobile.png';
import { show } from 'redux-modal';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import {Link} from "react-router-dom";
import { isGuest } from "src/common/utils/functionUtil";


interface Props {
  onCloseMenu(): void,
  className?: string
  link?: string,
  text?: string
  showBookmark(): void
}
const MobileMenu: React.FC<Props> = ({ className, onCloseMenu, showBookmark }) => {
  const newWindowHelp = (event:any) =>{
    event.preventDefault(); 
    window.open("/help","mywindow","width=1000,fullscreen=yes");
  }
  return (
    <StyledMobileMenu className={className}>
      <div className="modal-background"/>
      <div className="modal-content">
        <button
          onClick={() => onCloseMenu()}
          className="modal-close is-large"
          aria-label="close"
        />
        <div className="mobile-menu-content">
          <div className="mobile-logo">
            <Link to="/" onClick={() => onCloseMenu()}>
              <img src={MobileLogo} alt="" />
            </Link>
          </div>
          <div className="mobile-nav">
            <ul>
              <li>
                <Link to="/products" onClick={() => onCloseMenu()}>
                  <span>運用商品を探す</span>
                  <IconNav1 />
                </Link>
              </li>
              <li>
                <Link to="/kabu" onClick={() => onCloseMenu()}>
                  <span>株式を選ぶ</span>
                  <IconNav3 />
                </Link>
              </li>
              <li>
                <Link to="/toshin" onClick={() => onCloseMenu()}>
                  <span>投資信託を選ぶ</span>
                  <IconNav2 />
                </Link>
              </li>
              { !isGuest() ?
                  <li>
                   {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                   <a onClick={() =>{ showBookmark(); onCloseMenu() }}>
                     <span>お気に入り</span>
                     <IconNav4 />
                   </a>
                 </li>
                :
                <>  
                  <li>
                    <a target="_blank" rel="noopener noreferrer"  href='https://www.daiwa.jp/open/'>口座開設</a>
                    </li>
                    <li>
                      <a target="_blank" rel="noopener noreferrer"  href='https://www.daiwa.jp/trade.html'>ログイン</a>
                    </li>
                </>
                }
              <li>
                <Link to="/help" onClick={(event) =>{ newWindowHelp(event); onCloseMenu() }}>
                  <span>ヘルプ</span>
                  <IconNav5 />
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </StyledMobileMenu>
  );
};


const mapDispatchToProps = (dispatch: Dispatch) => ({
  showBookmark: () => dispatch(show('BOOMARK_MODAL')),
});

export default (connect(null, mapDispatchToProps)(MobileMenu));
