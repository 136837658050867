import React from 'react';
interface Props {}
class SkeletonTopCommon extends React.Component<Props> {

  render() {
    const height = window.outerHeight;
    return (
      <div className="container">
        <div className="is-12" style={{ height: height}}>
        </div>
      </div>
    )
  }
};

export default SkeletonTopCommon;