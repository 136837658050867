// import { IHistoryResult } from "src/interface/IHistoryResult";

export const actionTypes = {
  GET_RESULT_HISTORY: 'GET_RESULT_HISTORY',
  GET_RESULT_HISTORY_SUCCESS: 'GET_RESULT_HISTORY_SUCCESS',
  GET_RESULT_HISTORY_PENDING: 'GET_RESULT_HISTORY_PENDING',
  GET_RESULT_PRODUCT_LIST: 'GET_RESULT_PRODUCT_LIST',
  GET_RESULT_PRODUCT_LIST_PENDING: 'GET_RESULT_PRODUCT_LIST_PENDING',
  GET_RESULT_PRODUCT_LIST_SUCCESS: 'GET_RESULT_PRODUCT_LIST_SUCCESS'
}

export const getResultHistory = (product_id: string) => ({
  type: actionTypes.GET_RESULT_HISTORY,
  product_id
})

export const getResultHistoryPending = () => ({
  type: actionTypes.GET_RESULT_HISTORY_PENDING
})


export const getResultHistorySuccess = (data: any) => ({
    type: actionTypes.GET_RESULT_HISTORY_SUCCESS,
    data
  }
)

export const getResultProductListPending = () => ({
  type: actionTypes.GET_RESULT_PRODUCT_LIST_PENDING
})

export const getResultProductList = () => ({
  type: actionTypes.GET_RESULT_PRODUCT_LIST
})

export const getResultProductListSuccess = (data: any) => {
  return {
    type: actionTypes.GET_RESULT_PRODUCT_LIST_SUCCESS,
    data
  }
}
