import React from 'react';
import {
  TabContainer as StyledTabContainer,
  TabMenu as StyledTabMenu,
  TabContent as StyledTabContent
} from 'src/styled';

interface Props {
  className?: string;
  visibleDesktop?: boolean;
  onClick?(): void;
  id?: string
}

const TabMenu: React.FC<Props> = ({ children, className }) => {
  return (
    <StyledTabMenu className={`tabs is-centered ${className}`}>
      <ul>{children}</ul>
    </StyledTabMenu>
  );
};

const TabItem: React.FC<Props> = ({ children, className, ...props }) => {
  return (
    <li {...props} className={className}>
      {/*eslint-disable-next-line*/}
      <a>{children}</a>
    </li>
  );
};

const TabContent: React.FC<Props> = ({ children, className, id, ...props }) => {
  const tabClass = `tab-content ${className ? className : ''}`;
  return (
    <StyledTabContent id={id} className={tabClass} {...props}>
      {children}
    </StyledTabContent>
  );
};

const TabContainer: React.FC<Props> = ({ children, className }) => {
  return (
    <StyledTabContainer className={className}>{children}</StyledTabContainer>
  );
};

export default TabContainer;
export { TabContainer, TabMenu, TabItem, TabContent };
