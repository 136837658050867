import { AxiosResponse } from 'axios';
import { appAxios } from "src/app/appAxios";

export const TopService = {
  getTopResult: async () => {
    try {
      const { data }: AxiosResponse = await appAxios.get('/robo/results');
      return data;
    } catch (error) {
      throw error;
    }
  }
};