import { AxiosResponse } from 'axios';
import { appAxios } from "src/app/appAxios";

export const KabuService = {
  getKabuResult: async (result_id: string) => {
    try {
      const { data }: AxiosResponse = await appAxios.get(`/robo/kabu/results/${result_id}`);
      return data;
    } catch (error) {
      throw error;
    }
  },
  getResultKabuList: async () => {
    try {
      const { data }: AxiosResponse = await appAxios.get(`/robo/kabu/results?limit=3`);
      return data;
    } catch (error) {
      throw error;
    }
  }
};