import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import { History } from 'history'
import { productReducer } from 'src/product/productReducer';
import { commonReducer } from 'src/common/commonReducer';
import { questionReducer } from 'src/answer_question/question/QuestionReducer';
import { answerReducer } from 'src/answer_question/answer/AnswerReducer';
import {toshinReducer} from "../toshin/toshinReducer";
import {kabuReducer} from "../kabu/kabuReducer";
import { bookmarkReducer } from 'src/bookmark/bookmarkReducer';
import { reducer as modal} from 'redux-modal';


const appReducer = (history: History)  => combineReducers({
  router: connectRouter(history),
  productState: productReducer,
  commonState: commonReducer,
  questionState: questionReducer,
  answerState: answerReducer,
  toshinState: toshinReducer,
  kabuState: kabuReducer,
  bookmarkState: bookmarkReducer,
  modal
});

export default appReducer;