import React from 'react';
import logo from 'src/assets/images/img-logo-primary.png';
import menuMobile from 'src/assets/images/menu-hamburger.png';
import { MobileHidden, MobileVisible, Header as StyledHeader } from 'src/styled';
import MobileMenu from "../MobileMenu";
import { Link } from "react-router-dom";
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { show } from 'redux-modal';
import { disableBodyScroll } from 'body-scroll-lock';
import { isGuest } from "src/common/utils/functionUtil";

interface Props {
  showBookmark(): void;
}

interface State {
  isShowingMobileMenu: boolean
}

class Header extends React.Component<Props, State> {

  constructor(props: Props) {
    super(props)
    this.state = {
      isShowingMobileMenu: false
    }
  }

  componentDidMount() {
    this.forceUpdate();
  }

  toggleShowingMobileMenu = () => {
    this.setState(prevState => ({
      isShowingMobileMenu: !prevState.isShowingMobileMenu
    }));

    const bd = document.querySelector('body');

    if (bd instanceof HTMLElement) {
      const bodyPreventScrollingCssClass = 'prevent-scrolling';

      if (!bd.classList.contains(bodyPreventScrollingCssClass)) {
        bd.classList.add(bodyPreventScrollingCssClass)
      } else bd.classList.remove(bodyPreventScrollingCssClass);
    }
  };

  handleShowBookmark = () => {
    disableBodyScroll()
    document.documentElement.style.overflow = "hidden"
    this.props.showBookmark()
  }
  newWindowHelp = (event: any) => {
    const width = window.outerWidth - 150;
    const height = window.outerHeight - 150;
    event.preventDefault();
    window.open("/help/product", "mywindow", `scrollbars=yes,width=${width},height=${height},resizable=1`);
  }
  render() {
    return (
      <StyledHeader className="max-header max-container">
        <header className="primary-header container">
          <div className="header-logo">
            <Link to={'/'} title="つみたて">
              <img src={logo} alt="つみたて" />
            </Link>
          </div>
          <div className="header-hamburger-mobile">
            <MobileVisible>
              <button onClick={this.toggleShowingMobileMenu}>
                <img
                  className="icon-hamburger"
                  src={menuMobile}
                  alt="menuMobile trigger"
                />
              </button>
            </MobileVisible>
          </div>
          <MobileHidden>
            <div className="header-menu">
              <nav className="desktop-menu">
                <ul className="primary-menu">
                  <li>
                    <Link to="/products">運用商品を探す</Link>
                  </li>
                  <li>
                    <Link to="/kabu">株式を選ぶ</Link>
                  </li>
                  <li>
                    <Link to="/toshin">投資信託を選ぶ</Link>
                  </li>
                  {!isGuest() ?
                    <li>
                      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                      <a title="お気に入り" onClick={this.handleShowBookmark}>
                        お気に入り
                      </a>
                    </li>
                    :
                    <>
                      <li>
                        <a target="_blank" rel="noopener noreferrer" href='https://www.daiwa.jp/open/'>口座開設</a>
                      </li>
                      <li>
                        <a target="_blank" rel="noopener noreferrer" href='https://www.daiwa.jp/trade.html'>ログイン</a>
                      </li>
                    </>
                  }

                  <li>
                    <Link to="/help/product"
                      onClick={(event) => this.newWindowHelp(event)}>ヘルプ
                    </Link>
                  </li>
                </ul>
              </nav>
            </div>
          </MobileHidden>
          <MobileMenu
            onCloseMenu={this.toggleShowingMobileMenu}
            className={`modal mobile-menu-wrapper ${
              this.state.isShowingMobileMenu ? 'is-active' : ''
              }`}
          />
        </header>
      </StyledHeader>
    );
  }

};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  showBookmark: () => dispatch(show('BOOMARK_MODAL')),
});

export default (connect(null, mapDispatchToProps)(Header));