import { createStore, applyMiddleware, compose } from 'redux';
import { createBrowserHistory } from 'history';
import { routerMiddleware } from 'connected-react-router';
import createSagaMiddleware from 'redux-saga';
import appReducer from './appReducer';
import appSaga from './appSaga';
import { AxiosError, AxiosResponse } from 'axios';
import appAxios from './appAxios';

export const history = createBrowserHistory();
history.listen((location, action) => {
  if (action === 'PUSH') {
    window.scrollTo(0, 0)
  }
});

const createAxiosMiddleware = (axios: any) => ({ dispatch }: any) => {
  axios.interceptors.response.use(
    function (response: AxiosResponse) {
      return response;
    },
    function (error: AxiosError) {
      if(error && error.response && error.response.status) {
        const { status } = error.response;
        if (status === 401) {
          localStorage && localStorage.setItem('token', 'guest');
          window.location.href = window.location.pathname
        }
      }
      return Promise.reject(error);
    }
  );

  return (next: any) => (action: any) => next(action);
}

// Build app middleware
const sagaMiddleware = createSagaMiddleware();
const axiosMiddleware =  createAxiosMiddleware(appAxios);

const bindMiddleware = (middleware: any) => {
  if (process.env.NODE_ENV !== 'production') {
    const { composeWithDevTools } = require('redux-devtools-extension');
    return composeWithDevTools(applyMiddleware(...middleware));
  }
  return applyMiddleware(...middleware);
};

const reduxCompose = compose(
  bindMiddleware([sagaMiddleware, axiosMiddleware, routerMiddleware(history)])
);

// Build app store
const appStore = createStore(
  appReducer(history),
  reduxCompose
);



// Run saga middleware
sagaMiddleware.run(appSaga);

export default appStore;