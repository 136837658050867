export const actionTypes = {
  GET_RESULT_KABU_LIST: 'GET_RESULT_KABU_LIST',
  GET_RESULT_KABU_LIST_PENDING: 'GET_RESULT_KABU_LIST_PENDING',
  GET_RESULT_KABU_LIST_SUCCESS: 'GET_RESULT_KABU_LIST_SUCCESS',
  GET_KABU_RESULT: 'GET_KABU_RESULT',
  GET_KABU_RESULT_PENDING: 'GET_KABU_RESULT_PENDING',
  GET_KABU_RESULT_SUCCESS: 'GET_KABU_RESULT_SUCCESS',
  UPDATE_KABU_STOCK: 'UPDATE_KABU_STOCK'
};

export const getResultKabuList = () => ({
  type: actionTypes.GET_RESULT_KABU_LIST
});

export const getResultKabuListPending = () => ({
  type: actionTypes.GET_RESULT_KABU_LIST_PENDING
});

export const getResultKabuListSuccess = (data: any) => ({
  type: actionTypes.GET_RESULT_KABU_LIST_SUCCESS,
  data
});

export const getKabuResult = (id: string) => ({
  type: actionTypes.GET_KABU_RESULT,
  id
})

export const getKabuResultPending = () => ({
  type: actionTypes.GET_KABU_RESULT_PENDING,
})


export const getKabuResultSuccess = (data: any) => ({
  type: actionTypes.GET_KABU_RESULT_SUCCESS,
  data
});

export const updateKabuStock = (idx: number, isBookmark: boolean) => ({
  type: actionTypes.UPDATE_KABU_STOCK,
  idx, 
  isBookmark
})
