// import React from "react";
import styled from 'styled-components';
import {
  noOutline,
  $color01,
  $color11,
  gradient2,
  gradient1,
  posr,
  posa,
  gradient3,
} from 'src/styled/helpers/';

interface Props {
  reverse?: boolean,
  active?: boolean
}

const Select = styled.div<Props>`
  ${posr()}

  .icon-separator,
  .icon-triangle-white {
    ${posa()};
    top: 50%;
    transform: translateY(-50%);
  }

  .icon-separator {
    background-color: rgba(255, 255, 255, 0.7);
    height: 65%;
    right: 33px;
    width: 1px;
  }

  .icon-triangle-white {
    right: 8px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 8px 8px 0 8px;
    border-color: ${$color01} transparent transparent transparent;
  }

  select {
    ${props => (props.active? gradient3() : props.reverse ? gradient2() : gradient1())}
    ${noOutline};
    ${posr()};
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: 1px solid transparent;
    border-radius: 6px;
    color: ${$color01};
    font-size: 13px;
    font-weight: bold;
    height: 48px;
    padding-left: 18px;
    padding-right: 35px;
    width: 100%;

    ${props => `
      @media screen and (max-width: 768px) {
        ${props.active ? gradient3() : props.reverse ? gradient1() : gradient2()};
        font-size: 13px;
      }
    `}
    &:hover,
    &:focus {
      font-size: 13px;
    }

    option {
      color: ${$color11};
    }

    &::-ms-expand {
      display: none;
    }
  }
`;

export default Select;
export { Select };
