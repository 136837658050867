import { actionTypes } from "src/toshin/toshinAction";
import { IToshinState } from "src/interface/IToshinState";

const initialState: IToshinState = {
  listToshinResult: {
    isPending: false,
    results: []
  },
  toshinResult: {
    id: '',
    user_id: '',
    portfolios: [],
    stocks: [],
    simulation: {
      simulation_type: '',
      annual_rate: 0,
      total_accumulated_amount: 0,
      total_profit: 0,
      yearly_accumulated: [],
      monthly_accumulated: []
    },
    summary: {
      investment_style: '',
    },
    category: '',
    answers: {},
    created_at: '',
    isPending: true,
  },
  dataChart: {}
};

export function toshinReducer(state = initialState, action: any) {
  switch (action.type) {
    case actionTypes.GET_RESULT_TOSHIN_LIST_PENDING: {
      return {
        ...state,
        listToshinResult: {
          ...state.listToshinResult,
          results: [],
          isPending: true
        }
      }
    }
    case actionTypes.GET_RESULT_TOSHIN_LIST_SUCCESS: {
      return {
        ...state,
        listToshinResult: {
          ...state.listToshinResult,
          results: action.data,
          isPending: false
        }
      }
    }
    case actionTypes.GET_RESULT_TOSHIN_PENDING: {
      return {
        ...state,
        toshinResult: {
          isPending: true
        },
        dataChart: {}
      }
    }
    case actionTypes.GET_RESULT_TOSHIN_SUCCESS: {
      return {
        ...state,
        toshinResult: {
          ...action.data,
          simulation: {
            ...action.data.simulation,
            yearly_accumulated: "yearly_accumulated" in action.data.simulation ? action.data.simulation.yearly_accumulated : [],
            monthly_accumulated: "monthly_accumulated" in action.data.simulation ? action.data.simulation.monthly_accumulated : []
          },
          isPending: false
        }
      }
    }
    case actionTypes.UPDATE_TOSHIN_STOCK: {
      let newStocks = [...state.toshinResult.stocks];
      newStocks[action.idx] = {
        ...newStocks[action.idx],
        is_bookmark: action.isBookmark
      };

      return {
        ...state,
        toshinResult: {
          ...state.toshinResult,
          stocks: newStocks
        }
      }
    }
    case actionTypes.GET_TOSHIN_CHART_BY_STOCK_SUCCESS: {
      const { data, stock_id} = action
      return {
        ...state,
        dataChart: {
          ...state.dataChart,
          [stock_id]: data.reverse()
        }
      }
    }
    default:
      return state;
  }
}