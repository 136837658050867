export const actionTypes = {
  GET_RESULT_TOSHIN_LIST: 'GET_RESULT_TOSHIN_LIST',
  GET_RESULT_TOSHIN_LIST_PENDING: 'GET_RESULT_TOSHIN_LIST_PENDING',
  GET_RESULT_TOSHIN_LIST_SUCCESS: 'GET_RESULT_TOSHIN_LIST_SUCCESS',
  GET_RESULT_TOSHIN: 'GET_RESULT_TOSHIN',
  GET_RESULT_TOSHIN_PENDING: 'GET_RESULT_TOSHIN_PENDING',
  GET_RESULT_TOSHIN_SUCCESS: 'GET_RESULT_TOSHIN_SUCCESS',
  UPDATE_TOSHIN_STOCK: 'UPDATE_TOSHIN_STOCK',
  GET_TOSHIN_CHART_BY_STOCK: 'GET_TOSHIN_CHART_BY_STOCK',
  GET_TOSHIN_CHART_BY_STOCK_SUCCESS: 'GET_TOSHIN_CHART_BY_STOCK_SUCCESS'
};

export const getResultToshinList = () => ({
  type: actionTypes.GET_RESULT_TOSHIN_LIST
});

export const getResultToshinListPending = () => ({
  type: actionTypes.GET_RESULT_TOSHIN_LIST_PENDING,
});

export const getResultToshinListSuccess = (data: any) => ({
  type: actionTypes.GET_RESULT_TOSHIN_LIST_SUCCESS,
  data
});

export const getResultToshin = (id: string) => ({
  type: actionTypes.GET_RESULT_TOSHIN,
  id
});

export const getResultToshinPending = () => ({
  type: actionTypes.GET_RESULT_TOSHIN_PENDING,
});

export const getResultToshinSuccess = (data: any) => ({
  type: actionTypes.GET_RESULT_TOSHIN_SUCCESS,
  data
});

export const updateToshinStock = (idx: number, isBookmark: boolean) => ({
  type: actionTypes.UPDATE_TOSHIN_STOCK,
  idx, 
  isBookmark
})

export const getToshinChartByStock = (stock_id: string) => ({
  type: actionTypes.GET_TOSHIN_CHART_BY_STOCK,
  stock_id
});

export const getToshinChartByStockSuccess = (data: any, stock_id) => {
  return {
    type: actionTypes.GET_TOSHIN_CHART_BY_STOCK_SUCCESS,
    data,
    stock_id
  }
};