import styled from 'styled-components';
import { media } from 'src/styled/helpers/';

const SubTextButton = styled.div`
  font-size: 12px;
  padding: 10px 10px 0;
  text-align: justify;
  ${media.mobile_480`
    width: 110%;
  `}
  ${media.iphone5`
    width: 115%;
  `}
`;

export default SubTextButton;
export { SubTextButton };
