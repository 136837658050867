// import React from "react";
import styled from 'styled-components';
import {
  size,
  $color01,
  posa,
  // media,
  flexRow,
  noOutline,
  $color08,
  $color13
} from 'src/styled/helpers/';

const SurveyQuestionPage = styled.div`
  .surveypage-offset-container {
    &:not(:last-child) {
      margin-bottom: 52px;
    }

    &:last-child {
      margin-bottom: 12px;
    }
  }

  .question-back-edge {
    ${posa()};
    ${size('80px', '100%')};
    color: ${$color08};
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left: -100%;
    background: ${$color01};
    border-radius: 0 18px 18px 0;

    button {
      ${noOutline};
      align-items: center;
      background-color: transparent;
      border: none;
      color: ${$color08};
      cursor: pointer;
      display: flex;
      flex-flow: column nowrap;
      font-size: 12px;
      font-weight: bold;
      justify-content: center;
      margin: 0;
      padding: 20px 0;
      width: 100%;

      span {
        display: block;
        width: 12px;
      }
    }

    img {
      ${size('12px', '17px')};
      display: block;
      margin-bottom: 6px;
    }
  }

  .question-container {
    ${flexRow()};
    background-color: ${$color01};
    border-radius: 18px 0 0 18px;
    padding: 90px 60px;
    flex: 0 0 auto;
    width: 100%;
  }

  .question {
    &-col-left,
    &-col-right {
      flex: 0 0 auto;
      width: 50%;
    }

    &-col-left {
      &.is-fullheight {
        display: flex;
        flex-flow: column nowrap;
      }
    }

    &-col-right {
      padding-top: 27px;
      padding-left: 36px;
    }

    &-text-1 {
      color: ${$color13};
      font-size: 13px;
      font-weight: bold;
      line-height: 1;
      margin: 0 0 15px;
    }

    &-options-container {
    }

    &-each-option {
      font-size: 15px;
      font-weight: bold;
      line-height: 1;
      padding: 15.5px 6px 15.5px 25px;
      text-align: left;
      width: 100%;
      
      &--inline {
        width: auto;
        padding: 15.5px 25px;
        display: inline-block;
       
        &:not(:last-child) {
          margin-right: 10px;
        }
      }

      &:not(:last-child) {
        margin-bottom: 20px;
      }
    }
  }
`;

export default SurveyQuestionPage;
export { SurveyQuestionPage };
