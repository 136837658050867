import styled from 'styled-components';

interface Props {

}

const ResultTitle = styled.div<Props>`
  .title-result {
    font-size: 17px;
    font-weight: bold;
    padding-bottom: 20px;
  }
`;

export default ResultTitle;
export { ResultTitle };
