import styled from 'styled-components';
import { CategoryChoicePage } from 'src/styled/index';
import { media, posr, posa } from 'src/styled/helpers';

const InvestmentChoicePage = styled(CategoryChoicePage)`
  ${media.tabletUp`
    .image-placeholder-wrapper {
      height: 86px;
    }
  `}

  .image-placeholder {
    max-height: 90px;
  }

  .investment-mobile-col {
    ${media.mobile`
      .image-placeholder-wrapper {
        ${posa()};
        left: 0;
        top: 0;
      }

      .image-placeholder {
        max-width: 84px;
      }

      .block-heading {
        ${posr()};
        padding-left: 50px;
        padding-top: 15px;
      }
    `}
  }

  .category-choice-block-3 {
    ${media.tabletUp`
      .block-heading {
        height: 162px;
      }
    `}
  }
`;

export default InvestmentChoicePage;
export { InvestmentChoicePage };
