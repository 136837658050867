import _isArray from 'lodash.isarray';
import _isEmpty from 'lodash.isempty';
import _foreach from 'lodash.foreach';
import moment from "moment";
import { ILupinInfo } from 'src/interface/ILinkageData';
import { INVESMENT_TYPES } from './appConstant';
import { IResStockState } from 'src/interface/IToshinState';
require('moment/locale/ja')
moment.locale("ja")

export enum EXPRESSION_TYPE {
  EQUAL = '=',
  NOT_EQUAL = '!=',
  GREATER_THAN_OR_EQUAL = '>=',
  GREATER_THAN = '>',
  LESSOR_THAN_OR_EQUAL = '<=',
  LESSOR_THAN = '<',
  IN_ARRAY = 'in',
  MULTIPLE_CONDITIONS_AND = '&&',
  MULTIPLE_CONDITIONS_OR = '||',
}

function getAnsweredQuestionBefore(conditional_expressions: any, listAnsweredQuestions: any) {
  let answeredQuestion = {question_key: '', answer: {value:''}};
  for(const answerKey in listAnsweredQuestions) {
    if(conditional_expressions[answerKey]) {
      answeredQuestion = listAnsweredQuestions[answerKey];
      break;
    }
  }
  return answeredQuestion;
}

// check to show or hide current question by expression type from before answered of question
export function checkIsShowQuestionByExpressionType(conditional_expressions: any, listAnsweredQuestions: any): boolean {
  let showQuestion = true;
  // eslint-disable-next-line no-eval
  conditional_expressions = conditional_expressions ? eval('(' + conditional_expressions + ')') : conditional_expressions;

  if(_isEmpty(conditional_expressions) || _isEmpty(listAnsweredQuestions)) {
    // show question
    return true;
  }
  _foreach(conditional_expressions, (conditional_expression: any, key: string) => {
    if (!checkConditionShowQuestion({[key]: conditional_expression}, listAnsweredQuestions)) {
      showQuestion = false;
    }
  });

  return showQuestion;

}

function checkConditionShowQuestion(conditional_expressions: any, listAnsweredQuestions: any) {
  let isShowCurrentQuestion = false;
  let answeredQuestionBefore = getAnsweredQuestionBefore(conditional_expressions, listAnsweredQuestions);
    // if conditional_expressions !== {};
  /*
    Step:
      1. Find before answered
      2. Get before answered value
      3. Get before answered key
      3. Get expression type by answered key before from step 3
      4. Get values will match with before answered
      5. Check if value answered before match with values from step  by expression type to show or hide
  */
  if(answeredQuestionBefore.question_key) {
    const answeredQuestionValueBefore = answeredQuestionBefore.answer && answeredQuestionBefore.answer.value ? answeredQuestionBefore.answer.value : false;
    const answeredQuestionKeyBefore = answeredQuestionBefore.question_key || null;
    const expressionType: EXPRESSION_TYPE | null = (answeredQuestionKeyBefore  && conditional_expressions[answeredQuestionKeyBefore])
      ? conditional_expressions[answeredQuestionKeyBefore]._exp : null;
    const valuesWillMatch = (answeredQuestionKeyBefore  && conditional_expressions[answeredQuestionKeyBefore])
      ? conditional_expressions[answeredQuestionKeyBefore].value : null;
      if(!expressionType) {
      // show questionx`
      return false;
    }

    switch (expressionType) {
      case EXPRESSION_TYPE.EQUAL: {
        if (valuesWillMatch.toString() === answeredQuestionValueBefore.toString() ) {
          isShowCurrentQuestion = true;
        }
        break;
      }
      case EXPRESSION_TYPE.NOT_EQUAL: {
        break;
      }
      case EXPRESSION_TYPE.GREATER_THAN_OR_EQUAL: {
        break;
      }
      case EXPRESSION_TYPE.GREATER_THAN: {
        break;
      }
      case EXPRESSION_TYPE.LESSOR_THAN_OR_EQUAL: {
        break;
      }
      case EXPRESSION_TYPE.LESSOR_THAN: {
        break;
      }
      case EXPRESSION_TYPE.IN_ARRAY: {
        if(_isArray(valuesWillMatch) && valuesWillMatch.indexOf(answeredQuestionValueBefore) > -1) {
          isShowCurrentQuestion = true;
        }
        break;
      }
      case EXPRESSION_TYPE.MULTIPLE_CONDITIONS_AND: {
        break;
      }
      case EXPRESSION_TYPE.MULTIPLE_CONDITIONS_OR: {
        break;
      }
      default:
        isShowCurrentQuestion = false;
        break;
    }
  }

  return isShowCurrentQuestion;
}

export const formatDate = (rawDate: string, formatType?: string) => (
  moment(rawDate, "YYYY-MM-DDTHH:mm:ssZ").format(formatType || "YYYY/M/DD")
);

export const formatMoney = (number: string | number) => {
  if (!number) return "0";
  if (number === "-") return number;
  number = (typeof number === 'number') ? Math.trunc(number) : number;

  return number && number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
};

export const formatChartAxisYLabel = (value: number, precision: 4 | 8 = 4) => {
  if (!value) return 0;
  if (precision >= 8) {
    let number_fixed = (value / 100000000)
    return Number(number_fixed)
  }
  if (precision >= 4) {
    return Number(value)
  }
  return `${value}`;
};

export const formatAxisYSimulator = (value: number) => {
  if (!value) return 0;
  let number_fixed = (value / 10000).toFixed(2)
  return `${Number(number_fixed).toLocaleString('ja-JP')}万`;
};

export const getWindowSize = () => {
  let w = 0,
      h = 0;

  //IE
  if(!window.innerWidth){
    if(!(document.documentElement.clientWidth === 0)){
      //strict mode
      w = document.documentElement.clientWidth;
      h = document.documentElement.clientHeight;
    } else{
      //quirks mode
      w = document.body.clientWidth;
      h = document.body.clientHeight;
    }
  } else {
    //w3c
    w = window.innerWidth;
    h = window.innerHeight;
  }

  return {
    width:w,
    height:h
  };
};

/**
 * detect IE
 * returns version of IE or false, if browser is not Internet Explorer
 */
export function detectIE() {
    var ua = window.navigator.userAgent;

    var msie = ua.indexOf('MSIE ');
    if (msie > 0) {
        // IE 10 or older => return version number
        return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
    }

    var trident = ua.indexOf('Trident/');
    if (trident > 0) {
        // IE 11 => return version number
        var rv = ua.indexOf('rv:');
        return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
    }

    var edge = ua.indexOf('Edge/');
    if (edge > 0) {
       // Edge (IE 12+) => return version number
      return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
    }

    // other browser
    return false;
}

export const isIE = () => {
  const ua = window.navigator.userAgent;
  return /MSIE|Trident|Edge/.test(ua);
};

export const isIE10 = () => {
  const ua = window.navigator.userAgent;
  return /MSIE 10/.test(ua);
};

export const isMobileViewport = () => getWindowSize().width <= 768;

export const getDispValue = (value: number | string, disValue?: any) => {
  disValue = disValue || value;
  return value === 0 ? '±0' : value < 0 ? `${disValue}` : `+${disValue}`
};

export const formatJpMoneyUnit = (amount: any) => {
  amount = Math.trunc(amount);
  let i = 0
    , xis = amount + ""
    , len = 0
    , tmptime = ''
    , showtitles = ''
    , reverses = ''
    , tmp2 = ''
    , suji = ''
    , kanji = ''
    , xaaa = {
      4: {
          "keta": 4,
          "kan": "万",
          "kana": "マン"
      },
      8: {
          "keta": 8,
          "kan": "億",
          "kana": "オク"
      },
      12: {
          "keta": 12,
          "kan": "兆",
          "kana": "チョウ"
      },
      16: {
          "keta": 16,
          "kan": "京",
          "kana": "ケイ"
      },
      20: {
          "keta": 20,
          "kan": "垓",
          "kana": "ガイ"
      },
      24: {
          "keta": 24,
          "kan": "秭",
          "kana": "ジョ"
      },
      28: {
          "keta": 28,
          "kan": "穣",
          "kana": "ジョウ"
      },
      32: {
          "keta": 32,
          "kan": "溝",
          "kana": "コウ"
      },
      36: {
          "keta": 36,
          "kan": "澗",
          "kana": "カン"
      },
      40: {
          "keta": 40,
          "kan": "正",
          "kana": "セイ"
      },
      44: {
          "keta": 44,
          "kan": "載",
          "kana": "サイ"
      },
      48: {
          "keta": 48,
          "kan": "極",
          "kana": "ゴク"
      },
      52: {
          "keta": 52,
          "kan": "恒河沙",
          "kana": "ゴウガシャ"
      },
      56: {
          "keta": 56,
          "kan": "阿僧祇",
          "kana": "アソウギ"
      },
      60: {
          "keta": 60,
          "kan": "那由他",
          "kana": "ナユタ"
      },
      64: {
          "keta": 64,
          "kan": "不可思議",
          "kana": "フカシギ"
      },
      68: {
          "keta": 68,
          "kan": "無量大数",
          "kana": "ムリョウタイスウ"
      }
  };
  len = xis.length;
  for (i = 0; i < len; i++) {
    reverses = xis[i] + reverses;
  }
  len= reverses.length;
  for (i = 0; i < len; i++) {
    tmptime = tmptime + '' + (xaaa[i] ? xaaa[i]['kan'] : '') + reverses[i];
  }
  len = tmptime.length;
  for (i = 0; i < len; i++) {
      showtitles = tmptime[i] + showtitles;
  }
  len = showtitles.length;
  for (i = 0; i < len; i++) {
    var m = showtitles[i].match(/[0-9]/i);
    if (m) {
        suji = suji + '' + m[0];
        if (len === i + 1) {
          if (parseInt(suji) !== 0) {
            tmp2 = tmp2 + suji;
          }
        }
    } else {
      if (showtitles[i + 1]) {
          if (showtitles[i + 1].match(/[0-9]/i)) {
            kanji = showtitles[i] + kanji;
            if (parseInt(suji) !== 0) {
              tmp2 = tmp2 + suji + kanji;
            }
            suji = '';
            kanji = '';
          } else {
            kanji = showtitles[i] + kanji;
          }
      } else {
        kanji = showtitles[i] + kanji;
      }
    }
  }
  showtitles = tmp2 || showtitles.replace('万1千', '万1');
  return `${showtitles}円`;
};

export const  animateCSS = (element, animationName, callback) => {
  const node = document.querySelector(element);
  node.classList.add('animated', animationName);

  function handleAnimationEnd() {
      node.classList.remove('animated', animationName);
      node.removeEventListener('animationend', handleAnimationEnd);

      if (typeof callback === 'function') callback()
  }

  node.addEventListener('animationend', handleAnimationEnd)
};

export const createLupinForm = (info: ILupinInfo) => {
  const {
    naviType, stockCode, nabi_kai_koza_kbn,
    tpf_gmn_kmk_ichj_hzn_no, p1, p2_from_lupin3,
    onldenshikofuflg, p2_from_lupin3_for_fundwrap,
  } = info;
  const stockParams = naviType !== 'product' ?
    {
      formId: 'WKO03001011',
      actionType: 'meigarashiteihyoji',
      nabiKaiShohinKbn: naviType === 'kabu' ? '01' : '02',
      nabiMeigaraCd: stockCode,
      snmtSstmKbn: '01',
      tpfGmnKmkIchjHznNo: tpf_gmn_kmk_ichj_hzn_no,
      nabiKaiKozaKbn: nabi_kai_koza_kbn,
      p2: p2_from_lupin3,
    }
    : {
      loginmode: '01',
      onldenshikofuflg,
      p2: p2_from_lupin3_for_fundwrap,
    };
  return {
    p1: p1,
    ...stockParams,
  }
};

export function jsonToQueryString(json) {
  return '?' +
    Object.keys(json).map(function(key) {
      return encodeURIComponent(key) + '=' +
        encodeURIComponent(json[key]);
    }).join('&');
}

export function checkScrollTop(idx, type) {
  if (idx === 1 && [INVESMENT_TYPES.toshin, INVESMENT_TYPES.kabu].indexOf(type) > -1) {
    window.scrollTo(0, 0);
  }
}

export const onCalculateOffsetGradientWidth = ({
  queryWrapper = '',
  queryOffsetButton = '.offset-button',
  queryOffsetGradient = '.offset-gradient',
  paddingDesktop = 0,
  paddingMobile = 0,
}) => {
  const productIntroBlock = document.querySelector(queryWrapper);
  const buttonOffset = document.querySelector(queryOffsetButton);
  const offsetGradient = document.querySelector(queryOffsetGradient);

  if(!productIntroBlock || !buttonOffset || !offsetGradient) return;

  const { width: windowWidth } = getWindowSize();
  const offsetWrapper = productIntroBlock.getBoundingClientRect();
  const offsetButton = buttonOffset.getBoundingClientRect();

  if (!offsetWrapper || !offsetButton) return;

  // On mobile the padding is different from the desktop version
  const offsetPadding = isMobileViewport() ? paddingMobile : paddingDesktop;

  // Formular: ofssetGradientWidth = screenWidth - (spaceFromLeftEdgeToLeftWrapper + wrapperWidth) + buttonDefaultWidth + padding
  const offsetGradientWidth = (windowWidth - (offsetWrapper.left + offsetWrapper.width)) + offsetButton.width + offsetPadding;

  if (offsetGradientWidth && offsetGradient instanceof HTMLElement) {
    offsetGradient.style.width = `${offsetGradientWidth + 15}px`;
  }
};

export enum NAME_WITH_FLAG {
  t_listed_flag = 'T',
  n_listed_flag = 'M',
  f_listed_flag = 'FK',
  s_flag = 'S',
};

export function checkFlagStock(stock: IResStockState) {
  const kabuDetailURL = process.env.REACT_APP_KABU_DETAIL_URL
  const dataFlag = ['t_listed_flag', 'n_listed_flag', 'f_listed_flag', 's_flag']
  const flagFilter = dataFlag.filter(data => {
    return stock[data] === '1'
  })

  return `${kabuDetailURL}&QCODE=${stock.stock_code.slice(0, 4)}/${NAME_WITH_FLAG[flagFilter[0]]}`
}

export function getDeatilStockKabuLink(stock: IResStockState) {
  const data = checkFlagStock(stock)
  return data
}

export function getDeatilStockToShinLink(stock_code_l: string) {
  const toshinDetailURL = process.env.REACT_APP_TOSSHIN_DETAIL_URL;
  return `${toshinDetailURL}unyo&KEY1=${stock_code_l.slice(-4)}`
}

export const isGuest = () => {
  const token = localStorage.getItem('token') || '';
  return token === 'guest'
}
export const formatYYMMDDJapan = (datetimeString: string) => {
  const datetime = moment(datetimeString, "YYYY-MM-DD");
  if (datetime.isValid()) {
    return datetime.format("LL")
  }
  return '';
}

export const toASCII = (text: string) => {
  if(!text) return;
  let textASCII = zenhan().alphabet(text)
  textASCII = zenhan().marks(textASCII)
  textASCII = zenhan().bracket(textASCII)
  textASCII = zenhan().figs(textASCII)
  textASCII = zenhan().space(textASCII)
  return textASCII;
}

const zenhan = () => {
  return {
    marks: function (str: string) {
      var reg = /[！＂＃＄％＆＇（）＊＋，－．／：；＜＝＞？＠［＼］＾＿｀｛｜｝]/g;
      str = str.replace(reg, function (s) {
          return String.fromCharCode(s.charCodeAt(0) - 65248)
      });
      return str
    },
    bracket: function (str: string) {
        str = str.replace(/（/g, '(');
        str = str.replace(/）/g, ')');
        return str
    },
    figs: function (str: string) {
        str = str.replace(/[０-９]/g, function (s) {
            return String.fromCharCode(s.charCodeAt(0) - 65248)
        });
        return str
    },
    space: function (str: string) {
        return str.replace(/　/g, ' ')
    },
    alphabet: function (str: string) {
        str = str.replace(/[Ａ-Ｚａ-ｚ]/g, function (s) {
            return String.fromCharCode(s.charCodeAt(0) - 65248)
        });
        return str
    }
  }
}