import React from 'react';

interface Props {
  innerHeight: number,
  innerWidth: number,
  className: string,
  includeMargin: boolean,
  text: string,
  xPercent: number,
  yPercent: number,
  style: any
  marginBottom: number,
  marginLeft: number,
  marginRight: number,
  marginTop: number,
  subtext: string,
  isMobile: boolean
}


class ChartLabel extends React.PureComponent<Props> {
  static get requiresSVG() {
    return true;
  }
  public static defaultProps = {
    marginBottom: 0,
    marginLeft: 0,
    marginRight: 0,
    marginTop: 0,
    innerHeight: 0,
    innerWidth: 0
  };

  render() {
    const {
      // rv defined
      innerHeight,
      innerWidth,
      marginBottom,
      marginLeft,
      marginRight,
      marginTop,
      // user defined
      className,
      includeMargin,
      style,
      text,
      subtext,
      xPercent,
      yPercent,
      isMobile
    } = this.props;
    const width = innerWidth + (includeMargin ? marginLeft + marginRight : 0);
    const height = innerHeight + (includeMargin ? marginTop + marginBottom : 0);
    const xPos = width * xPercent + (includeMargin ? 0 : marginLeft);
    const yPos = height * yPercent + (includeMargin ? marginLeft : 0);
    return (
      <g
        transform={`translate(${xPos}, ${yPos})`}
        className={`rv-xy-plot__axis__title ${className}`}>
        <text {...style}>
          <tspan x='0' dy='1.2em' fontSize={isMobile ? "8" : "11"}>{text}</tspan>
          <tspan x='-5' dy='1.2em' fontSize={isMobile ? "8" : "11"}>{subtext}</tspan>
        </text>
      </g>
    );
  }
}

export default ChartLabel;