import * as React from 'react'
import { Route, RouteProps, withRouter, RouteComponentProps } from 'react-router-dom';
import queryString from 'query-string';
import SkeletonAsyncComponent from "./SkeletonAsyncComponent/SkeletonAsyncComponent";

interface Props extends RouteProps ,RouteComponentProps {
  location: any;
  history: any;
}
class AuthRoute extends React.Component<Props> {
  componentDidMount() {
    const params = queryString.parse(this.props.location.search);
    const token = localStorage && localStorage.getItem('token');
    let pathName = ''
    if (params.snsk_nabi_st_kbn) {
      const slug = {
        products: "01",
        kabu: "02",
        toshin: "03"
      }
      pathName = Object.keys(slug).find(k=>slug[k]=== params.snsk_nabi_st_kbn) || ''
    }
    if (params && params.token && typeof params.token === 'string') {
      localStorage.clear()
      localStorage && localStorage.setItem('token', params.token);
      this.props.history.push({ pathname: pathName });
    } else if (params.token === '' || params.error === '1' || !token) {
      localStorage && localStorage.setItem('token', 'guest');
      this.props.history.push({ pathname: pathName });
    }
  }

  render() {
    const { component: Component, ...rest } = this.props;
    const params = queryString.parse(this.props.location.search);
    const token = localStorage && localStorage.getItem('token');
    return (
    <Route {...rest} render={(props) => {
        if (token) {
            return Component && !params.token && params.token !== '' && !params.error ? <Component {...props} /> : <SkeletonAsyncComponent/>
        } else {
          return <SkeletonAsyncComponent />
        }
      }}/>
    )
  }
}

export default withRouter(AuthRoute);
