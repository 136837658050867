import { AxiosResponse } from 'axios';
import { appAxios } from "src/app/appAxios";

export const QuestionService = {
  getProductSurveyList: async () => {
    try {
      let { data }: AxiosResponse = await appAxios.get('/robo/product/questions');
      return data;
    } catch (error) {
      throw error;
    }
  },

  getToshinQuestionList: async () => {
    try {
      const { data }: AxiosResponse = await appAxios.get('/robo/toshin/questions');
      return data;
    } catch(error) {
      throw error;
    } 
  },

  getKabuQuestionList: async () => {
    try {
      const { data }: AxiosResponse = await appAxios.get('/robo/kabu/questions');
      return data;
    } catch(error) {
      throw error;
    } 
  }
}