// import React from "react";
import styled from 'styled-components';
import { $color05, $color18, $color14, media } from 'src/styled/helpers/';

interface Props {
  isMobile: boolean
}
const PanelGrey = styled.div<Props>`
  font-size: 12px;
  background-color: ${props  => (props.isMobile ? `${$color05}` : `${$color18}`)};
  padding: ${props  => (props.isMobile ? '10px 20px 25px' : '55px 80px')};
  .heading {
    color: ${$color14};
    font-size: 16px;
    font-weight: bold;
    line-height: 1;
    margin-bottom: 20px;
    text-align: ${props  => (props.isMobile ? 'left' : 'center')};
  }

  .last-line {
    margin-top: 15px;
  }

  ${media.tabletUp`
    font-size: 14px;
    margin-top: 60px;
    .heading {
      font-size: 18px;
      margin-bottom: 45px;
    }

    .last-line {
      margin-top: 25px;
    }
  `}
`;

export default PanelGrey;
export { PanelGrey };
