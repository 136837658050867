import { IQuestionGroupListState } from "src/interface/IQuestionListState";

export const actionTypes = {
  GET_PRODUCT_QUESTION_LIST:  'GET_PRODUCT_QUESTION_LIST',
  GET_PRODUCT_QUESTION_LIST_PENDING:  'GET_PRODUCT_QUESTION_LIST_PENDING',
  GET_PRODUCT_QUESTION_LIST_SUCCESS:  'GET_PRODUCT_QUESTION_LIST_SUCCESS',
  GET_TOSHIN_QUESTION_LIST:  'GET_TOSHIN_QUESTION_LIST',
  GET_TOSHIN_QUESTION_LIST_SUCCESS:  'GET_TOSHIN_QUESTION_LIST_SUCCESS',
  GET_KABU_QUESTION_LIST:  'GET_KABU_QUESTION_LIST',
  GET_KABU_QUESTION_LIST_SUCCESS:  'GET_KABU_QUESTION_LIST_SUCCESS'
}
  
export const getProductQuestionList = () => ({
  type: actionTypes.GET_PRODUCT_QUESTION_LIST,
})

export const getProductQuestionListPending = () => ({
  type: actionTypes.GET_PRODUCT_QUESTION_LIST_PENDING,
})

export const getProductQuestionSuccess = (data: IQuestionGroupListState) => ({
  type: actionTypes.GET_PRODUCT_QUESTION_LIST_SUCCESS,
  data
})

export const getToshinQuestionList = () => ({
  type: actionTypes.GET_TOSHIN_QUESTION_LIST,
})

export const getToshinQuestionListSuccess = (data: IQuestionGroupListState) => ({
  type: actionTypes.GET_TOSHIN_QUESTION_LIST_SUCCESS,
  data
})

export const getKabuQuestionList = () => ({
  type: actionTypes.GET_KABU_QUESTION_LIST,
})

export const getKabuQuestionListSuccess = (data: IQuestionGroupListState) => ({
  type: actionTypes.GET_KABU_QUESTION_LIST_SUCCESS,
  data
})