// import React from "react";
import styled from 'styled-components';
import {$color14, media, posr} from 'src/styled/helpers/';

const PageSectionHeading = styled.div`
  ${posr()};
  color: ${$color14};
  font-size: 13px;
  font-weight: bold;
  padding-bottom: 15px;
  padding-top: 15px;

  ${media.tabletUp`
    font-size: 16px;
    padding-bottom: 25px;
    padding-top: 50px;
  `}
`;

export default PageSectionHeading;
export { PageSectionHeading };
