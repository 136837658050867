import { actionTypes } from "src/kabu/kabuAction";
import { IKabuState } from "src/interface/IKabuState";

const initialState: IKabuState = {
  listKabuResult: {
    isPending: false,
    results: []
  },
  kabuResult: {
    id: '',
    user_id: '',
    portfolios: [],
    stocks: [],
    simulation: {
      simulation_type: '',
      annual_rate: 0,
      total_accumulated_amount: 0,
      total_profit: 0,
      yearly_accumulated: [],
      monthly_accumulated: []
    },
    summary: {
      investment_style: ''
    },
    category: '',
    answers: {},
    created_at: '',
    isPending: true,
    standard_date: ''
  },
};

export function kabuReducer(state = initialState, action: any) {
  switch (action.type) {
    case actionTypes.GET_RESULT_KABU_LIST_PENDING: {
      return {
        ...state,
        listKabuResult: {
          ...state.listKabuResult,
          results: [],
          isPending: true
        }
      }
    }
    case actionTypes.GET_RESULT_KABU_LIST_SUCCESS: {
      return {
        ...state,
        listKabuResult: {
          ...state.listKabuResult,
          results: action.data,
          isPending: false
        }
      }
    }
    case actionTypes.GET_KABU_RESULT_PENDING: {
      return {
        ...state,
        kabuResult: {
          stocks: [],
          isPending: true
        }
      }
    }
    case actionTypes.GET_KABU_RESULT_SUCCESS: {
      return {
        ...state,
        kabuResult: {
          ...action.data,
          simulation: {
            ...action.data.simulation,
            yearly_accumulated: 'yearly_accumulated' in action.data.simulation? action.data.simulation.yearly_accumulated : [],
            monthly_accumulated: 'monthly_accumulated' in action.data.simulation ? action.data.simulation.monthly_accumulated : []
          },
          isPending: false
        }
      }
    }
    case actionTypes.UPDATE_KABU_STOCK: {
      let newStocks = [...state.kabuResult.stocks];
      newStocks[action.idx] = {
        ...newStocks[action.idx],
        is_bookmark: action.isBookmark
      };

      return {
        ...state,
        kabuResult: {
          ...state.kabuResult,
          stocks: newStocks
        }
      }
    }
    default:
      return state;
  }
}