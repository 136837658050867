import { all, takeLatest, call, put, select } from 'redux-saga/effects'
import { AxiosResponse } from 'axios';
import {BOOKMARK_ACTION, getBookmarkSuccess, getBookmark} from "./bookmarkAction";
import { AnyAction } from 'redux';
import {BookmarksService} from "../api/BookmarkService";
import { bookmarksSelector } from './bookmarkSelector';

// Workers
function* bookmarkStockWorker(action: AnyAction) {
  const {id, navigation} = action;
  try {
    yield call(BookmarksService.bookmark, id, navigation)
  } catch (err) {

  }
}

function* unbookmarkStockWorker(action: AnyAction) {
  const {id, navigation} = action;
  try {
    yield call(BookmarksService.unbookmark, id, navigation)
  } catch (err) {

  }
}

function* getBookmarkWorker(action: AnyAction) {
  const { navigation, isSecondLoad } = action;
  const { toshin , kabu} = yield select(bookmarksSelector)
  const { params, hasLoadmore, bookmarks } = navigation === 'toshin' ? toshin : kabu
  const { data: { stocks, meta } }: AxiosResponse = yield call(BookmarksService.getBookmarkList, navigation, hasLoadmore ? params : undefined)
  const bookmarksLoadmore = hasLoadmore && stocks !== null ? bookmarks.concat(stocks) : stocks
  yield put(getBookmarkSuccess(navigation, bookmarksLoadmore, (meta.current_page < meta.total_pages), meta.next_page));
  if (isSecondLoad) {
    yield put(getBookmark('toshin'))
  }
  try {
  } catch (err) {

  }
}

// Watchers
function* bookmarkStockWatcher() {
  yield takeLatest(BOOKMARK_ACTION.BOOKMARK_STOCK, bookmarkStockWorker)
}

function* unbookmarkStockWatcher() {
  yield takeLatest(BOOKMARK_ACTION.UNBOOKMARK_STOCK, unbookmarkStockWorker)
}

function* getBookmarkWatcher() {
  yield takeLatest(BOOKMARK_ACTION.GET_LIST_BOOKMARK, getBookmarkWorker);
}

function* bookmarkSaga() {
  yield all([
    bookmarkStockWatcher(),
    unbookmarkStockWatcher(),
    getBookmarkWatcher(),
  ])
}

export default bookmarkSaga;