import { IQuestionListState } from "src/interface/IQuestionListState";
import { actionTypes } from "./QuestionAction";

const initialState: IQuestionListState = {
  product: { 
    question_groups: [],
    isPending: false
  },
  toshin: { question_groups: []},
  kabu: { question_groups: []},
}

export function questionReducer(state = initialState, action: any) {
  switch (action.type) {
    case actionTypes.GET_PRODUCT_QUESTION_LIST_PENDING:
      return {
        ...state,
        product: {
          ...state.product, 
          question_groups: [],
          isPending: true
        }
      }
    case actionTypes.GET_PRODUCT_QUESTION_LIST_SUCCESS:
      return {
        ...state,
        product: action.data
      }
    case actionTypes.GET_TOSHIN_QUESTION_LIST_SUCCESS:
      return {
        ...state,
        toshin: action.data
      }
    case actionTypes.GET_KABU_QUESTION_LIST_SUCCESS:
      return {
        ...state,
        kabu: action.data
      }
    default: 
      return state;
  }
}