// import React from "react";
import styled from 'styled-components';
import {
  noOutline,
  size,
  posr,
  posa,
  ROW_TYPE,
  SKIN,
  $color08,
  $color07, media
} from 'src/styled/helpers/';
import { DatatableResultPage } from 'src/styled/index';

const FavoriteBrandsPage = styled(DatatableResultPage)`
  &.modal {
    background: #f8f7f6;
  }

  .modal-background {
    background: #f8f7f6;
  }

  .modal-content {
    height: 100%;
    width: 100%;
    max-height: calc(100vh);
    -webkit-overflow-scrolling: touch;
  }

  .max-container {
    padding-left: 20px;
    padding-right: 20px;
    top: 0;
    left: 0;
    margin: 0;
    right: 0;
    position: absolute;
  }

  .container-favorite-brand {
    ${posr(999)}

    .columns {
      &:last-child {
        margin-bottom: 0;
      }
    }

    .close-modal {
      ${noOutline};
      ${posa()}
      ${size('21px')}
      background-color: transparent;
      border: none;
      cursor: pointer;
      right: 0;
      top: 27px;
      padding: 0;

      ${media.tabletUp`
        top: 52px;
      `}
    }
  }

  ${media.mobile`
    .tabs a {
      padding-left: 50px;
      padding-right: 50px;
    }
    .disclaimer {
      margin-top: 30px;
    }
  `}

  .${ROW_TYPE.THREE}.skin-${SKIN.PRIMARY} .col-1 {
    a,.no-style {
      color: ${$color08};
      font-weight: bold;
      font-size: 16px;
      line-height: 20px;
      text-decoration: underline;
      cursor: pointer;
    }
  }

  .${ROW_TYPE.THREE}.skin-${SKIN.THIRD} .col-1 {
    a,.no-style {
      color: ${$color07};
      font-weight: bold;
      font-size: 16px;
      line-height: 20px;
      text-decoration: underline;
      cursor: pointer;
    }
  }
`;

export default FavoriteBrandsPage;
export { FavoriteBrandsPage };
