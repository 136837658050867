import {AnyAction} from 'redux';
import {StockNavigation} from "../interface/IBookmarkState";
import { IResStockState } from 'src/interface/IToshinState';

export const BOOKMARK_ACTION = {
  BOOKMARK_STOCK: 'BOOKMARK_STOCK',
  UNBOOKMARK_STOCK: 'UNBOOKMARK_STOCK',
  UPDATE_UNBOOKMARK_STOCK_LIST: 'UPDATE_UNBOOKMARK_STOCK_LIST',
  GET_LIST_BOOKMARK: 'GET_LIST_BOOKMARK',
  GET_LIST_BOOKMARK_PENDING: 'GET_LIST_BOOKMARK_PENDING',
  GET_LIST_BOOKMARK_SUCCESS: 'GET_LIST_BOOKMARK_SUCCESS',
  RESET_BOOKMARK_LIST: 'RESET_BOOKMARK_LIST'
};

export const bookmarkStock = (id: string, navigation: StockNavigation): AnyAction => ({
  type: BOOKMARK_ACTION.BOOKMARK_STOCK,
  id,
  navigation
});

export const unbookmarkStock = (id: string, navigation: StockNavigation): AnyAction => ({
  type: BOOKMARK_ACTION.UNBOOKMARK_STOCK,
  id,
  navigation
});

export const updateBookmarkStockList = (id: string, navigation: StockNavigation): AnyAction => ({
  type: BOOKMARK_ACTION.UPDATE_UNBOOKMARK_STOCK_LIST,
  id,
  navigation
});

export const getBookmark = (navigation: StockNavigation, isSecondLoad: boolean = false) => ({
  type: BOOKMARK_ACTION.GET_LIST_BOOKMARK,
  navigation,
  isSecondLoad
})

export const getBookmarkPending = (navigation: StockNavigation): AnyAction => ({
  type: BOOKMARK_ACTION.GET_LIST_BOOKMARK_PENDING,
  navigation,
})

export const getBookmarkSuccess = (navigation: StockNavigation, bookmarks: IResStockState[], hasLoadmore: boolean, next_page: number): AnyAction => ({
  type: BOOKMARK_ACTION.GET_LIST_BOOKMARK_SUCCESS,
  navigation,
  bookmarks,
  hasLoadmore,
  next_page
})


export const resetBookmarkList = (navigation: StockNavigation) => ({
  type: BOOKMARK_ACTION.RESET_BOOKMARK_LIST,
  navigation
})