import styled from 'styled-components';
import { GAP, media, $color01 } from 'src/styled/helpers/';

interface Props {
  block?: boolean
}

const Paper = styled.div<Props>`
  background-color: ${$color01};
  ${props => {
    if (props.block) {
      return `
        padding-bottom: 55px;
        padding-top: 55px;
      `;
    }

    return `padding: 12px`;
  }}
  .tab-chart-simulation {
    ${media.mobile`
      display: flex;
      flex-flow: column-reverse;
    `}
  }
`;

const PaperSmall = styled.div`
  background-color: ${$color01};
  padding: 12px;
`;

const PaperLarge = styled.div`
  background-color: ${$color01};
  padding: ${GAP.MOBILE};

  ${media.tabletUp`
    padding: 50px 0;
    &.shorttop {
      padding-top: 20px;
    }
  `}

  ${media.iphone5`
    padding: ${GAP.MOBILE} ${GAP.IPHONE5};
  `}
  }
  }
`;

export default Paper;
export { Paper, PaperSmall, PaperLarge };
