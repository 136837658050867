// import React from "react";
import styled from 'styled-components';
import { size, flexRow, gradient5, $color01 } from 'src/styled/helpers';

import navIcon1 from 'src/assets/images/mobile-nav-1.png';
import navIcon2 from 'src/assets/images/mobile-nav-2.png';
import navIcon3 from 'src/assets/images/mobile-nav-3.png';
import navIcon4 from 'src/assets/images/mobile-nav-4.png';
import navIcon5 from 'src/assets/images/mobile-nav-5.png';
import imgCloseMobile from 'src/assets/images/mobile-img-close.png';

const IconNav1 = styled.i`
  ${size('24px')};
  background-image: url(${navIcon1});
  background-size: 24px auto;
  background-repeat: no-repeat;
  display: inline-block;
`;

const IconNav2 = styled(IconNav1)`
  ${size('27px', '24px')};
  background-image: url(${navIcon2});
  background-size: 27px auto;
`;

const IconNav3 = styled(IconNav1)`
  ${size('26px', '20px')};
  background-image: url(${navIcon3});
  background-size: 26px auto;
`;

const IconNav4 = styled(IconNav1)`
  ${size('20px', '15px')};
  background-image: url(${navIcon4});
  background-size: 16px auto;
`;

const IconNav5 = styled(IconNav1)`
  ${size('24px')};
  background-image: url(${navIcon5});
  background-size: 24px auto;
`;

const MobileMenu = styled.div`
  .modal-content {
    ${gradient5()};
    color: ${$color01};
    flex: 1 1 0;
    height: 100%;
    max-height: 100vh;
    padding: 65px 0 15px;
    overflow-y: auto;

    .mobile-menu-content {
      margin-left: auto;
      margin-right: auto;
      max-width: 253px;
      width: 96%;
    }
  }

  .modal-close.is-large {
    ${size('20px')};
    background-image: url(${imgCloseMobile});
    background-size: 20px 20px;
    background-repeat: no-repeat;
    background-position: center center;
    border-radius: 0;
    right: 15px;
    top: 15px;
    position: absolute;

    &:active,
    &:hover,
    &:focus {
      background-color: transparent;
    }

    &:after {
      height: 100%;
    }

    &:before {
      width: 100%;
    }

    &:before,
    &:after {
      display: none;
      visibility: hidden;
    }
  }

  .mobile {
    &-lastline {
      font-size: 10px;
    }

    &-logo {
      img {
        width: 108px;
      }
    }

    &-textblock {
      font-size: 10px;
      margin-bottom: 20px;

      .is-bold {
        font-weight: bold;
      }
    }

    &-nav {
      margin-bottom: 25px;
      margin-top: 35px;

      li {
        border-top: 1px solid rgba(255,255,255,.5);

        &:last-child {
          border-bottom: 1px solid rgba(255,255,255,.5);
        }
      }

      a {
        ${flexRow()};
        align-items: center;
        color: ${$color01};
        font-size: 16px;
        font-weight: bold;
        padding-bottom: 25px;
        padding-top: 25px;

        span {
          margin-right: auto;
        }

        i {
          margin-left: auto;
        }
      }
    }
  }
`;

export default MobileMenu;
export { MobileMenu, IconNav1, IconNav2, IconNav3, IconNav4, IconNav5 };
