import { all, takeLatest, put, call, takeEvery, delay } from "redux-saga/effects";
import { AxiosResponse } from 'axios';
import { 
  actionTypes,
  getResultToshinListSuccess,
  getResultToshinSuccess,
  getToshinChartByStockSuccess,
  getResultToshinListPending
} from "./toshinAction";
import { ToshinService } from "src/api/ToshinService";

function* getResultToshinListSaga() {
  try {
    yield put(getResultToshinListPending())
    const { data : { results } }: AxiosResponse = yield call(ToshinService.getResultToshinList);
    yield put(getResultToshinListSuccess(results))
  } catch (error) {
    console.log(error)
  }
}

function* getResultToshinSaga(action: any) {
  try {
    const { id } = action;
    const { data }: AxiosResponse = yield call(ToshinService.getResultToshin, id);
    yield put(getResultToshinSuccess(data))
  } catch(error) {

  }
}

function* getToshinChartSaga(action: any) {
  const { stock_id } = action
  try {
    yield delay(500)
    const { data : { values } }: AxiosResponse = yield call(ToshinService.getToshinDataChart, stock_id);
    yield delay(500)
    yield put(getToshinChartByStockSuccess(values, stock_id))
  } catch (error) {
    console.log(error)
  }
}

function* watchGetToshinChartSaga() {
  yield takeEvery(actionTypes.GET_TOSHIN_CHART_BY_STOCK, getToshinChartSaga);
}

function* watchGetToshinResultList() {
  yield takeLatest(actionTypes.GET_RESULT_TOSHIN_LIST, getResultToshinListSaga);
}

function* watchGetToshinResult() {
  yield takeLatest(actionTypes.GET_RESULT_TOSHIN, getResultToshinSaga);
}

export function* toshinSaga() {
  yield all([
    watchGetToshinResultList(),
    watchGetToshinResult(),
    watchGetToshinChartSaga()
  ]);
}
