// import React from "react";
import styled from 'styled-components';
import { media, $color07, posr } from 'src/styled/helpers/';

interface Props {
  equalHeight?: boolean,
  maxImageWidth?: string | number
}

const BlockStyle1 = styled.div<Props>`
  text-align: left;
  font-size: 14px;

  .image-placeholder {
    img {
      display: block;
      margin-left: auto;
      margin-right: auto;
      max-width: ${props =>
        props.maxImageWidth ? `${props.maxImageWidth}px` : 'none'
      }
    }
  }

  .block-heading {
    ${posr()};
    text-align: center;
    padding-top: 15px;
    margin-bottom: 20px;

    h5 {
      color: ${$color07};
      font-size: 18px;
      font-weight: bold;
      margin: 12px 0 0;
    }
  }

  .block-content {
    margin-bottom: 15px;

    p {
      line-height: 18px;
      margin-top: 0;

      &:not(:last-child) {
        margin-bottom: 10px;
      }
    }
  }

  .block-link {
    button {
      font-size: 16px;
      font-weight: bold;
      width: 100%;
    }
  }
  .arrow-kabu {
    width: 38px;
    margin: 0 auto;
  } 

  ${media.tabletUp`
    ${(props: Props) =>
      props.equalHeight &&
    `
      display: flex;
      flex-flow: column nowrap;
      height: 100%;

      .block-link {
        margin-top: auto;

        button {
          padding: 19px 5px;
        }
      }
    `}
  `}
`;

export default BlockStyle1;
export { BlockStyle1 };
