// import React from "react";
import styled from 'styled-components';
import {
  size,
  posr,
  $color18,
  posa,
  flexRow,
  noOutline,
  $color08,
  $color13,
} from 'src/styled/helpers';

const SurveyQuestionPageMobile = styled.div`
  .mobile-question-viewport {
    display: flex;
    flex-flow: column nowrap;
    width: 100%;
  }

  .mobile-temporary-message {
    ${posa()};
    ${size('190px', '40px')};
    right: 20px;
    bottom: 10px;
  }

  .mobile-question {
    &-body {
      ${posr()};
      background-color: ${$color18};
      flex: 1 1 0;
      height: auto;
      padding: 0 20px;
    }

    &-heading {
      padding-top: 12px;
    }

    &-options {
      margin-top: auto;
      padding: 15px 20px 10px;
    }

    &-container {
      padding-bottom: 60px;
    }
  }

  .question-options-container {
    select {
      height: 44px;
    }

    .question-each-option {
      font-size: 15px;
      font-weight: bold;
      line-height: 1;
      padding: 14.5px 15px;
      text-align: left;
      width: 100%;
      
      &--inline {
        width: auto;
        display: inline-block;
       
        &:not(:last-child) {
          margin-right: 10px;
        }
      }

      &:not(:last-child) {
        margin-bottom: 10px;
      }
    }
  }

  .question-text-1 {
    color: ${$color13};
    font-size: 13px;
    font-weight: bold;
    line-height: 1;
    margin: 0 0 15px;
  }

  .question-back-edge {
      button {
        ${noOutline};
        ${flexRow()};
        align-items: center;
        background-color: transparent;
        border: none;
        padding: 15px 0 5px;
      }

      img {
        ${size('6px', '8px')};
        display: block;
        margin-right: 6px;
      }

      span {
        color: ${$color08};
        font-size: 13px;
        font-weight: bold;
      }
    }
  }

`;

export { SurveyQuestionPageMobile };
