import styled from 'styled-components';

const Disclaimer1 = styled.div`
  padding: 10px 15px;
  border: 1px solid #ccc;
  margin-bottom: 20px;
  font-size: 12px;
  p {
    padding: 5px 0;
  }
`;
  
export default Disclaimer1;
export { Disclaimer1 };
