import React from 'react';
import { TableContainer as StyledTableContainer } from 'src/styled';

interface Props {
  className: string;
}

const TableContainer: React.FC<Props> = ({ children, className }) => {
  return (
    <StyledTableContainer className={className}>
      {children}
    </StyledTableContainer>
  );
};

export default TableContainer;
