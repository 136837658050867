import { actionTypes } from "./AnswerAction";
import { IAnswerListState, IAnswerSurveyState } from "src/interface/IAnswerListState";
import { IQuestionState } from "src/interface/IQuestionListState";
import _clone from "lodash.clone";

const initAnswer: IAnswerSurveyState = {
  currentQuestionIdx: 0,
  currentQuestionToshinIdx: -1,
  answeredQuestionList: {},
  prevQuestionList: [],
  questionWillShow: {
    question: {
      answers: [],
      is_active: false,
      category: '',
      key: '',
      text: '',
      conditional_expressions: ''
    },
    answer: {
      value: '',
      image_url: '',
      text: ''
    }
  }
}
const initialState: IAnswerListState = {
  product: initAnswer,
  toshin:  initAnswer,
  kabu: initAnswer
}

export function answerReducer(state = initialState, action: any) {
  const { typeAnswer, question, answer } = action;

  switch (action.type) {
    case actionTypes.ADD_ANSWER_SURVEY:
      return {
        ...state,
        [typeAnswer]: {
          ...state[typeAnswer],
          answeredQuestionList: {
            ...state[typeAnswer].answeredQuestionList,
            [question.key] : {
              question_key: question.key,
              answer,
              question
            }
          },
          prevQuestionList: [
            ...state[typeAnswer].prevQuestionList,
            question
          ]
        }
      }

    case actionTypes.UPDATE_CURRENT_QUESTION_IDX:
      return {
        ...state,
        [typeAnswer]: {
          ...state[typeAnswer],
          currentQuestionIdx: action.idx
        }
      }
    case actionTypes.UPDATE_CURRENT_TOSHIN_QUESTION_IDX:
      return {
        ...state,
        [typeAnswer]: {
          ...state[typeAnswer],
          currentQuestionToshinIdx: action.idx
        }
      }
    case actionTypes.REMOVE_ANSWER_SURVEY:
      let answeredQuestionList = _clone(state[typeAnswer].answeredQuestionList);
      let questionList: Array<IQuestionState> = state[typeAnswer].prevQuestionList;
      delete answeredQuestionList[question.key];

      return {
        ...state,
        [typeAnswer]: {
          ...state[typeAnswer],
          answeredQuestionList: {
            ...answeredQuestionList
          },
          prevQuestionList: [
            ...questionList.slice(0, questionList.length - 1)
          ]
        }
      }
    case actionTypes.UPDATE_QUESTION_WILL_SHOW:
      return {
        ...state,
        [typeAnswer]: {
          ...state[typeAnswer],
          questionWillShow: {
            question: action.question ,
            answer: action.answer
          }
        }
      }
    case actionTypes.RESET_ANSWER_SURVEY: 
      return {
        ...initialState
      }
    default: 
      return state;
  }
}
