import {css, DefaultTheme, ThemedCssFunction} from 'styled-components';
const $color01 = '#fff';
const $color02 = '#000';
const $color03 = '#7D172B';
const $color04 = '#CC9900';
const $color05 = '#F8F7F6';
const $color06 = '#e5e5e5';
const $color07 = '#B85E00';
const $color08 = '#C93A55';
const $color09 = '#BF4F24';
const $color10 = '#B85E00';
const $color11 = '#333333';
const $color12 = '#e58725';
const $color13 = '#808080';
const $color14 = '#33417F';
const $color15 = '#C54242';
const $color16 = '#BC5515';
const $color17 = '#666666';
const $color18 = '#f1efed';
const $color19 = '#999999';
const $color20 = '#E80C39';
const $color21 = '#1C8239';
const $color22 = '#BFBFBF';
const $color23 = '#8B5217';
const $color24 = '#6F2030';
const $color25 = '#909094';
const $color26 = '#b2b2b2';
const $color27 = '#1d3693';
const $color28 = '#BA5A0B';
const $color29 = '#C14C2C';
const $color30 = '#C73E4C';
const $color31 = '#BF3751';
const $color32 = '#66A11A';
const $color33 = '#33417F';
const $color34 = '#802536';
const $color35 = '#416610';

const GAP = {
  MOBILE: '40px',
  IPHONE5: '25px'
};

const SKIN = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  THIRD: 'third'
};

const COLOR_TYPE = {
  NEGATIVE: 'negative',
  POSITIVE: 'positive',
  UNCHANGED: 'unchanged',
  FIRST: 'first',
  SECOND: 'second'

};

const ROW_TYPE = {
  ONE: 'is-style-1',
  TWO: 'is-style-2',
  THREE: 'is-style-3'
};

const getColorType = (value: number) => value === 0 ? COLOR_TYPE.UNCHANGED : (value < 0 ? COLOR_TYPE.NEGATIVE : COLOR_TYPE.POSITIVE);

const getThemeColor = (skin: string, base: string) => {
  switch (skin) {
    case SKIN.PRIMARY:
      return $color08;
    case SKIN.SECONDARY:
      return $color09;
    case SKIN.THIRD:
      return $color10;
    default:
      return base;
  }
};

const alignByTextAlign = (pos?: 'left' | 'center' | 'right') => {
  switch (pos) {
    case 'left':
    default: {
      return `text-align: left;`;
    }
    case 'center': {
      return `text-align: center;`;
    }
    case 'right': {
      return `text-align: right;`;
    }
  }
};

const alignByMargin = (pos: 'left' | 'center' | 'right') => {
  let left, right;

  switch (pos) {
    default:
    case 'left': {
      left = 0;
      right = 0;
      break;
    }
    case 'right': {
      left = 'auto';
      right = 0;
      break;
    }
    case 'center': {
      left = 'auto';
      right = 'auto';
      break;
    }
  }

  return `
    margin-left: ${left};
    margin-right: ${right};
  `;
};

const gradient5 = () => `
    background: ${$color08};
    background: -moz-linear-gradient(35deg, ${$color08} 0%, ${$color08} 25%, ${$color12} 100%);
    background: -webkit-linear-gradient(35deg, ${$color08} 0%, ${$color08} 25%, ${$color12} 100%);
    background: linear-gradient(45deg, ${$color08} 0%, ${$color08} 25%, ${$color12} 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="${$color08}",endColorstr="${$color12}",GradientType=1);
`;

const gradient1 = () => `
    background: ${$color08};
    background: -moz-linear-gradient(35deg, ${$color08} 0%, ${$color08} 40%, ${$color12} 100%);
    background: -webkit-linear-gradient(35deg, ${$color08} 0%, ${$color08} 40%, ${$color12} 100%);
    background: linear-gradient(45deg, ${$color08} 0%, ${$color08} 40%, ${$color12} 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="${$color08}",endColorstr="${$color12}",GradientType=1);
`;

const gradient2 = () => `
    background: ${$color12};
    background: -moz-linear-gradient(45deg, ${$color12} 0%, ${$color12} 40%, ${$color08} 100%);
    background: -webkit-linear-gradient(45deg, ${$color12} 0%, ${$color12} 40%, ${$color08} 100%);
    background: linear-gradient(45deg, ${$color12} 0%, ${$color12} 40%, ${$color08} 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="${$color12}",endColorstr="${$color08}",GradientType=1);
`;

const gradient3 = () => `
    background: ${$color23};
    background: -moz-linear-gradient(45deg, ${$color23} 0%, ${$color24} 100%);
    background: -webkit-linear-gradient(45deg, ${$color23} 0%, ${$color24} 100%);
    background: linear-gradient(45deg, ${$color23} 0%, ${$color24} 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="${$color23}",endColorstr="${$color24}",GradientType=1);
`;

const gradient6 = () => `
    background: ${$color23};
    background: -moz-linear-gradient(45deg, ${$color24} 0%, ${$color23} 100%);
    background: -webkit-linear-gradient(45deg, ${$color24} 0%, ${$color23} 100%);
    background: linear-gradient(45deg, ${$color24} 0%, ${$color23} 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="${$color24}",endColorstr="${$color23}",GradientType=1);
`;


const gradient4 = () => `
  background: ${$color08};
  background: -moz-linear-gradient(left,  ${$color08} 0%, #cc434f 21%, #d55b40 54%, #e38328 96%, ${$color12} 100%);
  background: -webkit-linear-gradient(left,  ${$color08} 0%,#cc434f 21%,#d55b40 54%,#e38328 96%,${$color12} 100%);
  background: linear-gradient(to right,  ${$color08} 0%,#cc434f 21%,#d55b40 54%,#e38328 96%,${$color12} 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='${$color08}', endColorstr='${$color12}',GradientType=1 );
`;

const gradient7 = () => `
    background: ${$color12};
    background: -moz-linear-gradient(90deg, ${$color12} 0%, ${$color08} 100%);
    background: -webkit-linear-gradient(90deg, ${$color12} 0%, ${$color08} 100%);
    background: linear-gradient(90deg, ${$color12} 0%, ${$color08} 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="${$color12}",endColorstr="${$color08}",GradientType=1);
`;

const gradient8 = () => `
  background: ${$color08};
  background: -moz-linear-gradient(30deg, ${$color08} 0%, ${$color07} 100%);
  background: -webkit-linear-gradient(30deg, ${$color08} 0%, ${$color07} 100%);
  background: linear-gradient(30deg, ${$color08} 0%, ${$color07} 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="${$color08}",endColorstr="${$color07}",GradientType=1);
`;

const flexRow = (isWrap = false) => `
  display: flex;
  flex-flow: row ${isWrap ? `wrap` : `nowrap`};
`;

const size = (w: string, h?: string) => `
  width: ${w};
  height: ${h === undefined ? w : h};
`;

const posr = (z = 1) => `
  position: relative;
  z-index: ${z};
`;

const posa = (z = 10) => `
  position: absolute;
  z-index: ${z};
`;

const noOutline = css`
  &:hover,
  &:focus {
    outline: none;
  }
`;

const extendPaper = () => `
  background-color: ${$color01};
  padding-bottom: 55px;
  padding-top: 55px;
`;

const media: {
  largeDesktop: ThemedCssFunction<DefaultTheme>,
  desktop: ThemedCssFunction<DefaultTheme>,
  tablet: ThemedCssFunction<DefaultTheme>,
  tabletUp: ThemedCssFunction<DefaultTheme>,
  mobile: ThemedCssFunction<DefaultTheme>,
  mobile_480: ThemedCssFunction<DefaultTheme>,
  iphone5: ThemedCssFunction<DefaultTheme>,
  ie: ThemedCssFunction<DefaultTheme>,
} = {
  largeDesktop: (...args) => css`
    @media screen and (min-width: 1441px) {
    // @ts-ignore
      ${css(...args)};
    }
  `,
  desktop: (...args) => css`
    @media screen and (min-width: 1024px) {
    // @ts-ignore
      ${css(...args)};
    }
  `,
  tablet: (...args) => css`
    @media screen and (min-width: 769px) and (max-width: 1023px) {
    // @ts-ignore
      ${css(...args)};
    }
  `,
  tabletUp: (...args) => css`
    @media screen and (min-width: 769px) {
    // @ts-ignore
      ${css(...args)};
    }
  `,
  mobile: (...args) => css`
    @media screen and (max-width: 768px) {
    // @ts-ignore
      ${css(...args)};
    }
  `,
  mobile_480: (...args) => css`
    @media screen and (max-width: 480px) {
    // @ts-ignore
      ${css(...args)};
    }
  `,
  iphone5: (...args) => css`
    @media screen and (max-width: 320px) {
    // @ts-ignore
      ${css(...args)};
    }
  `,
  ie: (...args) => css`
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
     // @ts-ignore
      ${css(...args)};
    }
  `
};

export {
  $color01,
  $color02,
  $color03,
  $color04,
  $color05,
  $color06,
  $color07,
  $color08,
  $color09,
  $color10,
  $color11,
  $color12,
  $color13,
  $color14,
  $color15,
  $color16,
  $color17,
  $color18,
  $color19,
  $color20,
  $color21,
  $color22,
  $color23,
  $color24,
  $color25,
  $color26,
  $color27,
  $color28,
  $color29,
  $color30,
  $color31,
  $color32,
  $color33,
  $color34,
  $color35,
  COLOR_TYPE,
  GAP,
  ROW_TYPE,
  SKIN,
  alignByMargin,
  alignByTextAlign,
  extendPaper,
  flexRow,
  getThemeColor,
  gradient1,
  gradient2,
  gradient3,
  gradient4,
  gradient5,
  gradient6,
  gradient7,
  gradient8,
  media,
  noOutline,
  posa,
  posr,
  size,
  getColorType,
};
