import { all, takeLatest, put, call, select } from "redux-saga/effects";
import { push } from 'react-router-redux';
import _find from "lodash.find";
import _groupBy from "lodash.groupby";
import _map from "lodash.map";
import _foreach from "lodash.foreach";

import { 
  actionTypes,
  addAnswerSurvey,
  removeAnswerSurvey,
  updateCurrentQuestionIdx,
  updateQuestionWillShow,
  updateCurrentToshinQuestionIdx
} from "./AnswerAction";
import { IQuestionGroupListState, IQuestionState } from "src/interface/IQuestionListState";
import { checkIsShowQuestionByExpressionType, isGuest } from "src/common/utils/functionUtil";
import { answerListStateSelector} from "./AnswerSelector";
import { INVESMENT_TYPES } from "src/common/utils/appConstant";
import { AnswerService } from "src/api/AnswerService";
import { AxiosResponse } from "axios";

function* handleOnNextQuestion(questions: IQuestionGroupListState, type: string) {
  try {
    
    const answerListState = yield select(answerListStateSelector);
    // before fix
    // incase Toshin need to check Risk Level
    // if user belongs risk level go to question index 6;
    // after fix DLT-508
    // if user belongs risk level go to question index 5(cuz remove question 2);
    // https://docs.google.com/spreadsheets/d/1AqDZmmjkTg7B_F70cxrxO_vQ65iZP_75rWH4Cwgjp7c/edit#gid=1260980633&range=I8:I21
    const isRiskLevel = yield call(checkRiskLevel, type, answerListState );
    const { question_groups = []} = questions;
    const currentQuestionIdx = isRiskLevel ? 5 : answerListState[type].currentQuestionIdx + 1;
    let questionList;
    
    for (let i = currentQuestionIdx; i < question_groups.length; i++) {
      questionList = question_groups[i].questions;
    
      for (let j = 0; j < questionList.length; j++) {
        if (checkIsShowQuestionByExpressionType(questionList[j].conditional_expressions, answerListState[type].answeredQuestionList)) {
          yield put(updateCurrentQuestionIdx(type, i));
          yield put(updateCurrentToshinQuestionIdx(type, i));
          return yield put(updateQuestionWillShow(type, questionList[j]));
        }
      }
    }
    return null;
  } catch(error) {

  }
}

function* checkConditionSubmitAnswer(type: string, questions: IQuestionGroupListState) {
  try {
    const { question_groups } = questions;
    const answerListState = yield select(answerListStateSelector);
    const { currentQuestionIdx, answeredQuestionList } = answerListState[type];
    const PRODUCT_ANSWER_MAX = 4;
    
    switch (type) {
      case INVESMENT_TYPES.product:
        const answerArrray = _map(answeredQuestionList, "answer");
        const groupByValue = _groupBy(answerArrray, "value");
        const categoryKeys = Object.keys(groupByValue);

        for(let i = 0; i < categoryKeys.length; i++) {
          if (groupByValue[categoryKeys[i]].length >= PRODUCT_ANSWER_MAX) {
            return true
          }
        }
        return false;

      default:
        if (currentQuestionIdx >= question_groups.length - 1) return true;
    }
    return false;
  } catch(error) {

  }
}

function* handleSubmitAnswer(type: string) {
  const answerListState = yield select(answerListStateSelector);
  const { answeredQuestionList } = answerListState[type];
  const answers = {};
  let responseData:AxiosResponse;

  
  _foreach(answeredQuestionList, (item: any) => {
    answers[item.question_key] = item.answer.value
  });
  switch(type) {
    case INVESMENT_TYPES.product:
      responseData =  yield call(AnswerService.submitProductAnswer, answers);
      if (isGuest()) {
        checkGuestData(INVESMENT_TYPES.product, responseData)
      }
      return yield put(push(`/products/result/${responseData.data.id}`));
    case INVESMENT_TYPES.toshin:
      responseData =  yield call(AnswerService.submitToshinAnswer, answers);
      if (isGuest()) {
        checkGuestData(INVESMENT_TYPES.toshin, responseData)
      }
      return yield put(push(`/toshin/result/${responseData.data.id}`));
    case INVESMENT_TYPES.kabu: 
      responseData =  yield call(AnswerService.submitKabuAnswer, answers);
      if (isGuest()) {
        checkGuestData(INVESMENT_TYPES.kabu, responseData)
      }
      return yield put(push(`/kabu/result/${responseData.data.id}`));
  }
}

function checkGuestData(type: string, responseData: any) {
  localStorage.setItem(`${type}_result`, JSON.stringify(type === INVESMENT_TYPES.product ? responseData.data.products : responseData.data))
}

function checkRiskLevel(type: string, answerListState: any ) {
  const { currentQuestionIdx, answeredQuestionList } = answerListState[type];

  if (type === INVESMENT_TYPES.toshin && 1 < currentQuestionIdx &&  currentQuestionIdx < 5) {
    const toshinAnswers =  _map(answeredQuestionList, "answer");
    const groupLevel = _groupBy(toshinAnswers, "value");
    const levelKeys = ['low', 'normal', 'high'];

    for(let i = 0; i < levelKeys.length; i++) {
      if(groupLevel[levelKeys[i]] && groupLevel[levelKeys[i]].length >= 2) return true;
    }
  }
  return false;
}

function* clickNextQuestionSaga(action: any) {
  try {
    const { typeAnswer, questionList, question, answer } = action;
    yield put(addAnswerSurvey(typeAnswer, question, answer));
    const isSubmitAnswer = yield call(checkConditionSubmitAnswer, typeAnswer, questionList);
    if (isSubmitAnswer) {
      yield call(handleSubmitAnswer, typeAnswer);
    } else {
      yield call(handleOnNextQuestion, questionList, typeAnswer);
    }
    
  } catch (error) {

  }
}

function* handleOnPrevQuestion(questions: IQuestionGroupListState, type: string) {
  try {
    const answerListState = yield select(answerListStateSelector);
    const { prevQuestionList, answeredQuestionList } = answerListState[type];
    const lastQuestion: IQuestionState= prevQuestionList[prevQuestionList.length -1];
    const keyQuestion = lastQuestion.key;
    // get index of last answerd in questions
    const { question_groups = []} = questions;
    let questionList;
    for (let i = 0; i < question_groups.length; i++) {
      questionList = question_groups[i].questions;
  
      if(_find(questionList, (obj: IQuestionState) => (obj.key === keyQuestion))) {
        yield put(updateCurrentQuestionIdx(type, i));
        yield put(updateCurrentToshinQuestionIdx(type, i));
        return yield put(updateQuestionWillShow(type, lastQuestion, answeredQuestionList[keyQuestion].answer));
      }
    }
  } catch(error) {

  }
}

function* onClickPrevQuestionSaga(action: any) {
  try {
    const { typeAnswer, questionList, question } = action;
    const answerListState = yield select(answerListStateSelector);
    const { answeredQuestionList } = answerListState[typeAnswer];
    if (answeredQuestionList.length === 1) return
    yield call(handleOnPrevQuestion, questionList, typeAnswer);
    yield put(removeAnswerSurvey(typeAnswer, question));
  } catch(error) {

  }
}

function* watchClickNextQuestion() {
  yield takeLatest(actionTypes.CLICK_NEXT_QUESTION, clickNextQuestionSaga);
}

function* watchClickPrevQuestion() {
  yield takeLatest(actionTypes.CLICK_PREV_QUESTION, onClickPrevQuestionSaga);
}

export function* answerSaga() {
  yield all([
    watchClickNextQuestion(),
    watchClickPrevQuestion(),
  ]);
}