import React from 'react';
import {ILinkageData} from "../../interface/ILinkageData";
import { IAppState } from 'src/interface/IAppState';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { linkageDataSelector } from '../commonSelector';
import { fetchLinkageData } from '../commonAction';
import { StockNavigation } from 'src/interface/IBookmarkState';

interface Props {
  linkageData: ILinkageData | null,
  fetchLinkageData(navigation: StockNavigation, stockCode: string): void;
  type: StockNavigation;
  stockCode: string;
}

const LupinForm: React.FC<Props> = ({children, fetchLinkageData, type, stockCode}) => {
  
  const fetchLinkage = () => {
    fetchLinkageData(type, stockCode)
  }

  return (
    <div style={{width: "100%"}} onClick={fetchLinkage}>
      {children}
    </div>
  )
};


const mapStateToProps = (state: IAppState) => ({
  linkageData: linkageDataSelector(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchLinkageData: (navigation: StockNavigation, stockCode: string) => dispatch(fetchLinkageData(navigation, stockCode)),
});


export default connect(mapStateToProps, mapDispatchToProps)(LupinForm);

