// import React from "react";
import styled from 'styled-components';
import imageBG1 from 'src/assets/images/img-bg1.png';
import imageBG2 from 'src/assets/images/img-bg2.png';
import {
  GAP,
  size,
  $color01,
  $color07,
  posr,
  posa,
  media,
  $color14
} from 'src/styled/helpers/';
const homepageOffset = `70px`;
const paddingGap = '60px';

const Homepage = styled.div`
  .offset-button {
    ${posr()}
  }

  .text-introduction {
    color: ${$color14};
    font-weight: bold;

    ${media.mobile`
      font-size: 13px;
    `}
  }

  .homepage-block-01 {
    align-items: center;
    background-image: url(${imageBG1});
    background-position: center center;
    background-repeat: no-repeat;
    background-size: auto 275px;
    display: flex;
    flex-flow: row nowrap;
    margin-left: auto;
    margin-right: auto;
    max-width: 1280px;
    min-height: 300px;

    &:after{
      content:'';
      min-height:inherit;
      font-size:0;
    }

    .container {
      align-items: center;
      display: flex;
      height: 100%;

      ${media.ie`
        width: 100%;
      `}
    }

    .hero-subtext {
      font-weight: bold;
      font-size: 12px;
    }

    .hero-text {
      font-size: 26px;
    }

    .hero-panel {
      background: rgba(255, 255, 255, 0.9);
      max-width: 700px;
      padding: 30px 16px;
      transform: translateY(-17px);
      width: 100%;

      h1,
      h3 {
        margin: 0;
      }

      h1 {
      }

      h3 {
        color: ${$color14};
      }
    }

    ${media.tabletUp`
      background-position: calc(100% + 35px) center;
      background-size: auto 320px;
      min-height: 400px;
      height: 400px;

      .hero-subtext {
        font-size: 19px;
      }

      .hero-text {
        font-size: 52px;
      }

      .hero-panel {
        padding: 43px ${homepageOffset};
        transform: translateX(-${homepageOffset});
        width: 80%;
      }
    `}

    ${media.ie`
      &:after {
        content: "";
        font-size: 0;
        min-height: inherit;
      }
    `}
  }

  .homepage-intro {
    ${media.mobile`
      & > .columns {
        margin-bottom: 0;
        margin-top: 0;
      }
    `}
  }

  .homepage-block-02 {
    margin-bottom: 20px;

    ${media.tabletUp`
      margin-bottom: ${paddingGap};
    `}

    .homepage-offset {
      &-row {
        ${media.tabletUp`
          transform: translateX(-${homepageOffset});
          width: calc(100% + ${homepageOffset});
        `}
      }

      &-col {
        background-color: ${$color01};
        ${media.mobile`
          &:not(:last-child) {
            margin-bottom: 10px;
          }
        `}

        ${media.tabletUp`
          .text-introduction {
            margin-bottom: 28px;
          }

          &.col-1,
          &.col-2,
          &.col-3 {
            flex: 0 0 auto;
          }

          &.col-2,
          &.col-3 {
            width: calc((100% - 380px - 35px)/2);

            .homepage-intro {
              max-width: 240px;
            }

            .homepage-offset-content {
              padding-left: calc(${homepageOffset} - 3px);
              padding-right: 0;
            }
          }

          &.col-1 {
            width: 380px;
            margin-right: 35px;

            .homepage-offset-content {
              padding-left: ${homepageOffset};
              padding-right: ${homepageOffset};
            }
          }

          &.col-2 {
            .text-introduction {
              max-width: 240px;
            }
          }

          &.col-3 {
            .homepage-blockstyle-5 {
              margin-top: 24px;
            }
          }
        `}
      }

      &-content {
        padding: 8px ${GAP.MOBILE};

        ${media.tabletUp`
          padding: 40px 0;
        `}

        ${media.iphone5`
          padding: 8px ${GAP.IPHONE5};
        `}
      }
    }
  }

  .homepage-block-03 {
    padding-bottom: 15px;

    .gradient-text {
      font-size: 24px;
    }

    .text-bigintroduction {
      font-weight: bold;
      color: ${$color07};
      font-size: 24px;

      ${media.iphone5`
        font-size: 20px;
      `}
    }

    .homepage-offset {
      &-row {
        background-color: ${$color01};
      }

      &-col {
        &.col-2 {
          ${posr()};
          padding-bottom: 120px;

          &:after {
            ${posa(-1)};
            ${size('100%')};
            content: '';
            right: 0;
            bottom: -13px;
            background-image: url(${imageBG2});
            background-size: 107% auto;
            background-position: right bottom;
            background-repeat: repeat-x;
          }
        }

        ${media.tabletUp`
          &.col-1 {
            background-image: url(${imageBG2});
            background-size: auto 188px;
            background-position: right calc(100% - 58px);
            background-repeat: no-repeat;
            flex: 0 0 auto;
            width: calc(400px - ${homepageOffset});
            margin-right: 15px;

            &:before {
              width: 150%;
            }

            &:after {
              ${posa()};
              ${size('102%', '100%')};
              background-image: url(${imageBG2});
              background-position: calc(100% + 330px) bottom;
              background-repeat: repeat-x;
              background-size: auto 188px;
              bottom: 58px;
              content: "";
              right: calc(100% + 15px);
            }
          }

          &.col-2 {
            flex: 1 1 0px;
            width: auto;
            padding: 0 ${paddingGap}
            margin-top: 15px;

            &:after {
              display: none;
              visibility: hidden;
            }
          }
        `}
      }

      &-content {
        background-color: ${$color01};
        padding: 47px ${GAP.MOBILE} 15px;

        ${media.tabletUp`
          padding: 70px 0;
        `}

        ${media.iphone5`
          padding: 47px ${GAP.IPHONE5} 15px;
        `}
      }
    }

    ${media.mobile`
      .text-introduction {
        font-size: 15px;
      }

      .homepage-offset {
        &-col {
          &.col-2 {
            .homepage-offset-content {
              padding-top: 15px;
            }
          }
        }
      }
    `}
  }
`;

export { Homepage };
