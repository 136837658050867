// import React from "react";
import styled from 'styled-components';
import {
  flexRow,
  $color07,
  $color11,
  $color08,
  media,
  posa,
  posr,
  size
} from 'src/styled/helpers/';
interface Props {
  disableArrowDown?: boolean;
}

const BlockStyle5 = styled.div<Props>`
  text-align: left;
  font-size: 14px;

  .block-heading {
    ${posr()};
    min-height: 42px;
    margin-bottom: 20px;

    ${media.tabletUp`
      padding-top: 9px;
    `}

    .text-title {
      color: ${$color07};
      display: inline-block;
      font-weight: bold;
      font-size: 18px;

      span {
        color: ${$color08};
      }

      ${media.mobile`
        font-size: 16px;
        margin-top: 4px;
      `}
    }

    .icon {
      ${posa()};
      ${size('42px')};
      right: 0;
      top: 0;

      img {
        display: block;
        max-width: 100%;
        width: 100%;
      }
    }
  }

  button, a {
    font-weight: bold;
    width: 100%;

    ${media.tabletUp`
      font-size: 16px;
      padding: 19px 5px;
    `}
  }

  .block-content {
    margin-bottom: 23px;
  }

  .block-results {
    ${flexRow()};
    padding-left: 15px;

    a {
      font-weight: inherit;
      font-size: 12px;
      display: block;
      width: fit-content;
      padding: 0;
    }

    ${media.tabletUp`
      display: block;

      a {
        font-size: 13px;
      }
    `}
  }

  .result {
    &-label {
      ${posr()};
      font-size: 12px;
      font-weight: bold;
      margin-right: 13px;
      white-space: nowrap;

      ${ props => !props.disableArrowDown && `
        &:after {
          ${posa()};
          ${size('0')};
          border-color: ${$color11} transparent transparent transparent;
          border-style: solid;
          border-width: 5px 4px 0 4px;
          content: '';
          left: -15px;
          top: 7px;
        }
      `}

      ${media.tabletUp`
        font-size: 13px;
        margin-bottom: 8px;
        margin-right: 0;
      `}
    }

    &-text {
      font-size: 13px;
    }

    &_labelLeft {
      color: ${$color08};
    }

    &_value {
      font-weight: bold;
    }
  }
`;

export default BlockStyle5;
export { BlockStyle5 };
