import { ICommonState } from "src/interface/ICommonState";
import { actionTypes } from "./commonAction";

const initialState: ICommonState = {
  isLoading: false,
  linkageData: null,
  topResult: {
    product_results: [],
    toshin_results: [],
    kabu_results: [],
    isPending: false
  },
  isError: false
};

export function commonReducer(state = initialState, action: any) {
  switch (action.type) {
    case actionTypes.COMMON_SET_LOADING: {
      return {
        ...state,
        isLoading: action.isLoading
      }
    }
    case actionTypes.COMMON_FETCH_LINKAGE_DATA_SUCCESS: {
      return {
        ...state,
        linkageData: action.data
      }
    }
    case actionTypes.COMMON_GET_TOP_RESULT_PENDING: {
      return {
        ...state,
        topResult: {
          ...state.topResult,
          isPending: true
        },

      }
    }
    case actionTypes.COMMON_GET_TOP_RESULT_SUCCESS: {

      return {
        ...state,
        topResult: {
          ...state.topResult,
          ...action.data,
          isPending: false
        }
      }
    }
    default:
      return state;
  }
}