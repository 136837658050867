import styled from 'styled-components';
import { getThemeColor, size, $color08, media } from 'src/styled/helpers/';
import { isIE } from "src/common/utils/functionUtil";

// Creating a map of specific define CSS degree (Based on sample SVG)
const radiusMap = new Map();
radiusMap
  .set(25, 93) //25%, 93deg
  .set(50, 149)
  .set(75, 208)
  .set(100, 273);

// Convert key values from Map into an Array
// Using for naming keyframe, CSS classes.
const radiusArrayKeys = Array.from(radiusMap.keys());

// Generating a list of breakpoint depend on percentage value
const getNumberOfPathGenerator = value => {
  if (75 < value && value <= 100)
    return [25,50,75,value];
  if (50 < value && value <= 75)
    return [25,50,value];
  if (25 < value && value <= 50)
    return [25, value];
  return [value];
}

// Return a list of path-[INDEX] styling
const renderPathCSS = ({currentIndex, name}) => `
  .part-${currentIndex} {
    animation-name: radiusCustom_${name};
  }
`;

// Render a list of keyframes
const renderKeyframe = ({name, value, capPercent, capDegree}) => `
  @keyframes radiusCustom_${name} {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(${(value*capDegree)/capPercent}deg);
    }
  }
`;

const formatNumber = value => value.toString().replace(".", '_');

interface Props {
  theme?: string;
  className?: string;
  id?: string;
  value: number;
}

const ResultCircle = styled.div<Props>`
  ${media.tabletUp`
    height: 88px;
  `}

  ${media.mobile`
    height: 72px;
  `}

  ${media.iphone5`
    height: 58px;
  `}

  .circle-chart__circle {
    transform: rotate(-90deg);
    transform-origin: center;
  }

  svg.results {
    ${size('94px')};

    ${media.mobile`
      ${size('84px')};
    `}

    ${media.iphone5`
      ${size('70px', '64px')};
    `}

    clipPath path {
      fill: none;
    }

    g:not(.is-ie) {
      clip-path: url(#clip-path)
    }

    .is-svg-animation path.data {
      animation-duration: 2s;
      animation-fill-mode: forwards;
      transform-origin: 50% 50%;
    }

    path.data {
      fill: none;
      stroke-linecap: round;
      stroke-linejoin: round;
      stroke-width: 12px;
      stroke: ${props => getThemeColor(props.theme, $color08)};

      &.background {
        stroke: #cccccc;
      }
    }

    text {
      font-family: 'Noto Sans JP', sans-serif;
      font-size: ${props => props.value.toString().length >= 4 ? `15px` : `18px`};
      font-weight:700;
      fill: ${ props => getThemeColor(props.theme, $color08)}
      ${media.iphone5`
        font-size: ${(props: Props) => props.value.toString().length >= 4 ? `19px` : `22px`};
      `}
    }
  }

  ${props => {
    const { value } = props;

    if (isIE()) return ``;

    // Convert decimal number to `_`
    // This is used for naming CSS classes
    // E.g: 3.25 -> 3_25
    const name = formatNumber(value);

    // Getting a list of specifc breakpoints based on percentage value
    const steps = getNumberOfPathGenerator(value)

    if (!steps)
      return ``;

    let txtCSS = '',
      pathCSS = '';

    steps.forEach((step, index) => {
      const lastCap = index === steps.length - 1
        ? radiusArrayKeys[index]
        : steps[index];

      txtCSS += renderKeyframe({
        name: step,
        value: step,
        capPercent: lastCap,
        capDegree: radiusMap.get(lastCap)
      });

      pathCSS += renderPathCSS({
        currentIndex: index,
        name: step
      });
    });

    return `
      &.value-${name} svg {
        ${txtCSS}
        ${pathCSS}
      }
    `
  }}
`;

export default ResultCircle;
export { ResultCircle };
