// import React from "react";
import styled from 'styled-components';
import { BlockStyle1 } from 'src/styled/index';
import { media, alignByTextAlign, $color14, posr } from 'src/styled/helpers/';

interface Props {
  maxImageWidth?: number,
  imageAlign?: 'left' | 'center' | 'right',
}

const BlockStyle6 = styled(BlockStyle1)<Props>`
  text-align: left;
  font-size: 14px;

  .image-placeholder-wrapper {
    ${props => alignByTextAlign(props.imageAlign)};
    display: block;
    position: absolute;
    z-index: 10;
    left: 0;
    top: 10px;
    img {
      width: ${props =>
        props.maxImageWidth ? `${props.maxImageWidth}px` : 'none'};
    }
  }

  .image-placeholder {
    display: inline-block;
    max-width: ${props =>
    props.maxImageWidth ? `${props.maxImageWidth}px` : 'none'};
  }

  .block-heading {
    ${posr()};
    text-align: center;
    ${media.mobile`
    position: relative;
      z-index: 1;
      padding-left: 40px;
      text-align: left;
    `}
    padding-top: 0;
    margin-bottom: 10px;

    h5 {
      color: ${$color14};
      font-size: 15px;
      margin: 5px 0 0;
    }

    ${media.tabletUp`
      margin-bottom: 28px;

      h5 {
        font-size: 18px;
        margin: 15px 0 0;
      }
    `}
  }

  .block-content {
    font-size: 12px;

    ${media.tabletUp`
      font-size: 14px;
    `}
  }
`;

export default BlockStyle6;
export { BlockStyle6 };
