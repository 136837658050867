import styled, { css } from 'styled-components';
import {
  noOutline,
  ROW_TYPE,
  media,
  size,
  $color01,
  $color03,
  $color06,
  $color07,
  $color19,
  $color04,
  $color11,
  $color20,
  $color25,
  $color27,
  flexRow,
  COLOR_TYPE, posr, posa
} from 'src/styled/helpers/';

const TableContainer = styled.div`
  display: block;
`;

const RowWrapper = styled.div`
  background-color: ${$color01};
  margin-bottom: 10px;
  padding: 20px;

  button {
    font-size: 13px;
    font-weight: bold;
    width: 100%;
  }

  ${media.tabletUp`
    margin-bottom: 5px;
  `}

  .${ROW_TYPE.THREE} & {
    &:not(:last-child) {
      margin-bottom: 2px;
    }
  }

  .minichart-wrapper {
    display: block;
    visibility: visible;

    ${media.tabletUp`
      display: none;
      visibility: hidden;
    `}

    &.is-visible {
      display: block;
      visibility: visible;
    }
  }

  ${media.mobile`
    .${ROW_TYPE.THREE} & {
      padding-left: 40px;
      padding-right: 40px;
    }
  `};
`;

const RowMini = styled.div`
  text-align: left;
  font-size: 14px;
  line-height: 1;
  padding-bottom: 10px;
  padding-top: 10px;

  button.no-style {
    background: white;
    border: none;
    color: inherit;
    cursor: pointer;
    font-family: inherit;
    font-size: inherit;
    font-weight: bold;
    padding: 0;
    text-align: inherit;
    text-decoration: inherit;
    width: fit-content;
  }

  &[class*='minirow-'] {
    &:first-of-type {
      padding-top: 0;
    }

    &:last-of-type {
      padding-bottom: 0;
    }
  }

  &.minirow-1 {
    ${flexRow()};

    ${media.mobile`
      .${ROW_TYPE.THREE} & {
        ${posr()}
        flex-flow: column nowrap;
        padding-right: 0px;

        .mobile-icon-star {
          ${posa()};
          right: -25px;
          top: 3px;
        }
      }
    `};
  }

  &.minirow-2 {
    color: ${$color03};
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    text-decoration: underline;

    .no-style {
      cursor: pointer;
    }

    ${media.mobile`
      border-bottom: 1px solid ${$color06};
      padding-bottom: 15px;
      padding-top: 0;
    `};
  }

  &.minirow-3 {
    ${flexRow()};

    .${ROW_TYPE.ONE} & {
      border-bottom: 1px solid ${$color06};

      &.has-no-data {
        border-bottom-color: transparent;
        padding-bottom: 0;
      }
    }

    .${ROW_TYPE.TWO} & {
      button {
        ${noOutline};
        background-color: transparent;
        border: none;
        cursor: pointer;
      }

      .col-6 {
        i {
          transform: translateX(-5px);
        }

        button {
          text-align: center;
          padding: 0;
        }
      }

      ${media.tabletUp`
        padding-bottom: 0;

        &.is-showing-chart {
          padding-bottom: 10px;
        }
      `}
    }

    ${media.mobile`
      flex-flow: column nowrap;
      padding-bottom: 0;

      .${ROW_TYPE.ONE} & {
        border-bottom: none;
      }
    `};
  }

  &.minirow-4 {
    p {
      line-height: 1.4;
    }

    .${ROW_TYPE.ONE} &.has-no-data {
      padding-top: 0;
    }

    .${ROW_TYPE.TWO} & {
      ${media.tabletUp`
        padding-top: 0;

        &.is-showing-chart {
          padding-top: 10px;
        }
      `}
    }

    ${media.mobile`
      padding-top: 0;

      p {
        &:first-child {
          margin-bottom: 12px;
        }
      }

      .${ROW_TYPE.ONE} & .mobile-button-temp {
        margin-top: 15px;
      }
    `}
  }

  p {
    margin: 0;

    ${media.tabletUp`
      &:last-child {
        margin-top: 7px;
      }
    `}
  }

  a, button.no-style {
    color: ${$color07};
    font-size: 16px;
    line-height: 20px;
    text-decoration: underline;
  }

  .line-1 {
    font-size: 13px;
  }

  .line-number {
    font-size: 16px;

    ${media.tabletUp`
      font-size: 18px;
    `}
  }

  ${media.mobile`
    font-size: 13px;

    .mobile-left {
      margin-right: auto;
    }

    .mobile-right {
      margin-left: auto;
    }

    a, button.no-style {
      font-size: 13px;
    }
  `}
`;

const Row1ColLeft = styled.div`
  margin-right: auto;
`;

const styledFavorite = css<{favorite?: boolean}>`
  cursor: pointer;

  .icon-star {
    ${size('21px','20px')};
    background-size: 21px auto;
    margin-left: 8px;
  }

  .text-favorite {
    color: ${props => (props.favorite ? $color04 : $color19)};
  }
`;

const Row1ColRight = styled.div`
  align-items: center;
  display: flex;
  flex-flow: row nowrap;
  font-weight: bold;
  margin-left: auto;

  ${styledFavorite}
`;

const ColMini = styled.div<{colorType?: string, favorite?: boolean}>`
  button.no-style {
    background: white;
    border: none;
    cursor: pointer;
    font-family: inherit;
    font-size: 16px;
    font-weight: normal;
    line-height: 20px;
    padding: 0;
    text-align: inherit;
    text-decoration: underline;
    width: fit-content;
  }

  .line-number {
    color: ${props => {
    if (!props.colorType) return $color11;

    return  props.colorType === COLOR_TYPE.UNCHANGED ? $color25 : (props.colorType === COLOR_TYPE.POSITIVE ? $color20 : $color27);
    }}
    font-weight: bold;
  }

  .btn-link-bookmark {
    width: 100%;
  }

  .icon-star {
    cursor: pointer;
    margin-left: 12px;
  }

  .text-favorite {
    color: ${props => (props.favorite ? $color04 : $color19)};
    cursor: pointer;
  }

  ${media.mobile`
    &[class*="col-"] {
      ${flexRow()};

      &:not(:last-child) {
        margin-bottom: 7px;
        margin-top: 7px;
      }
    }

    .${ROW_TYPE.ONE} & {
      &.col-6 {
        width: 100%;
      }

      &.col-2 {
        .line-number {
          font-size: 13px;
        }
      }
    }

    .${ROW_TYPE.TWO} & {
      &.col-2 {
        .line-number {
          font-size: 13px;
        }
      }
    }

    .${ROW_TYPE.THREE} & {
      font-size: 15px;

      .line-1,
      .line-text {
        font-size: 15px;
      }

      .line-number {
        font-size: 15px;
      }

      &.col-1 {
        display: block;

        .line-1 {
          margin-bottom: 15px;
        }
      }

      &.col-3 {
        .line-number {
          font-size: 12px;
        }
      }
    }
  `}

  ${media.tabletUp`
    .${ROW_TYPE.ONE} & {
      &.col-1,
      &.col-2 {
        flex: 0 0 auto;
        width: 22%;
      }

      &.col-3,
      &.col-4,
      &.col-5,
      &.col-6 {
        flex: 0 0 auto;
      }

      &.col-guest {
        flex: 0 0 auto;
        width: 27%;
        .guest {
          font-size: 14px;
        }
      }

      &.col-2 {
        .line-number {
          font-size: 14px;
        }
      }

      &.col-3 {
        flex: 1 1 0px;
        width: auto;
      }
      &.col-4 {
        width: 90px;
      }
      &.col-5 {
        width: 140px;
      }
      &.col-6 {
        width: 86px;
      }
    }
  `}

  ${media.tabletUp`
    .${ROW_TYPE.TWO} & {
      &.col-1,
      &.col-2 {
        flex: 0 0 auto;
        width: 20%;
      }

      &.col-3,
      &.col-4,
      &.col-5,
      &.col-6,
      &.col-7 {
        flex: 0 0 auto;
      }

      &.col-1 {
        width: 27%;
      }

      &.col-guest {
        flex: 0 0 auto;
        width: 45%;
        .guest {
          font-size: 14px;
        }
      }

      &.col-2 {
        width: 27%;

        .line-number {
          font-size: 14px;
        }
      }

      &.col-3 {
        flex: 1 1 0px;
        width: auto;
        .show-chart {
          width: auto;
          padding: 0;
          margin-left: 5px;
        }
      }
      &.col-4 {
        width: 90px;
      }
      &.col-5 {
        width: 140px;
      }
      &.col-6 {
        width: 65px;
      }
      &.col-7 {
        width: 86px;
      }
    }
  `}

  ${media.tabletUp`
    .${ROW_TYPE.THREE} & {
      &.col-2,
      &.col-3,
      &.col-4 {
        flex: 0 0 auto;
      }

      &.col-1 {
        flex: 1 1 0px;
        width: auto;
        margin-right: 15px;
      }

      &.col-2 {
        width: 24%;
      }

      &.col-3 {
        width: 27%;

        .line-number {
          font-size: 14px;
        }
      }

      &.col-4 {
        width: 130px;
      }

      .line-1 {
        margin-bottom: 15px;
      }

      .part__favorite {
        align-items: center;
        display: flex;
        justify-content: flex-end;
        margin-bottom: 12px;
      }
    }
  `}
`;

export default TableContainer;
export {
  RowWrapper,
  RowMini,
  Row1ColLeft,
  Row1ColRight,
  ColMini,
  TableContainer
};
