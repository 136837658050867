import { all } from 'redux-saga/effects'
import { productSaga } from 'src/product/productSaga';
import { questionSaga } from 'src/answer_question/question/QuestionSaga';
import { answerSaga } from 'src/answer_question/answer/AnswerSaga';
import bookmarkSaga from "../bookmark/bookmarkSaga";
import {toshinSaga} from "../toshin/toshinSaga";
import {kabuSaga} from "../kabu/kabuSaga";
import {commonSaga} from "../common/commonSaga";

function* appSaga() {
  yield all([
    productSaga(),
    toshinSaga(),
    kabuSaga(),
    questionSaga(),
    answerSaga(),
    bookmarkSaga(),
    commonSaga(),
  ])
}

export default appSaga;