// import React from "react";
import styled from 'styled-components';
import { media } from 'src/styled/helpers/';

const OperationalProductPage = styled.div`
  .page-section {
    &__top {
      display: flex;
      flex-flow: column nowrap;
      align-items: center;
      padding-bottom: 52px;
      padding-top: 52px;
    }
  }

  .paper-wrapper-1 {
    .col-3 {
      .image-placeholder {
        img {
          transform: translateY(3px);
        }
      }
    }

    ${media.tabletUp`
      &.reduction {
        margin-bottom: 0px;
      }
      margin-bottom: 45px;

      .row-1 {
        margin-bottom: 30px;
      }

      .col-1 {
        padding-left: 90px;
        padding-right: 0;
      }

      .col-2 {
        padding-left: 40px;
        padding-right: 40px;
      }

      .col-3 {
        padding-left: 0;
        padding-right: 80px;

        .image-placeholder {
          img {
            transform: translateY(4px);
          }
        }
      }

      .col-offset-2 {
        padding-left: 50px;
      }
    `}
  }

  .paper-wrapper-2 {
  }

  .operation-offset-button {
    ${media.mobile`
      font-size: 15px;
      height: 44px;
      line-height: 1;
    `}
  }
`;

export default OperationalProductPage;
export { OperationalProductPage };
