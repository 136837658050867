// import React from "react";
import styled from 'styled-components';
import { BlockStyle1 } from 'src/styled';
import { size, $color01, posr, posa, media } from 'src/styled/helpers/';

const BlockStyle1Mobile = styled(BlockStyle1)<{maxImageWidth?: number}>`
  color: ${$color01};
  text-align: left;
  font-size: 12px;
  padding: 20px 60px 20px 85px;
  ${media.iphone5`
    padding: 20px 40px 20px 65px;
  `}
  border-bottom: 1px solid ${$color01};

  .image-placeholder {
    ${posa()};
    display: block;
    margin-left: 0;
    margin-right: 0;
    left: -55px;
    top: 25px;
    max-height: 80px;
    max-width: ${props => {
      if (!props.maxImageWidth) return 'none';

      return `${props.maxImageWidth}px`;
    }};

    .category-choice-block-mobile__col-1& {
      left: -60px;
      top: 25px;
    }
    
    .category-choice-block-mobile__col-2& {
      top: 10px;
    }

    .category-choice-block-mobile__col-3& {
      top: 20px;
    }
  }
  
  &.img-center {
    .image-placeholder {
      top: 2px;
    }
  }

  .icon-triagle-right {
    ${posa()}
    ${size('0')};
    border-style: solid;
    border-width: 6px 0 6px 8px;
    border-color: transparent transparent transparent ${$color01};
    right: 4px;
    top: 6px;
  }

  .block-heading {
    ${posr()};
    padding-top: 0;
    margin-bottom: 10px;

    h5 {
      color: ${$color01};
      font-size: 16px;
      margin-top: 0;
      padding-right: 15px;
      text-align: left;
    }
  }

  .block-content {
    margin-bottom: 0;
    text-align: justify;

    p {
      line-height: 18px;
      margin-top: 0;

      &:not(:last-child) {
        margin-bottom: 10px;
      }
    }
  }

  .block-link {
    button {
      font-size: 16px;
      width: 100%;
    }
  }
`;

export default BlockStyle1Mobile;
export { BlockStyle1Mobile };
