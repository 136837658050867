import { all, takeLatest, put, call } from "redux-saga/effects";
import { QuestionService } from 'src/api/QuestionService';
import { actionTypes,
  getProductQuestionSuccess,
  getToshinQuestionListSuccess,
  getKabuQuestionListSuccess,
  getProductQuestionListPending
} from './QuestionAction';


import { AxiosResponse } from 'axios';
import { resetAnswerSurvey } from "../answer/AnswerAction";

function* getProductQuestionListSaga() {
  try {
    yield put(resetAnswerSurvey());
    yield put(getProductQuestionListPending());
    const { data }: AxiosResponse = yield call(QuestionService.getProductSurveyList);
    yield put(getProductQuestionSuccess(data));
  } catch (error) {

  }
} 

function* getToshinQuestionListSaga() {
  try {
    yield put(resetAnswerSurvey());
    yield put(getProductQuestionListPending());
    const { data }: AxiosResponse = yield call(QuestionService.getToshinQuestionList);
    yield put(getToshinQuestionListSuccess(data));
  } catch(error) {

  }
}

function* getKabuQuestionListSaga() {
  try {
    yield put(resetAnswerSurvey());
    const { data }: AxiosResponse = yield call(QuestionService.getKabuQuestionList);
    yield put(getKabuQuestionListSuccess(data));
  } catch(error) {

  }
}

function* getProductQuestionList() {
  yield takeLatest(actionTypes.GET_PRODUCT_QUESTION_LIST, getProductQuestionListSaga);
}

function* getToshinQuestionList() {
  yield takeLatest(actionTypes.GET_TOSHIN_QUESTION_LIST, getToshinQuestionListSaga);
}

function* getKabuQuestionList() {
  yield takeLatest(actionTypes.GET_KABU_QUESTION_LIST, getKabuQuestionListSaga);
}


export function* questionSaga() {
  yield all([
    getProductQuestionList(),
    getToshinQuestionList(),
    getKabuQuestionList(),
  ]);
}