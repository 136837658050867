import {IAppState} from "../interface/IAppState";
import { formatDate } from "./utils/functionUtil";

export const linkageDataSelector = (appState: IAppState) => (
  appState.commonState.linkageData
);


export const toshinTopResultSelector = (state: IAppState) => {
  const results = state.commonState.topResult.toshin_results;
  return results.map(result => ({
    type: 'toshin',
    id: result.id,
    label: formatDate(result.created_at),
    name: result.category
  }));
};

export const kabuTopResultSelector = (state: IAppState) => {
  const results = state.commonState.topResult.kabu_results;
  return results.map(result => ({
    type: 'kabu',
    id: result.id,
    label: formatDate(result.created_at),
    name: result.category
  }));
};


export const productTopResultSelector = (state: IAppState) => {
  const results = state.commonState.topResult.product_results;
  return results.map(result => ({
    id: result.id,
    label: 'おすすめの運用商品',
    type: 'products',
    name: result.category
  }));
};
