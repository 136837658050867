// import React from "react";
import styled from 'styled-components';
import { size, $color01, flexRow, posr, posa } from 'src/styled/helpers/';

interface Props {
  onlyDesktop?: boolean
}

const OffsetRow = styled.div<Props>`
  ${flexRow()};

  ${props =>
  props.onlyDesktop &&
  `
      @media screen and (max-width: 768px) {
        display: block;
      }
    `}

  .has-offset {
    ${posr()};

    &.right:after,
    &.left:before {
      ${posa()};
      ${size('200%','100%')};
      background: ${$color01};
      content: '';
    }

    &.right:after {
      left: 100%;
      top: 0;
      width: 200%;
    }

    &.left {
      &:before {
        right: 100%;
        top: 0;
      }
    }
  }
`;

export default OffsetRow;
export { OffsetRow };
