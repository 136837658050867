import React from "react";
import SkeletonAsyncComponent from "./SkeletonAsyncComponent/SkeletonAsyncComponent";

interface State {
  component: any;
}

export default function asyncComponent(importComponent) {
  class AsyncComponent extends React.Component<{},State> {
    constructor(props) {
      super(props);

      this.state = {
        component: null,
      };
    }

    async componentDidMount() {
      const { default: component } = await importComponent();
      this.setState({
        component: component
      });
    }

    render() {
      const C = this.state.component;
      return C ? <C {...this.props} /> : 
      <SkeletonAsyncComponent/>;
    }
  }

  return AsyncComponent;
}
