import styled from 'styled-components';
import { $color17 } from 'src/styled/helpers/';

const Disclaimer2 = styled.div`
  padding: 10px 15px;
  border: 1px solid #ccc;
  margin-bottom: 20px;
  font-size: 12px;
  .title {
    font-size: 14px;
    margin-bottom: 0;
    &.title-head {
      color: ${$color17};
      margin-bottom: 15px;
      font-weight: normal;
    }
  }
  .disclaimer-2-block {
    margin-bottom: 15px;
    &:last-child {
      margin-bottom: 0px;
    }
    margin-bottom: 20px;
    .text-link {
      margin-top: 15px;
      text-decoration: underline;
      color: #3273dc;
      cursor: pointer;
      &:hover {
        color: ${$color17};
      }
    }
    &--1 {
      .mg-15 {
        margin-bottom: 15px;
      }
    }
  }
`;
  
export default Disclaimer2;
export { Disclaimer2 };
