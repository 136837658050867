// import React from "react";
import styled from 'styled-components';
import {
  $color01,
  $color03,
  $color07,
  $color09,
  $color06,
  posa,
  posr,
  size,
  media,
} from 'src/styled/helpers/';
import iconArrow from 'src/assets/images/img-arrow-brown.png';

const CarouselItem = styled.div`
  ${posr()}
  background-color: ${$color01};
  border-radius: 6px;
  font-size: 14px;
  padding: 20px;
  text-align: left;

  .section-heading {
    ${posr()};
    border-bottom: 1px solid ${$color06};
    padding-bottom: 9px;
    margin-bottom: 11px;
  }

  .section-body {
    padding-bottom: 15px;

    ${media.mobile`
      padding-bottom: 27px;
    `}

    p {
      margin-top: 0;
      margin-bottom: 0.25rem;
    }
  }

  .text-heading,
  .text-subtext {
    font-weight: bold;
  }

  .text-heading {
    color: ${$color03};
    font-size: 18px;
    text-decoration: underline;
    margin-bottom: 11px;
  }

  .text-subtext {
    color: ${$color09};
  }

  .text-line3 {
    color: ${$color07};
    font-weight: normal;
  }

  .text-date {
    ${posa()};
    font-size: 13px;
    right: 0;
    top: 5px;
  }

  .btn-icon-brown {
    ${posa()};
    background-color: transparent;
    border: none;
    bottom: 15px;
    right: 15px;
    color: ${$color03};
    font-weight: bold;
  }

  .icon-arrow-brown {
    ${size('20px')};
    display: inline-block;
    background-image: url(${iconArrow});
    background-repeat: no-repeat;
    background-size: 11px 11px;
    transform: translateY(10px);
    margin-left: 10px;
  }
`;

export default CarouselItem;
export { CarouselItem };
