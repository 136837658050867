import React from 'react';
import {Provider} from "react-redux";
import { ConnectedRouter}  from "connected-react-router";
import appStore, { history } from './appStore';
import {Switch} from 'react-router-dom';
import "react-vis/dist/style.css";
import './App.scss';
import AuthRoute from 'src/common/AuthRoute';
import AppLayout from "./AppLayout";
import AsyncComponent from "src/common/AsyncComponent";


const TopContainer = AsyncComponent(() => import("src/top/TopContainer"));
const ProductContainer = AsyncComponent(() => import("src/product/ProductContainer"));
const ToshinContainer = AsyncComponent(() => import("src/toshin/ToshinContainer"));
const KabuContainer = AsyncComponent(() => import("src/kabu/KabuContainer"));
const HelpContainer = AsyncComponent(() => import("src/help/HelpContainer"));

const App: React.FC = () => {
  return (
    <Provider store={appStore}>
      <ConnectedRouter history={history}>
        <AppLayout>
          <Switch>
            <AuthRoute path={'/'} exact component={TopContainer}/>
            <AuthRoute path={'/products'} component={ProductContainer}/>
            <AuthRoute path={'/toshin'} component={ToshinContainer}/>
            <AuthRoute path={'/kabu'} component={KabuContainer}/>
            <AuthRoute path={'/help'} component={HelpContainer}/>
          </Switch>
        </AppLayout>
      </ConnectedRouter>
    </Provider>
  );
};

export default App;
