import { actionTypes } from "src/product/productAction";
import { IProductState } from "src/interface/IProductState";

const initialState: IProductState = {
  historyResult: {
    isPending: true,
    products: []
  },
  listProductResult: [],
  isPending: false
};

export function productReducer(state = initialState, action: any) {
  switch (action.type) {
    case actionTypes.GET_RESULT_HISTORY_PENDING: {
      return {
        ...state,
        historyResult: {
          ...state.historyResult,
          products: [],
          isPending: true
        }
      }
    }
    case actionTypes.GET_RESULT_HISTORY_SUCCESS: {
      return {
        ...state,
        historyResult: {
          ...state.historyResult,
          products: action.data,
          isPending: false,
        }
      }
    }
    case actionTypes.GET_RESULT_PRODUCT_LIST_PENDING: {
      return {
        ...state,
        listProductResult: [],
        isPending: true
      }
    }
    case actionTypes.GET_RESULT_PRODUCT_LIST_SUCCESS: {
      return {
        ...state,
        listProductResult: action.data,
        isPending: false
      }
    }
    default:
      return state;
  }
}