import React from 'react';
import styled from 'styled-components';
import IconCollapse from "src/assets/images/collapse.png";
import { isMobileViewport } from 'src/common/utils/functionUtil';
interface Props {
  content: string;
}
interface State {
  hiddenDiv: boolean;
  hidden: boolean;
  valueHTML: string;
  hiddenHTML: string;
}

const IconCol = styled.i`
background-image: url(${IconCollapse});
background-repeat: no-repeat;
display: inline-block;
height: 16px;
width: 16px;
&:hover {
  cursor: pointer;
}
`;
class CollapsibleContent extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      hiddenDiv: false,
      hidden: false,
      valueHTML: '<p></p>',
      hiddenHTML: ''
    }
  }
  handleClick = () => {
    let { content } = this.props;
    if (this.state.hidden) {
      content = this.state.hiddenHTML;
    }
    this.setState((prevState) => ({
      valueHTML: content,
      hidden: !prevState.hidden
    }));
  }
  componentDidMount() {
    const { content } = this.props;
    const addComma = content.replace(new RegExp('<tr>', 'g'), '^<tr>');
    const dropEndTable = addComma.replace(new RegExp('</table>', 'g'), '');
    const array = dropEndTable.split(/\^/);
    const currentIsMobile = isMobileViewport();
    let result = '';
    let showDiv = true;

    if (array.length < 4) {
      result = content;
    }
    else {
      showDiv = false;
      const limit = currentIsMobile ? 2 : 3
      for (let i = 0; i < limit; i++) {
        result = result.concat(array[i]);
      }
      result = result.concat('</table>');

      let divIcon = ``;
      result = result.concat(divIcon);
      this.setState({ hiddenHTML: result });
    }
    this.setState({ valueHTML: result, hiddenDiv: showDiv });

    let element: HTMLElement = document.getElementsByClassName('divAutoClick')[0] as HTMLElement;
    element.focus();
  }

  render() {
    const { valueHTML, hiddenDiv } = this.state;
    return (
      <div className="divAutoClick">
        <p dangerouslySetInnerHTML={{ __html: valueHTML }} />
        {
          !hiddenDiv ?
            <div className='collapse-div' onClick={this.handleClick} >
              <IconCol className={this.state.hidden ? 'rotateOver' : 'rotateOut'} />
            </div>
            : null
        }
      </div>

    )
  }
}
export default CollapsibleContent;