import { IQuestionState, IAnswerState, IQuestionGroupListState } from "src/interface/IQuestionListState";

export const actionTypes = {
  CLICK_NEXT_QUESTION: 'CLICK_NEXT_QUESTION',
  CLICK_PREV_QUESTION: 'CLICK_PREV_QUESTION',
  ADD_ANSWER_SURVEY: 'ADD_ANSWER_SURVEY',
  UPDATE_CURRENT_QUESTION_IDX: 'UPDATE_CURRENT_QUESTION_IDX',
  REMOVE_ANSWER_SURVEY: 'REMOVE_ANSWER_SURVEY',
  RESET_ANSWER_SURVEY: 'RESET_ANSWER_SURVEY',
  UPDATE_QUESTION_WILL_SHOW: 'UPDATE_QUESTION_WILL_SHOW',
  UPDATE_CURRENT_TOSHIN_QUESTION_IDX: 'UPDATE_CURRENT_TOSHIN_QUESTION_IDX'
}

export const clickNextQuestion = (typeAnswer: string, questionList: IQuestionGroupListState,question: IQuestionState, answer: IAnswerState) => ({
  type: actionTypes.CLICK_NEXT_QUESTION,
  typeAnswer,
  questionList,
  question,
  answer
})

export const addAnswerSurvey = (typeAnswer: string, question: IQuestionState, answer: IAnswerState) => ({
  type: actionTypes.ADD_ANSWER_SURVEY,
  typeAnswer,
  question,
  answer
})

export const updateCurrentQuestionIdx = (typeAnswer: string, idx: number) => ({
  type: actionTypes.UPDATE_CURRENT_QUESTION_IDX,
  typeAnswer,
  idx,
})

export const updateCurrentToshinQuestionIdx = (typeAnswer: string, idx: number) => ({
  type: actionTypes.UPDATE_CURRENT_TOSHIN_QUESTION_IDX,
  typeAnswer,
  idx,
})


export const clickPrevQuestion = (typeAnswer: string, questionList: IQuestionGroupListState, question: IQuestionState) => ({
  type: actionTypes.CLICK_PREV_QUESTION,
  typeAnswer,
  questionList,
  question
})

export const removeAnswerSurvey = (typeAnswer: string, question: IQuestionState) => ({
  type: actionTypes.REMOVE_ANSWER_SURVEY,
  typeAnswer,
  question
})

export const resetAnswerSurvey = () => ({
  type: actionTypes.RESET_ANSWER_SURVEY
})

export const updateQuestionWillShow = (typeAnswer: string, question: IQuestionState, answer?: IAnswerState) => ({
  type: actionTypes.UPDATE_QUESTION_WILL_SHOW,
  typeAnswer,
  question,
  answer
})

