import React from 'react';
import Header from "src/components/Header";
import Footer from "src/components/Footer";
import BookmarkContainer from 'src/bookmark/BookmarkContainer';
import AppVersion from "./AppVersion";
import AuthModal from "src/components/ModalAuthFail";

interface Props {
}

const AppLayout: React.FC<Props> = ({children, ...props}) => {
  return (
    <div className="App">
      <BookmarkContainer />
      <Header/>
      {children}
      <Footer/>
      <AppVersion/>
      <AuthModal />
    </div>
  )
};

export default (AppLayout);