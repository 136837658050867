// import React from "react";
import styled from 'styled-components';
import { media } from 'src/styled/helpers/';

const MobileVisible = styled.div`
  display: none;
  visibility: hidden;

  ${media.mobile`
    display: block;
    visibility: visible;
  `}
`;

export default MobileVisible;
export { MobileVisible };
