import React from 'react';
import { Footer as StyledFooter, Disclaimer1, Disclaimer2 } from 'src/styled';
import { withRouter, RouteComponentProps } from 'react-router-dom';

interface Props extends RouteComponentProps {
  link?: string;
  text?: string;
}


export const ToshinDisclaimerRender = () => {
  return(
    <>
      <Disclaimer1>
        <p>本サービスは、東京証券取引所、名古屋証券取引所、日本経済新聞社から情報の提供及び許諾を受け、株式会社Finatextが提供しています。（これら法人を総じて以下「運営者及び情報提供者」といいます）当サービスの著作権は大和証券及び運営者及び情報提供者に属し、許可なく第三者への再利用及び再配信等を行うことを固く禁じます。</p>
        <p>本サービス内の情報に関しては万全を期してはいますが内容を保証するものではなく、また将来の結果を保証するものではありません。本サービスは、投資判断の参考として株式・投資信託等投資一般に関する情報の提供を目的としたものであり、投資の勧誘を目的としたものではありません。最終的な投資の意思決定は、お客様ご自身の判断でなされるものとし、この情報に基づき被った損害について大和証券及び運営者及び情報提供者はこれに一切関与せず、また、一切の責任を負いません。なお、当サービスは予告なしに内容が変更または廃止される場合がありますが、あらかじめご了承ください。</p>
      </Disclaimer1>
      <Disclaimer2>
      <p className="title title-head">お取引にあたっての手数料等およびリスクについて</p>
        <div className="disclaimer-2-block">
          <p className="title">主なリスクおよび留意点</p>
          <p className="title">投資信託にかかるリスクについて</p>
          ファンドは、株式、債券、投資信託証券など値動きのある証券（外貨建て資産には為替リスクもあります）に投資しますので、基準価額は大きく変動します。したがって、投資元本が保証されているものではなく、これを割込むことがあります。委託会社の指図に基づく行為により信託財産に生じた利益および損失は、すべて受益者に帰属します。投資信託は預貯金とは異なります。
        </div>
        <div className="disclaimer-2-block">
          <p className="title">投資信託にかかる手数料等について</p>
          投資信託にご投資いただくお客さまには、銘柄ごとに設定された販売手数料および運用管理費用（信託報酬）等の諸経費等をご負担いただく場合があります。
        </div>
        <div className="disclaimer-2-block">
          <p className="title">ご投資にあたっての留意点</p>
          投資信託にかかるリスクおよび手数料等は、それぞれの投資信託により異なりますので、当該投資信託の投資信託説明書（交付目論見書）をよくお読みください。投資信託説明書（交付目論見書）のご請求は、大和証券のお取引窓口までお願いいたします。
        </div>
        <div className="disclaimer-2-block">
          <p className="title">収益分配金に関する留意事項</p>
          ・分配金は、預貯金の利息とは異なり、投資信託の純資産から支払われますので、分配金が支払われると、その金額相当分、基準価額は下がります。<br/>
          ・分配金は、計算期間中に発生した収益（経費控除後の配当等収益および評価益を含む売買益）を超えて支払われる場合があります。その場合、当期決算日の基準価額は前期決算日と比べて下落することになります。<br/>
          ・また、分配金の水準は、必ずしも計算期間におけるファンドの収益率を示すものではありません。<br/>
          ・投資者のファンドの購入価額によっては、分配金の一部または全部が、実質的には元本の一部払戻しに相当する場合があります。ファンド購入後の運用状況により、分配金額より基準価額の値上がりが小さかった場合も同様です。<br/>
        </div>
      </Disclaimer2>
    </>
  )
}

export const DisclaimerRender = () => {
  const openLink = () => {
    window.open("https://www.daiwa.jp/policy/legal_info.html","mywindow","width=1000,fullscreen=yes,resizable=1");
  }
  return(
    <>
      <Disclaimer1>
        <p>本サービスは、東京証券取引所、名古屋証券取引所、日本経済新聞社から情報の提供及び許諾を受け、株式会社Finatextが提供しています。（これら法人を総じて以下「運営者及び情報提供者」といいます）当サービスの著作権は大和証券及び運営者及び情報提供者に属し、許可なく第三者への再利用及び再配信等を行うことを固く禁じます。</p>
        <p>本サービス内の情報に関しては万全を期してはいますが内容を保証するものではなく、また将来の結果を保証するものではありません。本サービスは、投資判断の参考として株式・投資信託等投資一般に関する情報の提供を目的としたものであり、投資の勧誘を目的としたものではありません。最終的な投資の意思決定は、お客様ご自身の判断でなされるものとし、この情報に基づき被った損害について大和証券及び運営者及び情報提供者はこれに一切関与せず、また、一切の責任を負いません。なお、当サービスは予告なしに内容が変更または廃止される場合がありますが、あらかじめご了承ください。</p>
      </Disclaimer1>
      <Disclaimer2>
        <p className="title title-head">お取引にあたっての手数料等およびリスクについて</p>
        <div className="disclaimer-2-block disclaimer-2-block--1">
          <p>当社で取扱う商品等へのご投資には、商品ごとに所定の手数料等をご負担いただく場合があります。（国内株式委託手数料は約定代金に対して最大1.26500％（税込）、ただし、最低2,750円（税込）、ハッスルレートを選択しオンライントレード経由でお取引いただいた場合は、1日の約定代金合計が300万円までなら、取引回数に関係なく国内株式委託手数料が3,300円（税込）、以降、300万円を超えるごとに3,300円（税込）が加算、投資信託の場合は銘柄ごとに設定された販売手数料および信託報酬等の諸経費、等）</p>
          <p className="mg-15">また、各商品等には価格の変動等による損失が生じるおそれがあります。商品ごとに手数料等およびリスクは異なりますので、当該商品等の上場有価証券等書面または契約締結前交付書面、目論見書、お客さま向け資料等をお読みください。</p>
          <span className="text-link" onClick={openLink}>お取引にあたっての手数料等およびリスクの詳細はこちら</span>
        </div>
      </Disclaimer2>
    </>
  )
}
class Footer extends React.Component<Props> {
  render() {
    const { pathname } = this.props.location;
    return (
      <StyledFooter className="max-footer max-container">
        <footer className="primary-footer container">
          { pathname === '/toshin' ?
            <DisclaimerRender/> : pathname.includes('/toshin/result') ? <ToshinDisclaimerRender /> : <DisclaimerRender/>
          }
          <div className="footer-row footer-row-1">
            <div className="footer-col-left">加入協会</div>
            <div className="footer-col-right">
              日本証券業協会　一般社団法人日本投資顧問業協会　一般社団法人金融先物取引業協会　一般社団法人第二種金融商品取引業協会　一般社団法人日本STO協会
            </div>
          </div>
          <div className="footer-row footer-row-2">
            <div className="footer-col-left">商号等</div>
            <div className="footer-col-right">
              大和証券株式会社 金融商品取引業者 関東財務局長（金商）第108号
            </div>
          </div>
          <div className="footer-row footer-row-3">
            ©Daiwa Securities Co.Ltd.
          </div>
        </footer>
      </StyledFooter>
    );
  };
}

export default withRouter(Footer);
