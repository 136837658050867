import styled from 'styled-components';

const PortfolioStyled = styled.div`
  .porfolio-chart {
    padding: 20px;
  }
`;

export { PortfolioStyled };

export default PortfolioStyled;
